import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const Input = styled(Form.Control)`
  padding: 0.8rem 1rem;
  border: ${(props) =>
    props.message ? "1px solid red" : "1px solid #ADB5BD !important"};
  background: ${(props) => (props.background ? props.background : "#fff")};
  border-radius: 8px;
  color: #374151;
  font-weight: 400;
  font-size: 15px;
  width: 100%;
  resize: none;
  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid ${appTheme.COLORS.blueSecondary};
    background: rgba(243, 244, 246, 0.7);
  }
  &::placeholder {
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }
  &:disabled {
    background: #f4f7fd;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }

  @media only screen and (max-width: 500px) {
    &::placeholder,
    &::-ms-input-placeholder,
    &:-ms-input-placeholder {
      font-size: 12px;
    }
  }
`;

const ErrorMessageWrapper = styled.div`
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 13px;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
`;

function CustomTextAreaCmp(props) {
  return (
    <>
      <Input
        {...props}
        background={props.background}
        required={props.required ? true : false || false}
        type={props.type}
        name={props.name}
        disabled={props.disabled}
        value={props.values ? props.values[props.name] : props.value}
        placeholder={props.placeholder}
        onChange={props.onChange || null}
        message={props.message || null}
      />

      <ErrorMessageWrapper>
        <ErrorMessage>{props.showMessage ? props.message : ""}</ErrorMessage>
      </ErrorMessageWrapper>
    </>
  );
}

export default CustomTextAreaCmp;
