import {
  ALL_UPDATE_ERROR,
  ALL_UPDATE_LOADING,
  ALL_UPDATE_SUCCESS,
  CREATE_UPDATE_ERROR,
  CREATE_UPDATE_LOADING,
  CREATE_UPDATE_SUCCESS,
  DELETE_UPDATE_ERROR,
  DELETE_UPDATE_LOADING,
} from "./types";

const initialState = {
  allUpdateLoading: null,
  allUpdate: null,
  allUpdateError: null,
  deleteUpdateLoading: null,
  deleteUpdateError: null,
  createUpdateLoading: null,
  createUpdateSuccess: null,
  createUpdateError: null,
};

export default function updateReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_UPDATE_LOADING:
      return { ...state, allUpdateLoading: action.payload };
    case ALL_UPDATE_SUCCESS:
      return { ...state, allUpdate: action.payload };
    case ALL_UPDATE_ERROR:
      return { ...state, allUpdateError: action.payload };
    case DELETE_UPDATE_LOADING:
      return { ...state, deleteUpdateLoading: action.payload };
    case DELETE_UPDATE_ERROR:
      return { ...state, deleteUpdateError: action.payload };
    case CREATE_UPDATE_LOADING:
      return { ...state, createUpdateLoading: action.payload };
    case CREATE_UPDATE_SUCCESS:
      return { ...state, createUpdateSuccess: action.payload };
    case CREATE_UPDATE_ERROR:
      return { ...state, createUpdateError: action.payload };
    default:
      return state;
  }
}
