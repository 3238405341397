import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { connect } from "react-redux";
import {
  changeUserStatus,
  depositIntoUserWallet,
  getAUser,
  resetAUser2fa,
  withdrawFromUserWallet,
} from "../../redux/actions/userActions";
import { useParams } from "react-router-dom";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import SolidButton from "../../components/ButtonCmp/Button";
import NumberFormat from "react-number-format";
import { FlagAlt } from "@styled-icons/boxicons-solid";
import NairaIcon from "../../assets/naira-color.svg";
import { Formik } from "formik";
import { PlusCircleFill } from "@styled-icons/bootstrap";
import { Money } from "@styled-icons/boxicons-regular";
import nairaDepositSchema from "../../validators/nairaDepositValidator";
import TableCmp from "../../components/TableCmp/TableCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { iconCategory, statusColorMap } from "../../constants/others";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 550px;
  width: 100%;
  padding: 0rem 1rem;

  @media only screen and (max-width: 700px) {
    max-width: unset;
  }
`;

const ParentWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const OtcWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const OtcTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 1.5rem 0 1rem;
`;

const AvailableBal = styled.p`
  color: #abafb3;
  font-size: 14px;
  font-weight: 400;
`;

const InputWrapper = styled.div`
  padding-bottom: 0.3rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 10px;
// `;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 906px) {
    flex-direction: column;
  }
`;

const SecondButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-top: 2rem;
  flex-wrap: wrap;
`;

const NairaImg = styled.img``;

const TxnWrapper = styled.div`
  background-color: ${appTheme.COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  height: 430px;
`;

const NoTxnWrapper = styled.div`
  max-width: 264px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
`;

const NoTxnTitle = styled.h2`
  margin: 0;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  color: #323232;
`;

const NoTxnDesc = styled.p`
  margin: 0;
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 14px;
  color: #000000;
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 1rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  color: #323232;
  padding-top: 2rem;
`;

const ViewUser = ({
  setGetAUser,
  aUserLoading,
  setDepositIntoUserWallet,
  setWithdrawFromUserWallet,
  depositIntoUserWalletLoading,
  withdrawFromUserWalletLoading,
  setChangeUserStatus,
  changeUserStatusLoading,
  setResetAUser2fa,
  resetAUser2faLoading,
  userRole,
}) => {
  const params = useParams();
  const [userData, setUserData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [txnType, setTxnType] = useState("");
  const [triggerReloadUser, setTriggerReloadUser] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAUserData = async () => {
      setLoadingState(true);
      const response = await setGetAUser(params.id, currentPage);

      setUserData(response.data);
      setDataCount(response.data.txns.count);
      setLoadingState(false);
      setRefresh(false);
    };
    if (refresh) {
      getAUserData();
    } else {
      getAUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, triggerReloadUser, currentPage]);
  return (
    <DashboardLayout pageTitle="View Profile">
      <>
        <ParentWrapper>
          <Wrapper>
            {aUserLoading ? (
              <SpinnerCmp
                enabled={true}
                color={appTheme.COLORS.bluePrimary}
                size={20}
                secondaryColor={appTheme.COLORS.white}
              />
            ) : (
              <OtcWrapper>
                <OtcTitle>Account Information</OtcTitle>
                <InputWrapper>
                  <InputLabel>Account Name</InputLabel>
                  <CustomInputCmp
                    required={true}
                    value={userData?.bank?.bankDetail[0]?.accountName}
                    disabled={true}
                    type="text"
                    name="primaryAccountName"
                    placeholder="Enter primary account name"
                    showMessage
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Bank Name</InputLabel>
                  <CustomInputCmp
                    required={true}
                    type="text"
                    value={userData?.bank?.bankDetail[0]?.bankName}
                    disabled={true}
                    name="primaryAccountNumber"
                    placeholder="Enter primary account number"
                    showMessage
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Account Number</InputLabel>
                  <CustomInputCmp
                    required={true}
                    type="text"
                    value={userData?.bank?.bankDetail[0]?.accountNumber}
                    disabled={true}
                    name="primaryAccountNumber"
                    placeholder="Enter primary account number"
                    showMessage
                  />
                </InputWrapper>
              </OtcWrapper>
            )}
          </Wrapper>
          <Wrapper>
            {aUserLoading ? (
              <SpinnerCmp
                enabled={true}
                color={appTheme.COLORS.bluePrimary}
                size={20}
                secondaryColor={appTheme.COLORS.white}
              />
            ) : (
              <OtcWrapper>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <OtcTitle>Wallet Information</OtcTitle>
                  <SolidButton
                    text={
                      changeUserStatusLoading ? (
                        <>
                          <SpinnerCmp
                            enabled={true}
                            color={appTheme.COLORS.bluePrimary}
                            size={20}
                            secondaryColor={appTheme.COLORS.white}
                          />
                        </>
                      ) : (
                        <>
                          <FlagAlt color="#3E4954" size={15} />{" "}
                          {userData?.user?.isFlagged ? "Unflag" : "Flag"}
                        </>
                      )
                    }
                    type="submit"
                    weighty="500"
                    textFontSize="14px"
                    borderColor="#3E4954"
                    borderHoverColor="#3E4954"
                    specifyPadding="0.5rem 0.3rem"
                    color="#3E4954"
                    widthWebkit="100%"
                    widthMoz="100%"
                    widthNormal="100%"
                    maxWidth="80px"
                    borderRadius="6px"
                    backColor="transparent"
                    backgroundHoverColor="transparent"
                    textHoverColor="#3E4954"
                    disabled={changeUserStatusLoading ? true : false}
                    onClick={async () => {
                      const response = await setChangeUserStatus(params.id, {
                        isFlagged: !userData?.user?.isFlagged,
                      });

                      if (
                        response &&
                        response.status === 200 &&
                        response.message === "User updated successfully"
                      ) {
                        setTriggerReloadUser(!triggerReloadUser);
                        return;
                      }
                    }}
                  />
                </div>
                <div>
                  <AvailableBal>Available Balance</AvailableBal>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: "32px",
                      color: "#323232",
                    }}
                  >
                    <NumberFormat
                      thousandSeparator
                      prefix="₦"
                      value={userData?.balance?.balance}
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  </div>
                  {["Super Admin"].includes(userRole) && (
                    <div style={{ paddingTop: "2rem" }}>
                      <Formik
                        enableReinitialize={false}
                        validationSchema={nairaDepositSchema}
                        initialValues={{
                          amount: "",
                        }}
                        onSubmit={async (values, { resetForm }) => {
                          // resetForm();

                          if (txnType === "deposit") {
                            const response = await setDepositIntoUserWallet(
                              params.id,
                              { amount: values.amount }
                            );

                            if (
                              response &&
                              response.status === 200 &&
                              response.message ===
                                "Successfully added to user balance"
                            ) {
                              setTriggerReloadUser(!triggerReloadUser);
                              return;
                            }
                          } else {
                            const response = await setWithdrawFromUserWallet(
                              params.id,
                              { amount: values.amount }
                            );

                            if (
                              response &&
                              response.status === 200 &&
                              response.message ===
                                "Successfully removed user balance"
                            ) {
                              setTriggerReloadUser(!triggerReloadUser);
                              return;
                            }
                          }
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          isValid,
                          setFieldTouched,
                          setFieldValue,
                          touched,
                        }) => (
                          <>
                            <form noValidate onSubmit={handleSubmit}>
                              <CustomPriceInputCmp
                                background="rgba(225, 235, 245, 0.1)"
                                required={true}
                                icon={
                                  <>
                                    <NairaImg src={NairaIcon} alt="Naira" />
                                  </>
                                }
                                type="text"
                                name="amount"
                                placeholder="Enter Amount"
                                values={values}
                                setFieldValue={setFieldValue}
                                message={`${
                                  errors["amount"] ? errors["amount"] : ""
                                }`}
                                showMessage={true}
                              />
                              <ButtonWrapper
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  paddingTop: "1rem",
                                }}
                              >
                                <SolidButton
                                  text={
                                    depositIntoUserWalletLoading ? (
                                      <>
                                        <SpinnerCmp
                                          enabled={true}
                                          color={appTheme.COLORS.bluePrimary}
                                          size={20}
                                          secondaryColor={appTheme.COLORS.white}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <PlusCircleFill
                                          color="#016CBA"
                                          size={18}
                                        />{" "}
                                        Deposit
                                      </>
                                    )
                                  }
                                  type="submit"
                                  weighty="500"
                                  textFontSize="14px"
                                  specifyPadding="0.8rem 1.5rem"
                                  color="#016CBA"
                                  widthWebkit="100%"
                                  widthMoz="100%"
                                  widthNormal="100%"
                                  borderRadius="8px"
                                  backColor="rgba(177, 222, 255, 0.2);"
                                  backgroundHoverColor="rgba(177, 222, 255, 0.2);"
                                  textHoverColor="#016CBA"
                                  margin="0rem auto 0"
                                  disabled={
                                    !isValid || depositIntoUserWalletLoading
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    setTxnType("deposit");
                                  }}
                                />
                                <SolidButton
                                  text={
                                    withdrawFromUserWalletLoading ? (
                                      <>
                                        <SpinnerCmp
                                          enabled={true}
                                          color={appTheme.COLORS.bluePrimary}
                                          size={20}
                                          secondaryColor={appTheme.COLORS.white}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Money color="#016CBA" size={18} />{" "}
                                        Withdraw
                                      </>
                                    )
                                  }
                                  type="submit"
                                  weighty="500"
                                  onClick={() => {
                                    setTxnType("withdraw");
                                  }}
                                  textFontSize="14px"
                                  specifyPadding="0.8rem 1.5rem"
                                  color="#016CBA"
                                  widthWebkit="100%"
                                  widthMoz="100%"
                                  widthNormal="100%"
                                  borderRadius="8px"
                                  backColor="rgba(177, 222, 255, 0.2);"
                                  backgroundHoverColor="rgba(177, 222, 255, 0.2);"
                                  textHoverColor="#016CBA"
                                  margin="0rem auto 0"
                                  disabled={
                                    !isValid || withdrawFromUserWalletLoading
                                      ? true
                                      : false
                                  }
                                />
                              </ButtonWrapper>
                            </form>
                          </>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </OtcWrapper>
            )}
          </Wrapper>
        </ParentWrapper>
        <>
          <Title>Transactions</Title>
          {loadingState ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SpinnerCmp
                enabled={true}
                color={appTheme.COLORS.bluePrimary}
                size={24}
                secondaryColor={appTheme.COLORS.white}
              />
            </div>
          ) : !userData?.txns?.results?.length ? (
            <TxnWrapper>
              <NoTxnWrapper>
                <NoTxnTitle>No transaction yet</NoTxnTitle>
                <NoTxnDesc>
                  User's recent transaction would appear here.
                </NoTxnDesc>
              </NoTxnWrapper>
            </TxnWrapper>
          ) : (
            <TableCmp
              containerHeight="350px"
              enablePagination={true}
              defaultPageSize={30}
              data={userData?.txns?.results}
              dataCount={dataCount}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              columns={[
                {
                  Header: "",
                  accessor: "txn",
                  id: "icon",
                  maxWidth: 100,
                  minWidth: 60,
                  width: 100,
                  Cell: (props) => {
                    return (
                      <LazyLoadImage
                        style={{ width: "40px", height: "40px" }}
                        src={iconCategory[props.value.txnType]}
                        alt="icon"
                        effect="blur"
                      />
                    );
                  },
                },
                {
                  Header: "Transaction ID",
                  accessor: "txn",
                  Cell: (props) => {
                    return <p style={{ margin: "0" }}>{props.value.txnId}</p>;
                  },
                },
                {
                  Header: "Category",
                  accessor: "txn",
                  id: "txnType",
                  maxWidth: 200,
                  minWidth: 180,
                  width: 200,
                  Cell: (props) => {
                    return (
                      <p
                        style={{
                          fontWeight: "700",
                          margin: "0",
                        }}
                      >
                        {props.value.txnType}
                      </p>
                    );
                  },
                },
                {
                  Header: "Status",
                  accessor: "txn",
                  id: "status",
                  maxWidth: 200,
                  minWidth: 100,
                  width: 200,
                  Cell: (props) => {
                    return (
                      <p
                        style={{
                          color: statusColorMap[props.value.status],
                          margin: "0",
                          fontWeight: "700",
                        }}
                      >
                        {props.value.status}
                      </p>
                    );
                  },
                },
                {
                  Header: "Amount",
                  accessor: "txn",
                  id: "amount",
                  maxWidth: 200,
                  minWidth: 100,
                  width: 200,
                  Cell: (props) => {
                    return (
                      <p
                        style={{
                          fontWeight: "700",
                          margin: "0",
                        }}
                      >
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={
                            props.value.otherPrice ||
                            props.value.totalAmount ||
                            props.value.amount
                          }
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      </p>
                    );
                  },
                },
                {
                  Header: "Date",
                  accessor: "txn",
                  id: "date",
                  maxWidth: 200,
                  minWidth: 140,
                  width: 200,
                  Cell: (props) => {
                    return (
                      <p style={{ margin: "0" }}>
                        {new Date(props.value.createdAt).toDateString()}
                      </p>
                    );
                  },
                },
              ]}
            />
          )}
        </>
        <SecondButtonWrapper>
          {" "}
          <SolidButton
            text={
              changeUserStatusLoading ? (
                <>
                  <SpinnerCmp
                    enabled={true}
                    color={appTheme.COLORS.bluePrimary}
                    size={20}
                    secondaryColor={appTheme.COLORS.white}
                  />
                </>
              ) : (
                <>
                  {userData?.user?.isRevoke
                    ? "Unrevoke User Access"
                    : "Revoke User Access"}
                </>
              )
            }
            type="submit"
            weighty="500"
            textFontSize="14px"
            borderColor="#C12B2B"
            borderHoverColor="#C12B2B"
            specifyPadding="0.5rem 1rem"
            color="#C12B2B"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            maxWidth="175px"
            borderRadius="6px"
            backColor="transparent"
            backgroundHoverColor="transparent"
            textHoverColor="#C12B2B"
            disabled={changeUserStatusLoading ? true : false}
            onClick={async () => {
              const response = await setChangeUserStatus(params.id, {
                isRevoke: !userData?.user?.isRevoke,
              });

              if (
                response &&
                response.status === 200 &&
                response.message === "User updated successfully"
              ) {
                setTriggerReloadUser(!triggerReloadUser);
                return;
              }
            }}
          />
          <SolidButton
            text={
              resetAUser2faLoading ? (
                <>
                  <SpinnerCmp
                    enabled={true}
                    color={appTheme.COLORS.bluePrimary}
                    size={20}
                    secondaryColor={appTheme.COLORS.white}
                  />
                </>
              ) : (
                <>Reset User 2FA</>
              )
            }
            type="submit"
            weighty="500"
            textFontSize="14px"
            borderColor="#C12B2B"
            borderHoverColor="#C12B2B"
            specifyPadding="0.5rem 1rem"
            color="#C12B2B"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            maxWidth="175px"
            borderRadius="6px"
            backColor="transparent"
            backgroundHoverColor="transparent"
            textHoverColor="#C12B2B"
            disabled={resetAUser2faLoading ? true : false}
            onClick={async () => {
              const response = await setResetAUser2fa(params.id);

              if (
                response &&
                response.status === 200 &&
                response.message === "User 2fa reset successfully"
              ) {
                toast.success("User 2fa reset successfully");
                return;
              }
            }}
          />
        </SecondButtonWrapper>
      </>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  aUserLoading: state.user.aUserLoading,
  aUserSuccess: state.user.aUser,
  aUserError: state.user.aUserError,
  depositIntoUserWalletLoading: state.user.depositIntoUserWalletLoading,
  depositIntoUserWallet: state.user.depositIntoUserWallet,
  withdrawFromUserWalletLoading: state.user.withdrawFromUserWalletLoading,
  withdrawFromUserWallet: state.user.withdrawFromUserWallet,
  changeUserStatusLoading: state.user.changeUserStatusLoading,
  resetAUser2faLoading: state.user.resetUser2faLoading,
  userRole: state.auth.userRole,
});

const mapDispatch = (dispatch) => ({
  setGetAUser: (id, page) => dispatch(getAUser(id, page)),
  setResetAUser2fa: (id) => dispatch(resetAUser2fa(id)),
  setDepositIntoUserWallet: (id, data) =>
    dispatch(depositIntoUserWallet(id, data)),
  setWithdrawFromUserWallet: (id, data) =>
    dispatch(withdrawFromUserWallet(id, data)),
  setChangeUserStatus: (id, data) => dispatch(changeUserStatus(id, data)),
});

export default connect(mapState, mapDispatch)(ViewUser);
