import axios from "axios";
import {
  ALL_TICKETS_LOADING,
  ALL_TICKETS_SUCCESS,
  A_TICKET_LOADING,
  A_TICKET_SUCCESS,
  DELETE_TICKET_LOADING,
  UPDATE_TICKET_STATUS_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllTicket =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_TICKETS_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/ticket/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: ALL_TICKETS_LOADING, payload: null });
      dispatch({ type: ALL_TICKETS_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: ALL_TICKETS_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const getATicket = (id) => async (dispatch) => {
  dispatch({ type: A_TICKET_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/ticket/get-ticket/${id}`,
      headers()
    );
    dispatch({ type: A_TICKET_LOADING, payload: null });
    dispatch({ type: A_TICKET_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_TICKET_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const updateTicketStatus = (status, id) => async (dispatch) => {
  dispatch({ type: UPDATE_TICKET_STATUS_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/ticket/update-ticket/${id}`,
      { status },
      headers()
    );
    dispatch({ type: UPDATE_TICKET_STATUS_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAllTicket());
    toast.success("Ticket updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: UPDATE_TICKET_STATUS_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const deleteATicket = (id) => async (dispatch) => {
  dispatch({ type: DELETE_TICKET_LOADING, payload: true });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/ticket/delete-ticket/${id}`,
      headers()
    );
    dispatch({ type: DELETE_TICKET_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAllTicket());
    toast.success("Ticket deleted successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_TICKET_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
