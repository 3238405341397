import React, { memo, useEffect } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import JessieLogo from "../../assets/logo-color.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SidebarItem from "./SidebarItem";
import {
  accountSidebarData,
  adminSidebarData,
  agentSidebarData,
  saleSidebarData,
  superAdminSidebarData,
  supportSidebarData,
} from "./sidebarData";
import { CloseOutline } from "@styled-icons/evaicons-outline";
import { connect } from "react-redux";
import { getUserRole } from "../../redux/actions/authActions";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";

const SidebarWrapper = styled.div`
  flex: 0 0 17%;
  background-color: ${appTheme.COLORS.white};
  height: 100vh;
  position: fixed;
  max-width: 17%;
  width: 100%;
  top: 0;
  z-index: 1070;

  @media only screen and (max-width: 991px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
    left: -300px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: display 0.3s ease all;
    transition: display 0.3s ease all;
    max-width: 30%;
    flex: 0 0 30%;

    &.open {
      -webkit-transform: translateX(300px);
      transform: translateX(300px);
      display: unset;
    }
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 45%;
    max-width: 45%;
  }

  @media only screen and (max-width: 500px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (max-width: 400px) {
    flex: 0 0 60%;
    max-width: 60%;
  }
`;

const LogoWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    padding-top: 1rem;
    padding-right: 1rem;
    text-align: right;
  }
`;

const CloseIconWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 767px) {
    display: unset;
  }
`;

const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  margin: 1rem 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const LogoImg = styled(LazyLoadImage)`
  width: 137px;
  height: 46px;
`;

const SidebarContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 3.75rem);
  padding-bottom: 2rem;

  @media only screen and (max-width: 767px) {
    padding-top: 2rem;
  }
`;

const SectionTitle = styled.h2`
  font-weight: ${appTheme.WEIGHT.small2};
  font-size: 14px;
  color: #abafb3;
  padding: 1rem 0.8rem;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #fff;
  margin: 1.5rem 0;
  border-top: 1px solid #abafb3;
  opacity: 0.4;
`;

const SidebarCmp = ({
  menuToggle,
  handleToggleOnMobile,
  userRole,
  setGetUserRole,
}) => {
  useEffect(() => {
    setGetUserRole();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let renderSidebar;

  if (userRole === "Super Admin") {
    renderSidebar = superAdminSidebarData;
  } else if (userRole === "Admin") {
    renderSidebar = adminSidebarData;
  } else if (userRole === "Support") {
    renderSidebar = supportSidebarData;
  } else if (userRole === "Sale") {
    renderSidebar = saleSidebarData;
  } else if (userRole === "Agent") {
    renderSidebar = agentSidebarData;
  } else if (userRole === "Account") {
    renderSidebar = accountSidebarData;
  }

  // const renderSidebar = useMemo(() => {
  //   if (userRole === "Super Admin") {
  //     return superAdminSidebarData;
  //   } else if (userRole === "Support") {
  //     return supportSidebarData;
  //   }
  // }, [userRole]);

  return (
    <>
      <SidebarWrapper className={`${menuToggle === true && "open"}`}>
        <LogoWrapper>
          <CloseIconWrapper>
            <CloseIcon
              size={20}
              color="#323232"
              onClick={() => {
                handleToggleOnMobile(!menuToggle);
              }}
            />
          </CloseIconWrapper>
          <LogoContainer>
            <LogoImg src={JessieLogo} alt="Jessie" effect="blur" />
          </LogoContainer>
        </LogoWrapper>
        <SidebarContainer>
          {renderSidebar &&
            renderSidebar.map((item, index) => {
              return (
                <>
                  <div key={`Parent-${index}`}>
                    <SectionTitle>{item.sectionTitle}</SectionTitle>
                    {item.pages.map((page, i) => {
                      return <SidebarItem key={i} item={page} />;
                    })}
                    {index !== renderSidebar.length - 1 && (
                      <>
                        <Line />
                      </>
                    )}
                  </div>
                </>
              );
            })}
          {!renderSidebar && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SpinnerCmp
                enabled={true}
                color={appTheme.COLORS.white}
                size={20}
                secondaryColor={appTheme.COLORS.bluePrimary}
              />
            </div>
          )}
        </SidebarContainer>
      </SidebarWrapper>
    </>
  );
};

const mapState = (state) => ({
  userRole: state.auth.userRole,
});

const mapDispatch = (dispatch) => ({
  setGetUserRole: () => dispatch(getUserRole()),
});

export default connect(mapState, mapDispatch)(memo(SidebarCmp));
