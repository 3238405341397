import {
  ALL_USER_ERROR,
  ALL_USER_LOADING,
  ALL_USER_SUCCESS,
  A_USER_ERROR,
  A_USER_LOADING,
  A_USER_SUCCESS,
  CHANGE_USER_STATUS_ERROR,
  CHANGE_USER_STATUS_LOADING,
  CHANGE_USER_STATUS_SUCCESS,
  DEPOSIT_INTO_USER_WALLET_ERROR,
  DEPOSIT_INTO_USER_WALLET_LOADING,
  DEPOSIT_INTO_USER_WALLET_SUCCESS,
  RESET_USER_2FA_ERROR,
  RESET_USER_2FA_LOADING,
  RESET_USER_2FA_SUCCESS,
  WITHDRAW_FROM_USER_ERROR,
  WITHDRAW_FROM_USER_LOADING,
  WITHDRAW_FROM_USER_SUCCESS,
} from "./types";

const initialState = {
  allUserLoading: null,
  allUser: null,
  allUserError: null,
  aUserLoading: null,
  aUser: null,
  aUserError: null,
  changeUserStatusLoading: null,
  changeUserStatus: null,
  changeUserStatusError: null,
  depositIntoUserWalletLoading: null,
  depositIntoUserWallet: null,
  depositIntoUserWalletError: null,
  withdrawFromUserWalletLoading: null,
  withdrawFromUserWallet: null,
  withdrawFromUserWalletError: null,
  resetUser2faLoading: null,
  resetUser2fa: null,
  resetUser2faError: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_USER_LOADING:
      return { ...state, allUserLoading: action.payload };
    case ALL_USER_SUCCESS:
      return { ...state, allUser: action.payload };
    case ALL_USER_ERROR:
      return { ...state, allUserError: action.payload };
    case A_USER_LOADING:
      return { ...state, aUserLoading: action.payload };
    case A_USER_SUCCESS:
      return { ...state, aUser: action.payload };
    case A_USER_ERROR:
      return { ...state, aUserError: action.payload };
    case CHANGE_USER_STATUS_LOADING:
      return { ...state, changeUserStatusLoading: action.payload };
    case CHANGE_USER_STATUS_SUCCESS:
      return { ...state, changeUserStatus: action.payload };
    case CHANGE_USER_STATUS_ERROR:
      return { ...state, changeUserStatusError: action.payload };
    case DEPOSIT_INTO_USER_WALLET_LOADING:
      return { ...state, depositIntoUserWalletLoading: action.payload };
    case DEPOSIT_INTO_USER_WALLET_SUCCESS:
      return { ...state, depositIntoUserWallet: action.payload };
    case DEPOSIT_INTO_USER_WALLET_ERROR:
      return { ...state, depositIntoUserWalletError: action.payload };
    case WITHDRAW_FROM_USER_LOADING:
      return { ...state, withdrawFromUserWalletLoading: action.payload };
    case WITHDRAW_FROM_USER_SUCCESS:
      return { ...state, withdrawFromUserWallet: action.payload };
    case WITHDRAW_FROM_USER_ERROR:
      return { ...state, withdrawFromUserWalletError: action.payload };
    case RESET_USER_2FA_LOADING:
      return { ...state, resetUser2faLoading: action.payload };
    case RESET_USER_2FA_SUCCESS:
      return { ...state, resetUser2fa: action.payload };
    case RESET_USER_2FA_ERROR:
      return { ...state, resetUser2faError: action.payload };
    default:
      return state;
  }
}
