import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllNewsCmp from "./AllOtcs";

const Wrapper = styled.div``;

const Otc = () => {
  return (
    <>
      <DashboardLayout pageTitle="OTC">
        <Wrapper>
          <AllNewsCmp />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Otc;
