import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import TableCmp from "../../components/TableCmp/TableCmp";
import { convertToLocalDateTime } from "../../utils/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import UserPlaceholder from "../../assets/user-placeholder.jpg";
import {
  deleteAnAdmin,
  getAllAdmins,
  grantRevokeAdminAccess,
} from "../../redux/actions/roleAction";
import { Checkmark } from "@styled-icons/ionicons-outline";
import { Cancel } from "@styled-icons/material-outlined";
import { Pencil, Trash } from "@styled-icons/boxicons-regular";
import ActionModal from "../../components/Modals/ActionModal";
import rolesOption from "../../constants/roles";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const WrapperImageText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BlogImgWrapper = styled(LazyLoadImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllRolesCmp = ({
  setGetAllAdmins,
  setDeleteAnAdmin,
  setGrantRevokeAdminAccess,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [item, setItem] = useState();
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAllAdmin = async () => {
      setLoadingState(true);
      const response = await setGetAllAdmins(
        `?role=${rolesOption[params?.userRole]}&page=${currentPage}`
      );

      setAdminData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    getAllAdmin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, params?.userRole, currentPage]);

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : adminData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...adminData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "",
                accessor: "profilePicUrl",
                id: "picture",
                maxWidth: 300,
                minWidth: 100,
                width: 100,
                Cell: (props) => {
                  return (
                    <WrapperImageText>
                      <BlogImgWrapper
                        src={
                          props.row.original.profilePicUrl || UserPlaceholder
                        }
                        alt={props.row.original.fullName}
                        effect="blur"
                      />
                    </WrapperImageText>
                  );
                },
              },
              {
                Header: "Name",
                accessor: "fullName",
                maxWidth: 300,
                minWidth: 180,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Email",
                accessor: "email",
                maxWidth: 300,
                minWidth: 100,
                width: 250,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Phone Number",
                accessor: "phone",
                maxWidth: 300,
                minWidth: 100,
                width: 230,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Date Joined",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value
                        ? convertToLocalDateTime(props.value)
                        : new Date().toDateString()}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        <SolidButton
                          text={
                            <>
                              {row?.original?.isRevoke ? (
                                <Checkmark color="#58c283" size={15} />
                              ) : (
                                <Cancel color="#D60000" size={15} />
                              )}
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor={
                            row?.original?.isRevoke ? "#58c283" : "#D60000"
                          }
                          borderHoverColor={
                            row?.original?.isRevoke ? "#58c283" : "#D60000"
                          }
                          onClick={async () => {
                            await setGrantRevokeAdminAccess(
                              {
                                isRevoke: !row?.original?.isRevoke,
                              },
                              row?.original?._id
                            );
                            setRefresh(!refresh);
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color={
                            row?.original?.isRevoke ? "#58c283" : "#D60000"
                          }
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor={
                            row?.original?.isRevoke ? "#58c283" : "#D60000"
                          }
                        />
                        <SolidButton
                          text={
                            <>
                              <Pencil color="#2a9bf2" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#2a9bf2"
                          borderHoverColor="#2a9bf2"
                          onClick={() => {
                            navigate(
                              `/roles-edit/${params?.userRole}/${row.values._id}`
                            );
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#2a9bf2"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#2a9bf2"
                        />
                        <SolidButton
                          text={
                            <>
                              <Trash color="#D60000" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#D60000"
                          borderHoverColor="#D60000"
                          onClick={() => {
                            setShowActionModal(true);
                            setItem(row.values);
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#D60000"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#D60000"
                        />
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No admin signed up yet"
            desc="No admin info"
          />
        )}
      </Wrapper>
      <ActionModal
        maxWidth="450px"
        wrapperPadding={"1rem 1rem 2rem"}
        showCloseIcon={true}
        isOpen={showActionModal}
        onRequestClose={() => {
          setShowActionModal(false);
        }}
        runAction={() => {
          setDeleteAnAdmin(item._id);
          setRefresh(true);
        }}
        actionOnReject={() => {
          setItem();
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.role.getAllAdminsLoading,
  error: state.role.getAllAdminsError,
  admins: state.role.getAllAdmins,
});

const mapDispatch = (dispatch) => ({
  setGetAllAdmins: (query) => dispatch(getAllAdmins(query)),
  setDeleteAnAdmin: (adminId) => dispatch(deleteAnAdmin(adminId)),
  setGrantRevokeAdminAccess: (data, adminId) =>
    dispatch(grantRevokeAdminAccess(data, adminId)),
});

export default connect(mapState, mapDispatch)(AllRolesCmp);
