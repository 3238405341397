import React, { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import getSymbolFromCurrency from "currency-symbol-map";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import { useEffect } from "react";
import {
  aGiftcardSellAction,
  getAPendingTransaction,
} from "../../redux/actions/giftcardActions";
import { convertToLocalDateTime } from "../../utils/utils";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  /* max-width: 768px; */
  width: 100%;
  padding: 0rem 2rem;

  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const NewsFormWrapper = styled(Form)``;

const ImagePreviewWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const Image = styled(LazyLoadImage)`
  object-fit: cover;
  width: 100%;
  height: 200px;
`;

const NewsWrapper = styled.div`
  /* max-width: 500px; */
  /* margin: 0 auto; */
  width: 100%;
  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const ViewAGiftcardTransaction = ({
  setAGiftcardSellAction,
  setGetATransaction,
  loading,
  aTxnLoading,
  aTxn,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [, setATxnData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getATxn = async () => {
      const response = await setGetATransaction(id);

      setATxnData(response.data);
      setRefresh(false);
    };
    if (refresh) {
      getATxn();
    } else {
      getATxn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <DashboardLayout pageTitle="View a transaction">
      {aTxn && (
        <Wrapper>
          <Formik
            // enableReinitialize={true}
            initialValues={{
              txnId: aTxn?.data?.txnId,
              quantity: aTxn?.data?.quantity,
              giftcard: aTxn?.data?.brand?.brandName,
              cardType: aTxn?.data?.giftcardType,
              currency: aTxn?.data?.currencyCode,
              cardValue: aTxn?.data?.fixedRecipientDenomination,
              rate: aTxn?.data?.nairaDollarRate,
              nairaValue: aTxn?.data?.totalAmount,
              date: convertToLocalDateTime(aTxn?.data?.createdAt),
              otherPrice: "",
              reason: "",
              description: "",
            }}
            // validationSchema={createNewsSchema}
            onSubmit={async (values, { resetForm }) => {
              let data = { status: "success" };

              if (values?.otherPrice) {
                data["otherPrice"] = values?.otherPrice;
              }
              const response = await setAGiftcardSellAction(data, id);

              if (
                response &&
                response.status === 200 &&
                response.message === "Giftcard operation successful"
              ) {
                return navigate("/giftcards/pending/transactions");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              isValid,
              touched,
            }) => (
              <>
                <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                  <NewsTitle>Transaction Details</NewsTitle>
                  <NewsWrapper>
                    {aTxn?.data && aTxn?.data?.images && (
                      <ImagePreviewWrapper>
                        {aTxn?.data?.images?.map((v) => {
                          return (
                            <Image src={v.url} alt="Preview" effect="blur" />
                          );
                        })}
                      </ImagePreviewWrapper>
                    )}
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Transaction ID</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="txnId"
                          placeholder="Enter Transaction ID"
                          showMessage
                          message={`${errors["txnId"] ? errors["txnId"] : ""}`}
                          disabled={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Quantity</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="quantity"
                          placeholder="Enter Quantity"
                          showMessage
                          message={`${
                            errors["quantity"] ? errors["quantity"] : ""
                          }`}
                          disabled={true}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Giftcard</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="giftcard"
                          placeholder="Giftcard"
                          showMessage
                          message={`${
                            errors["giftcard"] ? errors["giftcard"] : ""
                          }`}
                          disabled={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Card type</InputLabel>
                        <CustomInputSelectCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          name="cardType"
                          values={values}
                          onChange={handleChange}
                          placeholder="Select"
                          data={[
                            "Physical card",
                            "E-code",
                            "Cash Receipt",
                            "No Receipt",
                            "Debit Receipt",
                          ]}
                          message={`${
                            errors["cardType"] ? errors["cardType"] : ""
                          }`}
                          showMessage={true}
                          disabled={true}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Currency</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="currency"
                          placeholder="Enter Currency"
                          showMessage
                          message={`${
                            errors["currency"] ? errors["currency"] : ""
                          }`}
                          disabled={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Card value</InputLabel>
                        <CustomPriceInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="cardValue"
                          placeholder="Enter Card Value"
                          showMessage
                          message={`${
                            errors["cardValue"] ? errors["cardValue"] : ""
                          }`}
                          disabled={true}
                          prefix={getSymbolFromCurrency(
                            aTxn?.data?.currencyCode
                          )}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Rate</InputLabel>
                        <CustomPriceInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="rate"
                          placeholder="Enter Rate"
                          showMessage
                          message={`${errors["rate"] ? errors["rate"] : ""}`}
                          disabled={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Naira Value(₦)</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="nairaValue"
                          placeholder="Enter Naira value"
                          showMessage
                          message={`${
                            errors["nairaValue"] ? errors["nairaValue"] : ""
                          }`}
                          disabled={true}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Date</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="date"
                          placeholder="Enter Date"
                          showMessage
                          message={`${errors["date"] ? errors["date"] : ""}`}
                          disabled={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Other amount</InputLabel>
                        <CustomPriceInputCmp
                          required={true}
                          values={values}
                          setFieldValue={setFieldValue}
                          type="text"
                          name="otherPrice"
                          placeholder="Enter other amount"
                          showMessage
                          message={`${
                            errors["otherPrice"] ? errors["otherPrice"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Reason</InputLabel>
                        <CustomInputSelectCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          name="reason"
                          values={values}
                          onChange={handleChange}
                          placeholder="Choose reason"
                          data={[
                            "INVALID CARDS",
                            "BAD CARDS",
                            "ALREADY REDEEMED CARDS",
                            "REPEATED CARDS",
                            "BLURRY CARDS",
                            "CARD NEED MORE INFO",
                            "ERROR REDEEMING CODE",
                            "ONLY RECIEPT UPLOADED",
                            "CARD UPLOADED ON WRONG CHANNEL",
                            "OTHERS",
                          ]}
                          message={`${
                            errors["reason"] ? errors["reason"] : ""
                          }`}
                          showMessage={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Description</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="description"
                          placeholder="Enter description"
                          showMessage
                          message={`${
                            errors["description"] ? errors["description"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <ButtonWrapper>
                      <SolidButton
                        text={
                          loading ? (
                            <>
                              <SpinnerCmp
                                enabled={true}
                                color={appTheme.COLORS.bluePrimary}
                                size={20}
                                secondaryColor={appTheme.COLORS.white}
                              />
                            </>
                          ) : (
                            "Successful"
                          )
                        }
                        margin="2rem 0"
                        type="submit"
                        weighty="500"
                        textFontSize="16px"
                        widthWebkit="100%"
                        widthMoz="100%"
                        widthNormal="100%"
                        maxWidth="150px"
                        specifyPadding="1rem 1.5rem"
                        color="#2BC155"
                        borderRadius="7px"
                        backColor="transparent"
                        backgroundHoverColor="transparent"
                        textHoverColor="#2BC155"
                        borderColor="#28c155"
                        borderHoverColor="#28c155"
                        disabled={loading ? true : false}
                      />
                      <SolidButton
                        onClick={async () => {
                          let data = { status: "declined" };

                          if (values?.reason) {
                            data["reason"] = values?.reason;
                          }
                          if (values?.description) {
                            data["description"] = values?.description;
                          }
                          const response = await setAGiftcardSellAction(
                            data,
                            id
                          );

                          if (
                            response &&
                            response.status === 200 &&
                            response.message === "Giftcard operation successful"
                          ) {
                            return navigate("/giftcards/pending/transactions");
                          }
                        }}
                        text={
                          loading ? (
                            <>
                              <SpinnerCmp
                                enabled={true}
                                color={appTheme.COLORS.bluePrimary}
                                size={20}
                                secondaryColor={appTheme.COLORS.white}
                              />
                            </>
                          ) : (
                            "Decline"
                          )
                        }
                        margin="2rem 0"
                        type="submit"
                        weighty="500"
                        textFontSize="16px"
                        widthWebkit="100%"
                        widthMoz="100%"
                        widthNormal="100%"
                        maxWidth="150px"
                        specifyPadding="1rem 1.5rem"
                        borderRadius="7px"
                        color="#fff"
                        backColor="#C12B2B"
                        backgroundHoverColor="#C12B2B"
                        textHoverColor="#fff"
                        disabled={loading ? true : false}
                      />
                    </ButtonWrapper>
                  </NewsWrapper>
                </NewsFormWrapper>
              </>
            )}
          </Formik>
        </Wrapper>
      )}
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.giftcard.aPendingGiftcardSellActionLoading,
  aTxnLoading: state.giftcard.aPendingGiftcardSellTxnLoading,
  aTxn: state.giftcard.aPendingGiftcardSellTxn,
});

const mapDispatch = (dispatch) => ({
  setAGiftcardSellAction: (data, id) => dispatch(aGiftcardSellAction(data, id)),
  setGetATransaction: (id) => dispatch(getAPendingTransaction(id)),
});

export default connect(mapState, mapDispatch)(ViewAGiftcardTransaction);
