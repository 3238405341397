const countries = [
  {
    country: "Afghanistan",
    countryCode: "AF",
  },
  {
    country: "Albania",
    countryCode: "AL",
  },
  {
    country: "Algeria",
    countryCode: "DZ",
  },
  {
    country: "American Samoa",
    countryCode: "AS",
  },
  {
    country: "Andorra",
    countryCode: "AD",
  },
  {
    country: "Angola",
    countryCode: "AO",
  },
  {
    country: "Anguilla",
    countryCode: "AI",
  },
  {
    country: "Antarctica",
    countryCode: "AQ",
  },
  {
    country: "Antigua and Barbuda",
    countryCode: "AG",
  },
  {
    country: "Argentina",
    countryCode: "AR",
  },
  {
    country: "Armenia",
    countryCode: "AM",
  },
  {
    country: "Aruba",
    countryCode: "AW",
  },
  {
    country: "Australia",
    countryCode: "AU",
  },
  {
    country: "Austria",
    countryCode: "AT",
  },
  {
    country: "Azerbaijan",
    countryCode: "AZ",
  },
  {
    country: "Bahamas (the)",
    countryCode: "BS",
  },
  {
    country: "Bahrain",
    countryCode: "BH",
  },
  {
    country: "Bangladesh",
    countryCode: "BD",
  },
  {
    country: "Barbados",
    countryCode: "BB",
  },
  {
    country: "Belarus",
    countryCode: "BY",
  },
  {
    country: "Belgium",
    countryCode: "BE",
  },
  {
    country: "Belize",
    countryCode: "BZ",
  },
  {
    country: "Benin",
    countryCode: "BJ",
  },
  {
    country: "Bermuda",
    countryCode: "BM",
  },
  {
    country: "Bhutan",
    countryCode: "BT",
  },
  {
    country: "Bolivia (Plurinational State of)",
    countryCode: "BO",
  },
  {
    country: "Bonaire, Sint Eustatius and Saba",
    countryCode: "BQ",
  },
  {
    country: "Bosnia and Herzegovina",
    countryCode: "BA",
  },
  {
    country: "Botswana",
    countryCode: "BW",
  },
  {
    country: "Bouvet Island",
    countryCode: "BV",
  },
  {
    country: "Brazil",
    countryCode: "BR",
  },
  {
    country: "British Indian Ocean Territory (the)",
    countryCode: "IO",
  },
  {
    country: "Brunei Darussalam",
    countryCode: "BN",
  },
  {
    country: "Bulgaria",
    countryCode: "BG",
  },
  {
    country: "Burkina Faso",
    countryCode: "BF",
  },
  {
    country: "Burundi",
    countryCode: "BI",
  },
  {
    country: "Cabo Verde",
    countryCode: "CV",
  },
  {
    country: "Cambodia",
    countryCode: "KH",
  },
  {
    country: "Cameroon",
    countryCode: "CM",
  },
  {
    country: "Canada",
    countryCode: "CA",
  },
  {
    country: "Cayman Islands (the)",
    countryCode: "KY",
  },
  {
    country: "Central African Republic (the)",
    countryCode: "CF",
  },
  {
    country: "Chad",
    countryCode: "TD",
  },
  {
    country: "Chile",
    countryCode: "CL",
  },
  {
    country: "China",
    countryCode: "CN",
  },
  {
    country: "Christmas Island",
    countryCode: "CX",
  },
  {
    country: "Cocos (Keeling) Islands (the)",
    countryCode: "CC",
  },
  {
    country: "Colombia",
    countryCode: "CO",
  },
  {
    country: "Comoros (the)",
    countryCode: "KM",
  },
  {
    country: "Congo (the Democratic Republic of the)",
    countryCode: "CD",
  },
  {
    country: "Congo (the)",
    countryCode: "CG",
  },
  {
    country: "Cook Islands (the)",
    countryCode: "CK",
  },
  {
    country: "Costa Rica",
    countryCode: "CR",
  },
  {
    country: "Croatia",
    countryCode: "HR",
  },
  {
    country: "Cuba",
    countryCode: "CU",
  },
  {
    country: "Curaçao",
    countryCode: "CW",
  },
  {
    country: "Cyprus",
    countryCode: "CY",
  },
  {
    country: "Czechia",
    countryCode: "CZ",
  },
  {
    country: "Côte d'Ivoire",
    countryCode: "CI",
  },
  {
    country: "Denmark",
    countryCode: "DK",
  },
  {
    country: "Djibouti",
    countryCode: "DJ",
  },
  {
    country: "Dominica",
    countryCode: "DM",
  },
  {
    country: "Dominican Republic (the)",
    countryCode: "DO",
  },
  {
    country: "Ecuador",
    countryCode: "EC",
  },
  {
    country: "Egypt",
    countryCode: "EG",
  },
  {
    country: "El Salvador",
    countryCode: "SV",
  },
  {
    country: "Equatorial Guinea",
    countryCode: "GQ",
  },
  {
    country: "Eritrea",
    countryCode: "ER",
  },
  {
    country: "Estonia",
    countryCode: "EE",
  },
  {
    country: "Eswatini",
    countryCode: "SZ",
  },
  {
    country: "Ethiopia",
    countryCode: "ET",
  },
  {
    country: "Falkland Islands (the) [Malvinas]",
    countryCode: "FK",
  },
  {
    country: "Faroe Islands (the)",
    countryCode: "FO",
  },
  {
    country: "Fiji",
    countryCode: "FJ",
  },
  {
    country: "Finland",
    countryCode: "FI",
  },
  {
    country: "France",
    countryCode: "FR",
  },
  {
    country: "French Guiana",
    countryCode: "GF",
  },
  {
    country: "French Polynesia",
    countryCode: "PF",
  },
  {
    country: "Gabon",
    countryCode: "GA",
  },
  {
    country: "Gambia (the)",
    countryCode: "GM",
  },
  {
    country: "Georgia",
    countryCode: "GE",
  },
  {
    country: "Germany",
    countryCode: "DE",
  },
  {
    country: "Ghana",
    countryCode: "GH",
  },
  {
    country: "Gibraltar",
    countryCode: "GI",
  },
  {
    country: "Greece",
    countryCode: "GR",
  },
  {
    country: "Greenland",
    countryCode: "GL",
  },
  {
    country: "Grenada",
    countryCode: "GD",
  },
  {
    country: "Guadeloupe",
    countryCode: "GP",
  },
  {
    country: "Guam",
    countryCode: "GU",
  },
  {
    country: "Guatemala",
    countryCode: "GT",
  },
  {
    country: "Guernsey",
    countryCode: "GG",
  },
  {
    country: "Guinea",
    countryCode: "GN",
  },
  {
    country: "Guinea-Bissau",
    countryCode: "GW",
  },
  {
    country: "Guyana",
    countryCode: "GY",
  },
  {
    country: "Haiti",
    countryCode: "HT",
  },
  {
    country: "Holy See (the)",
    countryCode: "VA",
  },
  {
    country: "Honduras",
    countryCode: "HN",
  },
  {
    country: "Hong Kong",
    countryCode: "HK",
  },
  {
    country: "Hungary",
    countryCode: "HU",
  },
  {
    country: "Iceland",
    countryCode: "IS",
  },
  {
    country: "India",
    countryCode: "IN",
  },
  {
    country: "Indonesia",
    countryCode: "ID",
  },
  {
    country: "Iran (Islamic Republic of)",
    countryCode: "IR",
  },
  {
    country: "Iraq",
    countryCode: "IQ",
  },
  {
    country: "Ireland",
    countryCode: "IE",
  },
  {
    country: "Isle of Man",
    countryCode: "IM",
  },
  {
    country: "Israel",
    countryCode: "IL",
  },
  {
    country: "Italy",
    countryCode: "IT",
  },
  {
    country: "Jamaica",
    countryCode: "JM",
  },
  {
    country: "Japan",
    countryCode: "JP",
  },
  {
    country: "Jersey",
    countryCode: "JE",
  },
  {
    country: "Jordan",
    countryCode: "JO",
  },
  {
    country: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    country: "Kenya",
    countryCode: "KE",
  },
  {
    country: "Kiribati",
    countryCode: "KI",
  },
  {
    country: "Korea (the Democratic People's Republic of)",
    countryCode: "KP",
  },
  {
    country: "Korea (the Republic of)",
    countryCode: "KR",
  },
  {
    country: "Kuwait",
    countryCode: "KW",
  },
  {
    country: "Kyrgyzstan",
    countryCode: "KG",
  },
  {
    country: "Lao People's Democratic Republic (the)",
    countryCode: "LA",
  },
  {
    country: "Latvia",
    countryCode: "LV",
  },
  {
    country: "Lebanon",
    countryCode: "LB",
  },
  {
    country: "Lesotho",
    countryCode: "LS",
  },
  {
    country: "Liberia",
    countryCode: "LR",
  },
  {
    country: "Libya",
    countryCode: "LY",
  },
  {
    country: "Liechtenstein",
    countryCode: "LI",
  },
  {
    country: "Lithuania",
    countryCode: "LT",
  },
  {
    country: "Luxembourg",
    countryCode: "LU",
  },
  {
    country: "Macao",
    countryCode: "MO",
  },
  {
    country: "Madagascar",
    countryCode: "MG",
  },
  {
    country: "Malawi",
    countryCode: "MW",
  },
  {
    country: "Malaysia",
    countryCode: "MY",
  },
  {
    country: "Maldives",
    countryCode: "MV",
  },
  {
    country: "Mali",
    countryCode: "ML",
  },
  {
    country: "Malta",
    countryCode: "MT",
  },
  {
    country: "Marshall Islands (the)",
    countryCode: "MH",
  },
  {
    country: "Martinique",
    countryCode: "MQ",
  },
  {
    country: "Mauritania",
    countryCode: "MR",
  },
  {
    country: "Mauritius",
    countryCode: "MU",
  },
  {
    country: "Mayotte",
    countryCode: "YT",
  },
  {
    country: "Mexico",
    countryCode: "MX",
  },
  {
    country: "Micronesia (Federated States of)",
    countryCode: "FM",
  },
  {
    country: "Moldova (the Republic of)",
    countryCode: "MD",
  },
  {
    country: "Monaco",
    countryCode: "MC",
  },
  {
    country: "Mongolia",
    countryCode: "MN",
  },
  {
    country: "Montenegro",
    countryCode: "ME",
  },
  {
    country: "Montserrat",
    countryCode: "MS",
  },
  {
    country: "Morocco",
    countryCode: "MA",
  },
  {
    country: "Mozambique",
    countryCode: "MZ",
  },
  {
    country: "Myanmar",
    countryCode: "MM",
  },
  {
    country: "Namibia",
    countryCode: "NA",
  },
  {
    country: "Nauru",
    countryCode: "NR",
  },
  {
    country: "Nepal",
    countryCode: "NP",
  },
  {
    country: "Netherlands (the)",
    countryCode: "NL",
  },
  {
    country: "New Caledonia",
    countryCode: "NC",
  },
  {
    country: "New Zealand",
    countryCode: "NZ",
  },
  {
    country: "Nicaragua",
    countryCode: "NI",
  },
  {
    country: "Niger (the)",
    countryCode: "NE",
  },
  {
    country: "Nigeria",
    countryCode: "NG",
  },
  {
    country: "Niue",
    countryCode: "NU",
  },
  {
    country: "Norfolk Island",
    countryCode: "NF",
  },
  {
    country: "Northern Mariana Islands (the)",
    countryCode: "MP",
  },
  {
    country: "Norway",
    countryCode: "NO",
  },
  {
    country: "Oman",
    countryCode: "OM",
  },
  {
    country: "Pakistan",
    countryCode: "PK",
  },
  {
    country: "Palau",
    countryCode: "PW",
  },
  {
    country: "Palestine, State of",
    countryCode: "PS",
  },
  {
    country: "Panama",
    countryCode: "PA",
  },
  {
    country: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    country: "Paraguay",
    countryCode: "PY",
  },
  {
    country: "Peru",
    countryCode: "PE",
  },
  {
    country: "Philippines (the)",
    countryCode: "PH",
  },
  {
    country: "Pitcairn",
    countryCode: "PN",
  },
  {
    country: "Poland",
    countryCode: "PL",
  },
  {
    country: "Portugal",
    countryCode: "PT",
  },
  {
    country: "Puerto Rico",
    countryCode: "PR",
  },
  {
    country: "Qatar",
    countryCode: "QA",
  },
  {
    country: "Republic of North Macedonia",
    countryCode: "MK",
  },
  {
    country: "Romania",
    countryCode: "RO",
  },
  {
    country: "Russian Federation (the)",
    countryCode: "RU",
  },
  {
    country: "Rwanda",
    countryCode: "RW",
  },
  {
    country: "Réunion",
    countryCode: "RE",
  },
  {
    country: "Saint Barthélemy",
    countryCode: "BL",
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    countryCode: "SH",
  },
  {
    country: "Saint Kitts and Nevis",
    countryCode: "KN",
  },
  {
    country: "Saint Lucia",
    countryCode: "LC",
  },
  {
    country: "Saint Martin (French part)",
    countryCode: "MF",
  },
  {
    country: "Saint Pierre and Miquelon",
    countryCode: "PM",
  },
  {
    country: "Saint Vincent and the Grenadines",
    countryCode: "VC",
  },
  {
    country: "Samoa",
    countryCode: "WS",
  },
  {
    country: "San Marino",
    countryCode: "SM",
  },
  {
    country: "Sao Tome and Principe",
    countryCode: "ST",
  },
  {
    country: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    country: "Senegal",
    countryCode: "SN",
  },
  {
    country: "Serbia",
    countryCode: "RS",
  },
  {
    country: "Seychelles",
    countryCode: "SC",
  },
  {
    country: "Sierra Leone",
    countryCode: "SL",
  },
  {
    country: "Singapore",
    countryCode: "SG",
  },
  {
    country: "Sint Maarten (Dutch part)",
    countryCode: "SX",
  },
  {
    country: "Slovakia",
    countryCode: "SK",
  },
  {
    country: "Slovenia",
    countryCode: "SI",
  },
  {
    country: "Solomon Islands",
    countryCode: "SB",
  },
  {
    country: "Somalia",
    countryCode: "SO",
  },
  {
    country: "South Africa",
    countryCode: "ZA",
  },
  {
    country: "South Sudan",
    countryCode: "SS",
  },
  {
    country: "Spain",
    countryCode: "ES",
  },
  {
    country: "Sri Lanka",
    countryCode: "LK",
  },
  {
    country: "Sudan (the)",
    countryCode: "SD",
  },
  {
    country: "Suriname",
    countryCode: "SR",
  },
  {
    country: "Svalbard and Jan Mayen",
    countryCode: "SJ",
  },
  {
    country: "Sweden",
    countryCode: "SE",
  },
  {
    country: "Switzerland",
    countryCode: "CH",
  },
  {
    country: "Syrian Arab Republic",
    countryCode: "SY",
  },
  {
    country: "Taiwan",
    countryCode: "TW",
  },
  {
    country: "Tajikistan",
    countryCode: "TJ",
  },
  {
    country: "Tanzania, United Republic of",
    countryCode: "TZ",
  },
  {
    country: "Thailand",
    countryCode: "TH",
  },
  {
    country: "Timor-Leste",
    countryCode: "TL",
  },
  {
    country: "Togo",
    countryCode: "TG",
  },
  {
    country: "Tokelau",
    countryCode: "TK",
  },
  {
    country: "Tonga",
    countryCode: "TO",
  },
  {
    country: "Trinidad and Tobago",
    countryCode: "TT",
  },
  {
    country: "Tunisia",
    countryCode: "TN",
  },
  {
    country: "Turkey",
    countryCode: "TR",
  },
  {
    country: "Turkmenistan",
    countryCode: "TM",
  },
  {
    country: "Turks and Caicos Islands (the)",
    countryCode: "TC",
  },
  {
    country: "Tuvalu",
    countryCode: "TV",
  },
  {
    country: "Uganda",
    countryCode: "UG",
  },
  {
    country: "Ukraine",
    countryCode: "UA",
  },
  {
    country: "United Arab Emirates (the)",
    countryCode: "AE",
  },
  {
    country: "United Kingdom of Great Britain and Northern Ireland (the)",
    countryCode: "GB",
  },
  {
    country: "United States of America (the)",
    countryCode: "US",
  },
  {
    country: "Uruguay",
    countryCode: "UY",
  },
  {
    country: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    country: "Vanuatu",
    countryCode: "VU",
  },
  {
    country: "Venezuela (Bolivarian Republic of)",
    countryCode: "VE",
  },
  {
    country: "Viet Nam",
    countryCode: "VN",
  },
  {
    country: "Virgin Islands (British)",
    countryCode: "VG",
  },
  {
    country: "Virgin Islands (U.S.)",
    countryCode: "VI",
  },
  {
    country: "Wallis and Futuna",
    countryCode: "WF",
  },
  {
    country: "Western Sahara",
    countryCode: "EH",
  },
  {
    country: "Yemen",
    countryCode: "YE",
  },
  {
    country: "Zambia",
    countryCode: "ZM",
  },
  {
    country: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    country: "Åland Islands",
    countryCode: "AX",
  },
];

const countryCodeSwap = {
  Afghanistan: "AF",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  "Bahamas (the)": "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  "Bolivia (Plurinational State of)": "BO",
  "Bonaire, Sint Eustatius and Saba": "BQ",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory (the)": "IO",
  "Brunei Darussalam": "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  "Cabo Verde": "CV",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cayman Islands (the)": "KY",
  "Central African Republic (the)": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands (the)": "CC",
  Colombia: "CO",
  "Comoros (the)": "KM",
  "Congo (the Democratic Republic of the)": "CD",
  "Congo (the)": "CG",
  "Cook Islands (the)": "CK",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Curaçao: "CW",
  Cyprus: "CY",
  Czechia: "CZ",
  "Côte d'Ivoire": "CI",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic (the)": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Eswatini: "SZ",
  Ethiopia: "ET",
  "Falkland Islands (the) [Malvinas]": "FK",
  "Faroe Islands (the)": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  Gabon: "GA",
  "Gambia (the)": "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Holy See (the)": "VA",
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  "Iran (Islamic Republic of)": "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  "Korea (the Democratic People's Republic of)": "KP",
  "Korea (the Republic of)": "KR",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  "Lao People's Democratic Republic (the)": "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands (the)": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  "Micronesia (Federated States of)": "FM",
  "Moldova (the Republic of)": "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  "Netherlands (the)": "NL",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  "Niger (the)": "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "Northern Mariana Islands (the)": "MP",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestine, State of": "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  "Philippines (the)": "PH",
  Pitcairn: "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  "Republic of North Macedonia": "MK",
  Romania: "RO",
  "Russian Federation (the)": "RU",
  Rwanda: "RW",
  Réunion: "RE",
  "Saint Barthélemy": "BL",
  "Saint Helena, Ascension and Tristan da Cunha": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin (French part)": "MF",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and the Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  "Sint Maarten (Dutch part)": "SX",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Sudan": "SS",
  Spain: "ES",
  "Sri Lanka": "LK",
  "Sudan (the)": "SD",
  Suriname: "SR",
  "Svalbard and Jan Mayen": "SJ",
  Sweden: "SE",
  Switzerland: "CH",
  "Syrian Arab Republic": "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  "Tanzania, United Republic of": "TZ",
  Thailand: "TH",
  "Timor-Leste": "TL",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and Caicos Islands (the)": "TC",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates (the)": "AE",
  "United Kingdom of Great Britain and Northern Ireland (the)": "GB",
  "United States of America (the)": "US",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  "Venezuela (Bolivarian Republic of)": "VE",
  "Viet Nam": "VN",
  "Virgin Islands (British)": "VG",
  "Virgin Islands (U.S.)": "VI",
  "Wallis and Futuna": "WF",
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
  "Åland Islands": "AX",
};

export { countries, countryCodeSwap };
