import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import PrevImg from "../../assets/prev.svg";
import NextImg from "../../assets/next.svg";

const PaginationWrapper = styled.div`
  padding: 1rem 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const PrevNextImg = styled.img`
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  pointer-events: ${(props) => props.disabled && "none"};
`;

const PageSpan = styled.span`
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  color: #323232;
  font-weight: ${appTheme.WEIGHT.small};
  font-size: 18px;
  background-color: ${(props) => (props.setBackColor ? "#fff" : "transparent")};
  border-radius: ${(props) => props.setBackColor && "10px"};
`;

const PaginationCmp = ({
  pageChangeHandler,
  currentPageNo,
  totalRows,
  rowsPerPage,
}) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(currentPageNo);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Onclick handlers for the butons
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo) => setCurrentPage(pageNo);

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    pageChangeHandler(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const renderPages = () => {
    const displayPages = [];

    // Show first three pages
    for (let i = 0; i < Math.min(3, noOfPages); i++) {
      displayPages.push(
        <PageSpan
          key={i + 1}
          setBackColor={i + 1 === currentPage}
          onClick={() => onPageSelect(i + 1)}
        >
          {i + 1}
        </PageSpan>
      );
    }

    // Show ellipsis if currentPage is greater than 3
    if (currentPage > 3) {
      displayPages.push(<span key="startEllipsis">...</span>);
    }

    // Calculate start and end page numbers to display around the current page
    let start = Math.max(currentPage - 1, 3);
    let end = Math.min(currentPage + 1, noOfPages - 1);

    // Ensure start and end are within valid bounds
    if (end - start <= 1) {
      start = Math.max(1, end - 2);
    }

    // Show current page and surrounding pages
    for (let i = start; i <= end; i++) {
      if (i > 3 && i < noOfPages) {
        displayPages.push(
          <PageSpan
            key={i}
            setBackColor={i === currentPage}
            onClick={() => onPageSelect(i)}
          >
            {i}
          </PageSpan>
        );
      }
    }

    // Show ellipsis if currentPage is less than noOfPages - 1
    if (currentPage < noOfPages - 2) {
      displayPages.push(<span key="endEllipsis">...</span>);
    }

    // Show last page
    if (noOfPages > 3) {
      displayPages.push(
        <PageSpan
          key={noOfPages}
          setBackColor={currentPage === noOfPages}
          onClick={() => onPageSelect(noOfPages)}
        >
          {noOfPages}
        </PageSpan>
      );
    }

    return displayPages;
  };

  return (
    <>
      {noOfPages > 1 ? (
        <PaginationWrapper>
          <PrevNextImg
            src={PrevImg}
            alt="Previous"
            onClick={onPrevPage}
            disabled={!canGoBack}
          />
          {renderPages()}
          <PrevNextImg
            src={NextImg}
            alt="Next"
            onClick={onNextPage}
            disabled={!canGoNext}
          />
        </PaginationWrapper>
      ) : null}
    </>
  );
};

export default PaginationCmp;
