import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNetworkState } from "react-use";
import DisableSiteCmp from "./components/DisableSiteCmp/DisableSiteCmp";
import store from "./redux/store";
import RouteCmp from "./Routes";

function App() {
  const { online, previous } = useNetworkState();

  if (online === false && previous === true) {
    toast.warning("You are offline", { toastId: "Offline" });
  }

  if (online === true && previous === false) {
    toast.success("You are online", { toastId: "Online" });
  }
  return (
    <Provider store={store}>
      <ToastContainer autoClose={3000} closeOnClick bodyClassName="toastBody" />
      {online === false && <DisableSiteCmp />}
      <Router>
        <RouteCmp />
      </Router>
    </Provider>
  );
}

export default App;
