import {
  CREATE_AN_ADMIN_ERROR,
  CREATE_AN_ADMIN_LOADING,
  CREATE_AN_ADMIN_SUCCESS,
  DELETE_AN_ADMIN_ERROR,
  DELETE_AN_ADMIN_LOADING,
  DELETE_AN_ADMIN_SUCCESS,
  EDIT_AN_ADMIN_ERROR,
  EDIT_AN_ADMIN_LOADING,
  EDIT_AN_ADMIN_SUCCESS,
  GET_ALL_ADMINS_ERROR,
  GET_ALL_ADMINS_LOADING,
  GET_ALL_ADMINS_SUCCESS,
  GET_AN_ADMIN_ERROR,
  GET_AN_ADMIN_LOADING,
  GET_AN_ADMIN_SUCCESS,
  GRANT_REVOKE_ADMIN_ACCESS_ERROR,
  GRANT_REVOKE_ADMIN_ACCESS_LOADING,
  GRANT_REVOKE_ADMIN_ACCESS_SUCCESS,
} from "./types";

const initialState = {
  getAllAdminsLoading: null,
  getAllAdmins: null,
  getAllAdminsError: null,
  createAnAdminLoading: null,
  createAnAdmin: null,
  createAnAdminError: null,
  getAnAdminLoading: null,
  getAnAdmin: null,
  getAnAdminError: null,
  deleteAnAdminLoading: null,
  deleteAnAdmin: null,
  deleteAnAdminError: null,
  editAnAdminLoading: null,
  editAnAdmin: null,
  editAnAdminError: null,
  grantRevokeAdminAccessLoading: null,
  grantRevokeAdminAccess: null,
  grantRevokeAdminAccessError: null,
};

export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ADMINS_LOADING:
      return { ...state, getAllAdminsLoading: action.payload };
    case GET_ALL_ADMINS_SUCCESS:
      return { ...state, getAllAdmins: action.payload };
    case GET_ALL_ADMINS_ERROR:
      return { ...state, getAllAdminsError: action.payload };
    case CREATE_AN_ADMIN_LOADING:
      return { ...state, createAnAdminLoading: action.payload };
    case CREATE_AN_ADMIN_SUCCESS:
      return { ...state, createAnAdmin: action.payload };
    case CREATE_AN_ADMIN_ERROR:
      return { ...state, createAnAdminError: action.payload };
    case GET_AN_ADMIN_LOADING:
      return { ...state, getAnAdminLoading: action.payload };
    case GET_AN_ADMIN_SUCCESS:
      return { ...state, getAnAdmin: action.payload };
    case GET_AN_ADMIN_ERROR:
      return { ...state, getAnAdminError: action.payload };
    case DELETE_AN_ADMIN_LOADING:
      return { ...state, deleteAnAdminLoading: action.payload };
    case DELETE_AN_ADMIN_SUCCESS:
      return { ...state, deleteAnAdmin: action.payload };
    case DELETE_AN_ADMIN_ERROR:
      return { ...state, deleteAnAdminError: action.payload };
    case EDIT_AN_ADMIN_LOADING:
      return { ...state, editAnAdminLoading: action.payload };
    case EDIT_AN_ADMIN_SUCCESS:
      return { ...state, editAnAdmin: action.payload };
    case EDIT_AN_ADMIN_ERROR:
      return { ...state, editAnAdminError: action.payload };
    case GRANT_REVOKE_ADMIN_ACCESS_LOADING:
      return { ...state, grantRevokeAdminAccessLoading: action.payload };
    case GRANT_REVOKE_ADMIN_ACCESS_SUCCESS:
      return { ...state, grantRevokeAdminAccess: action.payload };
    case GRANT_REVOKE_ADMIN_ACCESS_ERROR:
      return { ...state, grantRevokeAdminAccessError: action.payload };
    default:
      return state;
  }
}
