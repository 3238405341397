import axios from "axios";
import {
  ALL_CAREER_LOADING,
  ALL_CAREER_SUCCESS,
  A_CAREER_LOADING,
  A_CAREER_SUCCESS,
  CREATE_CAREER_LOADING,
  DELETE_CAREER_LOADING,
  UPDATE_CAREER_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllCareer =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_CAREER_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/career/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: ALL_CAREER_LOADING, payload: null });
      dispatch({ type: ALL_CAREER_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: ALL_CAREER_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const deleteACareer = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CAREER_LOADING, payload: true });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/career/delete-career/${id}`,
      headers()
    );
    dispatch({ type: DELETE_CAREER_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllCareer());
    toast.success("Career deleted successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_CAREER_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const createCareer = (data) => async (dispatch) => {
  dispatch({ type: CREATE_CAREER_LOADING, payload: true });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/career/add-career`,
      data,
      headers()
    );
    dispatch({ type: CREATE_CAREER_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllCareer());
    toast.success("Career created successfully", { toastId: "Career success" });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_CAREER_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${
          err.response?.data?.errors[0]?.title ||
          err.response?.data?.errors[0]?.careerImage ||
          err.response?.data?.errors[0]?.content
        }`
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const editCareer = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_CAREER_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/career/edit-career/${id}`,
      data,
      headers()
    );
    dispatch({ type: UPDATE_CAREER_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllCareer());
    toast.success("Career updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: UPDATE_CAREER_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getACareer = (id) => async (dispatch) => {
  dispatch({ type: A_CAREER_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/career/get-career/${id}`,
      headers()
    );
    dispatch({ type: A_CAREER_LOADING, payload: null });
    dispatch({ type: A_CAREER_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_CAREER_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
