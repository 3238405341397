import { Search } from "@styled-icons/fa-solid";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import styled from "styled-components";
import CustomInputIconCmp from "../InputsCmp/CustomInputIconCmp";

const SearchWrapper = styled.div`
  max-width: 300px;
`;

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchPlaceholderText,
}) {
  //   const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <SearchWrapper>
        <CustomInputIconCmp
          background="#fff"
          required={true}
          icon={
            <>
              <Search size={20} color="rgba(0,0,0,0.5)" />
            </>
          }
          type="text"
          name="search"
          placeholder={searchPlaceholderText || "Search"}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </SearchWrapper>
    </>
  );
}

// columns={[
//   {
//     Header: "User Info",
//     columns: [
//       {
//         Header: "Name",
//         accessor: "name",
//         maxWidth: 400,
//         minWidth: 140,
//         width: 400,
//         disableGlobalFilter: true,
//         Cell: (props) => {
//           return (
//             <p
//               style={{
//                 color: props.value === "SUCCESS" ? "green" : "red",
//               }}
//             >
//               {props.value}
//             </p>
//           );
//         },
//       },
//       {
//         Header: "Address",
//         accessor: "address",
//         maxWidth: 800,
//         minWidth: 140,
//         width: 800,
//         // // disable the filter for particular column
//         // disableFilters: true,
//       },
//     ],
//   },
//   {
//     Header: "Order Info",
//     columns: [
//       {
//         Header: "Date",
//         accessor: "date",
//         maxWidth: 400,
//         minWidth: 140,
//         width: 400,
//       },
//       {
//         Header: "Order #",
//         accessor: "order",
//         maxWidth: 400,
//         minWidth: 140,
//         width: 400,
//       },
//     ],
//   },
// ]}

// data={[
//     {
//       name: "Kim Parrish",
//       address: "4420 Valley Street, Garnerville, NY 10923",
//       date: "07/11/2020",
//       order: "87349585892118",
//       id: "1",
//     },
//     {
//       name: "Michele Castillo",
//       address: "637 Kyle Street, Fullerton, NE 68638",
//       date: "07/11/2020",
//       order: "58418278790810",
//       id: "1",
//     },
//     {
//       name: "Eric Ferris",
//       address: "906 Hart Country Lane, Toccoa, GA 30577",
//       date: "07/10/2020",
//       order: "81534454080477",
//       id: "1",
//     },
//     {
//       name: "Gloria Noble",
//       address: "2403 Edgewood Avenue, Fresno, CA 93721",
//       date: "07/09/2020",
//       order: "20452221703743",
//       id: "1",
//     },
//     {
//       name: "Darren Daniels",
//       address: "882 Hide A Way Road, Anaktuvuk Pass, AK 99721",
//       date: "07/07/2020",
//       order: "22906126785176",
//       id: "1",
//     },
//     {
//       name: "Ted McDonald",
//       address: "796 Bryan Avenue, Minneapolis, MN 55406",
//       date: "07/07/2020",
//       order: "87574505851064",
//       id: "1",
//     },
//     {
//       name: "Darren Daniels",
//       address: "882 Hide A Way Road, Anaktuvuk Pass, AK 99721",
//       date: "07/07/2020",
//       order: "22906126785176",
//       id: "1",
//     },
//     {
//       name: "Ted McDonald",
//       address: "796 Bryan Avenue, Minneapolis, MN 55406",
//       date: "07/07/2020",
//       order: "87574505851064",
//       id: "1",
//     },
//     {
//       name: "Darren Daniels",
//       address: "882 Hide A Way Road, Anaktuvuk Pass, AK 99721",
//       date: "07/07/2020",
//       order: "22906126785176",
//       id: "1",
//     },
//     {
//       name: "Ted McDonald",
//       address: "796 Bryan Avenue, Minneapolis, MN 55406",
//       date: "07/07/2020",
//       order: "87574505851064",
//       id: "1",
//     },
//     {
//       name: "Darren Daniels",
//       address: "882 Hide A Way Road, Anaktuvuk Pass, AK 99721",
//       date: "07/07/2020",
//       order: "22906126785176",
//       id: "1",
//     },
//     {
//       name: "Ted McDonald",
//       address: "796 Bryan Avenue, Minneapolis, MN 55406",
//       date: "07/07/2020",
//       order: "87574505851064",
//       id: "1",
//     },
//   ]}
