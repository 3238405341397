import { Add } from "@styled-icons/material-outlined";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllFaqs from "./AllFaqs";

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const Faqs = () => {
  const navigate = useNavigate();
  return (
    <>
      <DashboardLayout pageTitle="FAQs">
        <Wrapper>
          <ButtonWrapper>
            <SolidButton
              text={
                <>
                  <Add color="#fff" size={16} /> Create FAQs
                </>
              }
              type="submit"
              weighty="500"
              textFontSize="14px"
              onClick={() => {
                navigate("/help-center/faqs/create");
              }}
              specifyPadding="0.8rem 0.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              maxWidth="180px"
              borderRadius="2px"
              backColor="#016CBA"
              backgroundHoverColor="#016CBA"
              textHoverColor="#fff"
              margin="1rem auto 0"
            />
          </ButtonWrapper>
          <AllFaqs />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Faqs;
