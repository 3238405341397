import axios from "axios";
import {
  ALL_UPDATE_LOADING,
  ALL_UPDATE_SUCCESS,
  CREATE_UPDATE_LOADING,
  DELETE_UPDATE_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllUpdate =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_UPDATE_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/update/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: ALL_UPDATE_LOADING, payload: null });
      dispatch({ type: ALL_UPDATE_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: ALL_UPDATE_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const deleteAUpdate = (id) => async (dispatch) => {
  dispatch({ type: DELETE_UPDATE_LOADING, payload: true });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/update/delete-update/${id}`,
      headers()
    );
    dispatch({ type: DELETE_UPDATE_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllUpdate());
    toast.success("Update deleted successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_UPDATE_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const createUpdate = (data) => async (dispatch) => {
  dispatch({ type: CREATE_UPDATE_LOADING, payload: true });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/update/add-update`,
      data,
      headers()
    );
    dispatch({ type: CREATE_UPDATE_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllUpdate());
    toast.success("Update created successfully", { toastId: "Update success" });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_UPDATE_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${
          err.response?.data?.errors[0]?.title ||
          err.response?.data?.errors[0]?.content
        }`
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
