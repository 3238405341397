/* eslint-disable no-useless-escape */
import * as yup from "yup";

const createFaqSchema = yup.object().shape({
  question: yup.string().required("Please add question"),
  answer: yup.string().required("Please add an answer"),
  showOnHome: yup.string().required("Select an option"),
  category: yup.string().required("Select an option"),
});

export default createFaqSchema;
