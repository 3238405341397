import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { loadToken } from "../../redux/token";

const PrivateRoute = ({ auth }) => {
  return auth.authenticated && loadToken() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

const mapState = (state) => ({
  auth: state.auth,
});

export default connect(mapState)(PrivateRoute);
