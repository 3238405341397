import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { Lock } from "@styled-icons/boxicons-regular";
import SolidButton from "../ButtonCmp/Button";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import resetValidatorSchema from "../../validators/resetPasswordValidator";
import CustomInputPasswordIconCmp from "../InputsCmp/CustomInputPasswordIconCmp";
import SuccessModal from "../Modals/SuccessModal";
import SuccessImg from "../../assets/success-2.svg";
import { resetPassword } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import appTheme from "../../constants/theme";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import { toast } from "react-toastify";

const Wrapper = styled(Form)`
  padding-top: 0.5rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputWrapper = styled.div``;

// const TextWrapper = styled.div`
//   padding-top: 0.5rem;
// `;

// const Text = styled.p`
//   color: ${appTheme.COLORS.grey1};
//   font-weight: ${appTheme.WEIGHT.small3};
//   font-size: 14px;
// `;

// const Span = styled.span`
//   color: #1a8fdd;
//   cursor: pointer;
// `;

const ButtonWrapper = styled.div`
  padding-top: 1.3rem;
`;

const ResetPasswordForm = ({
  loading,
  error,
  setResetPassword,
  verifyResetToken,
}) => {
  const [resetSuccess, setResetSuccess] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Formik
        validationSchema={resetValidatorSchema}
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          console.log("Submitted");
          // console.log(values);
          // resetForm();

          const response = await setResetPassword(
            {
              password: values.password,
              confirmPassword: values.confirmPassword,
            },
            verifyResetToken
          );

          if (
            response &&
            response.status === 201 &&
            response.message === "Password reset successful"
          ) {
            navigate("/login");
            toast.success(response.message, {
              toastId: "Password reset successful",
            });

            // setResetSuccess(true);
          }
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <>
            <Wrapper noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <CustomInputPasswordIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Lock size={30} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    name="password"
                    placeholder="New Password"
                    values={values}
                    onChange={handleChange}
                    message={`${errors["password"] ? errors["password"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInputPasswordIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Lock size={30} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    values={values}
                    onChange={handleChange}
                    message={`${
                      errors["confirmPassword"] ? errors["confirmPassword"] : ""
                    }`}
                    showMessage={true}
                  />
                </InputWrapper>
              </FormWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Update Password"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={loading ? true : false}
                />
              </ButtonWrapper>
              <SuccessModal
                title="Your account is now secured, your password has been successfully changed"
                titleMaxWidth="300px"
                maxWidth="400px"
                // showLogo={false}
                // wrapperPadding={"2rem 1rem 2rem"}
                // showCloseIcon={false}
                isOpen={resetSuccess}
                heading="Update Successful"
                buttonCloseText="Return to Sign in"
                successImgUrl={SuccessImg}
                onRequestClose={() => {
                  setResetSuccess(false);
                  navigate("/login");
                }}
                actionButtonWidth="160px"
              />
            </Wrapper>
          </>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatch = (dispatch) => ({
  setResetPassword: (data, token) => dispatch(resetPassword(data, token)),
});

export default connect(mapState, mapDispatch)(ResetPasswordForm);
