import {
  ALL_NEWS_ERROR,
  ALL_NEWS_LOADING,
  ALL_NEWS_SUCCESS,
  A_NEWS_ERROR,
  A_NEWS_LOADING,
  A_NEWS_SUCCESS,
  CREATE_NEWS_ERROR,
  CREATE_NEWS_LOADING,
  CREATE_NEWS_SUCCESS,
  DELETE_NEWS_ERROR,
  DELETE_NEWS_LOADING,
  EDIT_NEWS_ERROR,
  EDIT_NEWS_LOADING,
  EDIT_NEWS_SUCCESS,
} from "./types";

const initialState = {
  allNewsLoading: null,
  allNews: null,
  allNewsError: null,
  deleteNewsLoading: null,
  deleteNewsError: null,
  createNewsLoading: null,
  createNewsSuccess: null,
  createNewsError: null,
  editNewsLoading: null,
  editNewsSuccess: null,
  editNewsError: null,
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_NEWS_LOADING:
      return { ...state, allNewsLoading: action.payload };
    case ALL_NEWS_SUCCESS:
      return { ...state, allNews: action.payload };
    case ALL_NEWS_ERROR:
      return { ...state, allNewsError: action.payload };
    case A_NEWS_LOADING:
      return { ...state, aNewsLoading: action.payload };
    case A_NEWS_SUCCESS:
      return { ...state, aNews: action.payload };
    case A_NEWS_ERROR:
      return { ...state, aNewsError: action.payload };
    case DELETE_NEWS_LOADING:
      return { ...state, deleteNewsLoading: action.payload };
    case DELETE_NEWS_ERROR:
      return { ...state, deleteNewsError: action.payload };
    case CREATE_NEWS_LOADING:
      return { ...state, createNewsLoading: action.payload };
    case CREATE_NEWS_SUCCESS:
      return { ...state, createNewsSuccess: action.payload };
    case CREATE_NEWS_ERROR:
      return { ...state, createNewsError: action.payload };
    case EDIT_NEWS_LOADING:
      return { ...state, editNewsLoading: action.payload };
    case EDIT_NEWS_SUCCESS:
      return { ...state, editNewsSuccess: action.payload };
    case EDIT_NEWS_ERROR:
      return { ...state, editNewsError: action.payload };
    default:
      return state;
  }
}
