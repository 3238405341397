import React from "react";
import styled from "styled-components";
import ModalCmp from "../ModalCmp/ModalCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import appTheme from "../../constants/theme";
import RenderEditorCmp from "../InputsCmp/RenderEditorCmp";
import { convertFromRaw } from "draft-js";

const Wrapper = styled.div`
  text-align: center;
  padding: ${(props) =>
    props.wrapperPadding ? props.wrapperPadding : "2rem 1rem"};
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 22px;
  padding-bottom: 1rem;
`;

const ImagePreviewWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
`;

const Image = styled(LazyLoadImage)`
  background-size: cover;
  width: 100%;
  height: 200px;
`;

const NewsWrapper = styled.div`
  text-align: left;
`;

const ViewNewsModal = (props) => {
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: props.maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="News-Modal"
        id="news-modal"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={false}
        showCloseIcon={props.showCloseIcon}
      >
        <Wrapper wrapperPadding={props.wrapperPadding}>
          <Title>{props?.data?.title}</Title>
          {props?.data?.newsPicUrl && (
            <ImagePreviewWrapper>
              <Image src={props?.data?.newsPicUrl} alt={props?.data?.title} />
            </ImagePreviewWrapper>
          )}
          {props?.data?.content && (
            <NewsWrapper>
              <RenderEditorCmp
                contentState={convertFromRaw(JSON.parse(props?.data?.content))}
              />
            </NewsWrapper>
          )}
        </Wrapper>
      </ModalCmp>
    </>
  );
};

export default ViewNewsModal;
