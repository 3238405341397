import {
  ALL_AD_ERROR,
  ALL_AD_LOADING,
  ALL_AD_SUCCESS,
  A_AD_ERROR,
  A_AD_LOADING,
  A_AD_SUCCESS,
  CREATE_AD_ERROR,
  CREATE_AD_LOADING,
  CREATE_AD_SUCCESS,
  DELETE_AD_ERROR,
  DELETE_AD_LOADING,
  UPDATE_AD_ERROR,
  UPDATE_AD_LOADING,
  UPDATE_AD_SUCCESS,
} from "./types";

const initialState = {
  allAdLoading: null,
  allAd: null,
  allAdError: null,
  deleteAdLoading: null,
  deleteAdError: null,
  createAdLoading: null,
  createAdSuccess: null,
  createAdError: null,
  editAdLoading: null,
  editAdSuccess: null,
  editAdError: null,
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_AD_LOADING:
      return { ...state, allAdLoading: action.payload };
    case ALL_AD_SUCCESS:
      return { ...state, allAd: action.payload };
    case ALL_AD_ERROR:
      return { ...state, allAdError: action.payload };
    case A_AD_LOADING:
      return { ...state, aAdLoading: action.payload };
    case A_AD_SUCCESS:
      return { ...state, aAd: action.payload };
    case A_AD_ERROR:
      return { ...state, aAdError: action.payload };
    case DELETE_AD_LOADING:
      return { ...state, deleteAdLoading: action.payload };
    case DELETE_AD_ERROR:
      return { ...state, deleteAdError: action.payload };
    case CREATE_AD_LOADING:
      return { ...state, createAdLoading: action.payload };
    case CREATE_AD_SUCCESS:
      return { ...state, createAdSuccess: action.payload };
    case CREATE_AD_ERROR:
      return { ...state, createAdError: action.payload };
    case UPDATE_AD_LOADING:
      return { ...state, editAdLoading: action.payload };
    case UPDATE_AD_SUCCESS:
      return { ...state, editAdSuccess: action.payload };
    case UPDATE_AD_ERROR:
      return { ...state, editAdError: action.payload };
    default:
      return state;
  }
}
