export const LOADING = "LOADING";
export const AUTHENTICATED = "AUTHENTICATED";
export const LOGIN_DATA = "LOGIN_DATA";
export const ERROR = "ERROR";
// export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT = "LOGOUT";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";

// types used in DASHBOARD
export const DASHBOARD_BALANCE_LOADING = "DASHBOARD_BALANCE_LOADING";
export const DASHBOARD_BALANCE_SUCCESS = "DASBOARD_BALANCE_SUCCESS";
export const DASHBOARD_BALANCE_ERROR = "DASHBOARD_BALANCE_ERROR";

export const DASHBOARD_TXN_LIMIT_LOADING = "DASHBOARD_TXN_LIMIT_LOADING";
export const DASHBOARD_TXN_LIMIT_SUCCESS = "DASHBOARD_TXN_LIMIT_SUCCESS";
export const DASHBOARD_TXN_LIMIT_ERROR = "DASHBOARD_TXN_LIMIT_ERROR";

//types used in NEWS
export const ALL_NEWS_LOADING = "ALL_NEWS_LOADING";
export const ALL_NEWS_SUCCESS = "ALL_NEWS_SUCCESS";
export const ALL_NEWS_ERROR = "ALL_NEWS_ERROR";

export const A_NEWS_LOADING = "A_NEWS_LOADING";
export const A_NEWS_SUCCESS = "A_NEWS_SUCCESS";
export const A_NEWS_ERROR = "A_NEWS_ERROR";

export const DELETE_NEWS_LOADING = "DELETE_NEWS_LOADING";
export const DELETE_NEWS_ERROR = "DELETE_NEWS_ERROR";

export const CREATE_NEWS_LOADING = "CREATE_NEWS_LOADING";
export const CREATE_NEWS_SUCCESS = "CREATE_NEWS_SUCCESS";
export const CREATE_NEWS_ERROR = "CREATE_NEWS_ERROR";

export const EDIT_NEWS_LOADING = "EDIT_NEWS_LOADING";
export const EDIT_NEWS_SUCCESS = "EDIT_NEWS_SUCCESS";
export const EDIT_NEWS_ERROR = "EDIT_NEWS_ERROR";

// types used in NEWS AND UPDATE

export const ALL_UPDATE_LOADING = "ALL_UPDATE_LOADING";
export const ALL_UPDATE_SUCCESS = "ALL_UPDATE_SUCCESS";
export const ALL_UPDATE_ERROR = "ALL_UPDATE_ERROR";

export const DELETE_UPDATE_LOADING = "DELETE_UPDATE_LOADING";
export const DELETE_UPDATE_ERROR = "DELETE_UPDATE_ERROR";

export const CREATE_UPDATE_LOADING = "CREATE_UPDATE_LOADING";
export const CREATE_UPDATE_SUCCESS = "CREATE_UPDATE_SUCCESS";
export const CREATE_UPDATE_ERROR = "CREATE_UPDATE_ERROR";

//types used in TICKETS
export const ALL_TICKETS_ERROR = "ALL_TICKETS_ERROR";
export const ALL_TICKETS_LOADING = "ALL_TICKETS_LOADING";
export const ALL_TICKETS_SUCCESS = "ALL_TICKETS_SUCCESS";
export const A_TICKET_ERROR = "A_TICKET_ERROR";
export const A_TICKET_LOADING = "A_TICKET_LOADING";
export const A_TICKET_SUCCESS = "A_TICKET_SUCCESS";
export const VIEW_TICKET_ERROR = "VIEW_TICKET_ERROR";
export const VIEW_TICKET_LOADING = "VIEW_TICKET_LOADING";
export const VIEW_TICKET_SUCCESS = "VIEW_TICKET_SUCCESS";
export const DELETE_TICKET_ERROR = "DELETE_TICKET_ERROR";
export const DELETE_TICKET_LOADING = "DELETE_TICKET_LOADING";
export const UPDATE_TICKET_STATUS_ERROR = "UPDATE_TICKET_STATUS_ERROR";
export const UPDATE_TICKET_STATUS_LOADING = "UPDATE_TICKET_STATUS_LOADING";
export const UPDATE_TICKET_STATUS_SUCCESS = "UPDATE_TICKET_STATUS_SUCCESS";

// types used in FAQS
export const ALL_FAQ_LOADING = "ALL_FAQ_LOADING";
export const ALL_FAQ_SUCCESS = "ALL_FAQ_SUCCESS";
export const ALL_FAQ_ERROR = "ALL_FAQ_ERROR";

export const DELETE_FAQ_LOADING = "DELETE_FAQ_LOADING";
export const DELETE_FAQ_ERROR = "DELETE_FAQ_ERROR";

export const CREATE_FAQ_LOADING = "CREATE_FAQ_LOADING";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const CREATE_FAQ_ERROR = "CREATE_FAQ_ERROR";

export const UPDATE_FAQ_ERROR = "UPDATE_FAQ_ERROR";
export const UPDATE_FAQ_LOADING = "UPDATE_FAQ_LOADING";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";

export const A_FAQ_ERROR = "A_FAQ_ERROR";
export const A_FAQ_LOADING = "A_FAQ_LOADING";
export const A_FAQ_SUCCESS = "A_FAQ_SUCCESS";

// types used in ADS
export const ALL_AD_LOADING = "ALL_AD_LOADING";
export const ALL_AD_SUCCESS = "ALL_AD_SUCCESS";
export const ALL_AD_ERROR = "ALL_AD_ERROR";

export const DELETE_AD_LOADING = "DELETE_AD_LOADING";
export const DELETE_AD_ERROR = "DELETE_AD_ERROR";

export const CREATE_AD_LOADING = "CREATE_AD_LOADING";
export const CREATE_AD_SUCCESS = "CREATE_AD_SUCCESS";
export const CREATE_AD_ERROR = "CREATE_AD_ERROR";

export const UPDATE_AD_ERROR = "UPDATE_AD_ERROR";
export const UPDATE_AD_LOADING = "UPDATE_AD_LOADING";
export const UPDATE_AD_SUCCESS = "UPDATE_AD_SUCCESS";

export const A_AD_ERROR = "A_AD_ERROR";
export const A_AD_LOADING = "A_AD_LOADING";
export const A_AD_SUCCESS = "A_AD_SUCCESS";

// types used in CAREER
export const ALL_CAREER_LOADING = "ALL_CAREER_LOADING";
export const ALL_CAREER_SUCCESS = "ALL_CAREER_SUCCESS";
export const ALL_CAREER_ERROR = "ALL_CAREER_ERROR";

export const DELETE_CAREER_LOADING = "DELETE_CAREER_LOADING";
export const DELETE_CAREER_ERROR = "DELETE_CAREER_ERROR";

export const CREATE_CAREER_LOADING = "CREATE_CAREER_LOADING";
export const CREATE_CAREER_SUCCESS = "CREATE_CAREER_SUCCESS";
export const CREATE_CAREER_ERROR = "CREATE_CAREER_ERROR";

export const UPDATE_CAREER_ERROR = "UPDATE_CAREER_ERROR";
export const UPDATE_CAREER_LOADING = "UPDATE_CAREER_LOADING";
export const UPDATE_CAREER_SUCCESS = "UPDATE_CAREER_SUCCESS";

export const A_CAREER_ERROR = "A_CAREER_ERROR";
export const A_CAREER_LOADING = "A_CAREER_LOADING";
export const A_CAREER_SUCCESS = "A_CAREER_SUCCESS";

//types used in OTC

export const ALL_OTC_LOADING = "ALL_OTC_LOADING";
export const ALL_OTC_SUCCESS = "ALL_OTC_SUCCESS";
export const ALL_OTC_ERROR = "ALL_OTC_ERROR";

export const DELETE_OTC_LOADING = "DELETE_OTC_LOADING";
export const DELETE_OTC_ERROR = "DELETE_OTC_ERROR";

export const UPDATE_OTC_ERROR = "UPDATE_OTC_ERROR";
export const UPDATE_OTC_LOADING = "UPDATE_OTC_LOADING";
export const UPDATE_OTC_SUCCESS = "UPDATE_OTC_SUCCESS";

export const A_OTC_ERROR = "A_OTC_ERROR";
export const A_OTC_LOADING = "A_OTC_LOADING";
export const A_OTC_SUCCESS = "A_OTC_SUCCESS";

//types used in DASHBOARD STAT

export const ALL_DASHBOARD_STAT_LOADING = "ALL_DASHBOARD_STAT_LOADING";
export const ALL_DASHBOARD_STAT_SUCCESS = "ALL_DASHBOARD_STAT_SUCCESS";
export const ALL_DASHBOARD_STAT_ERROR = "ALL_DASHBOARD_STAT_ERROR";

//types used in NAIRA WALLET
export const ALL_NAIRA_WALLET_ERROR = "ALL_NAIRA_WALLET_ERROR";
export const ALL_NAIRA_WALLET_LOADING = "ALL_NAIRA_WALLET_LOADING";
export const ALL_NAIRA_WALLET_SUCCESS = "ALL_NAIRA_WALLET_SUCCESS";
export const A_NAIRA_WALLET_ERROR = "A_NAIRA_WALLET_ERROR";
export const A_NAIRA_WALLET_LOADING = "A_NAIRA_WALLET_LOADING";
export const A_NAIRA_WALLET_SUCCESS = "A_NAIRA_WALLET_SUCCESS";
export const UPDATE_NAIRA_WALLET_ERROR = "UPDATE_NAIRA_WALLET_ERROR";
export const UPDATE_NAIRA_WALLET_LOADING = "UPDATE_NAIRA_WALLET_LOADING";
export const UPDATE_NAIRA_WALLET_SUCCESS = "UPDATE_NAIRA_WALLET_SUCCESS";
export const UPDATE_NAIRA_WALLET_SETTINGS_ERROR =
  "UPDATE_NAIRA_WALLET_SETTINGS_ERROR";
export const UPDATE_NAIRA_WALLET_SETTINGS_LOADING =
  "UPDATE_NAIRA_WALLET_SETTINGS_LOADING";
export const UPDATE_NAIRA_WALLET_SETTINGS_SUCCESS =
  "UPDATE_NAIRA_WALLET_SETTINGS_SUCCESS";

export const GET_NAIRA_WALLET_SETTINGS_ERROR =
  "GET_NAIRA_WALLET_SETTINGS_ERROR";
export const GET_NAIRA_WALLET_SETTINGS_LOADING =
  "GET_NAIRA_WALLET_SETTINGS_LOADING";
export const GET_NAIRA_WALLET_SETTINGS_SUCCESS =
  "GET_NAIRA_WALLET_SETTINGS_SUCCESS";

export const BANK_LIST_ERROR = "BANK_LIST_ERROR";
export const BANK_LIST_LOADING = "BANK_LIST_LOADING";
export const BANK_LIST_SUCCESS = "BANK_LIST_SUCCESS";

//types used in USER MANAGEMENT
export const ALL_USER_ERROR = "ALL_USER_ERROR";
export const ALL_USER_LOADING = "ALL_USER_LOADING";
export const ALL_USER_SUCCESS = "ALL_USER_SUCCESS";

export const A_USER_ERROR = "A_USER_ERROR";
export const A_USER_LOADING = "A_USER_LOADING";
export const A_USER_SUCCESS = "A_USER_SUCCESS";

export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR";
export const CHANGE_USER_STATUS_LOADING = "CHANGE_USER_STATUS_LOADING";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";

export const RESET_USER_2FA_ERROR = "RESET_USER_2FA_ERROR";
export const RESET_USER_2FA_LOADING = "RESET_USER_2FA_LOADING";
export const RESET_USER_2FA_SUCCESS = "RESET_USER_2FA_SUCCESS";

export const DEPOSIT_INTO_USER_WALLET_ERROR = "DEPOSIT_INTO_USER_WALLET_ERROR";
export const DEPOSIT_INTO_USER_WALLET_LOADING =
  "DEPOSIT_INTO_USER_WALLET_LOADING";
export const DEPOSIT_INTO_USER_WALLET_SUCCESS =
  "DEPOSIT_INTO_USER_WALLET_SUCCESS";

export const WITHDRAW_FROM_USER_ERROR = "WITHDRAW_FROM_USER_ERROR";
export const WITHDRAW_FROM_USER_LOADING = "WITHDRAW_FROM_USER_LOADING";
export const WITHDRAW_FROM_USER_SUCCESS = "WITHDRAW_FROM_USER_SUCCESS";

//types used in TRANSACTION
export const GET_ALL_TRANSACTIONS_ERROR = "GET_ALL_TRANSACTIONS_ERROR";
export const GET_ALL_TRANSACTIONS_LOADING = "GET_ALL_TRANSACTIONS_LOADING";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";

export const GET_ALL_TRANSACTIONS_WITH_STATUS_ERROR =
  "GET_ALL_TRANSACTIONS_WITH_STATUS_ERROR";
export const GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING =
  "GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING";
export const GET_ALL_TRANSACTIONS_WITH_STATUS_SUCCESS =
  "GET_ALL_TRANSACTIONS_WITH_STATUS_SUCCESS";

//types used in PERFECT MONEY SETTINGS
export const ALL_PM_SETTINGS_ERROR = "ALL_PM_SETTINGS_ERROR";
export const ALL_PM_SETTINGS_LOADING = "ALL_PM_SETTINGS_LOADING";
export const ALL_PM_SETTINGS_SUCCESS = "ALL_PM_SETTINGS_SUCCESS";

export const A_PM_SETTING_ERROR = "A_PM_SETTING_ERROR";
export const A_PM_SETTING_LOADING = "A_PM_SETTING_LOADING";
export const A_PM_SETTING_SUCCESS = "A_PM_SETTING_SUCCESS";

export const EDIT_A_PM_SETTING_ERROR = "EDIT_A_PM_SETTING_ERROR";
export const EDIT_A_PM_SETTING_LOADING = "EDIT_A_PM_SETTING_LOADING";
export const EDIT_A_PM_SETTING_SUCCESS = "EDIT_A_PM_SETTING_SUCCESS";

//types used in CRYPTO SETTINGS
export const ALL_CRYPTO_SETTINGS_ERROR = "ALL_CRYPTO_SETTINGS_ERROR";
export const ALL_CRYPTO_SETTINGS_LOADING = "ALL_CRYPTO_SETTINGS_LOADING";
export const ALL_CRYPTO_SETTINGS_SUCCESS = "ALL_CRYPTO_SETTINGS_SUCCESS";

export const A_CRYPTO_SETTING_ERROR = "A_CRYPTO_SETTING_ERROR";
export const A_CRYPTO_SETTING_LOADING = "A_CRYPTO_SETTING_LOADING";
export const A_CRYPTO_SETTING_SUCCESS = "A_CRYPTO_SETTING_SUCCESS";

export const EDIT_A_CRYPTO_SETTING_ERROR = "EDIT_A_CRYPTO_SETTING_ERROR";
export const EDIT_A_CRYPTO_SETTING_LOADING = "EDIT_A_CRYPTO_SETTING_LOADING";
export const EDIT_A_CRYPTO_SETTING_SUCCESS = "EDIT_A_CRYPTO_SETTING_SUCCESS";

export const GET_ADMIN_CRYPTO_INFO_ERROR = "GET_ADMIN_CRYPTO_INFO_ERROR";
export const GET_ADMIN_CRYPTO_INFO_LOADING = "GET_ADMIN_CRYPTO_INFO_LOADING";
export const GET_ADMIN_CRYPTO_INFO_SUCCESS = "GET_ADMIN_CRYPTO_INFO_SUCCESS";

export const GET_ALL_GIFTCARD_BUY_ERROR = "GET_ALL_GIFTCARD_BUY_ERROR";
export const GET_ALL_GIFTCARD_BUY_LOADING = "GET_ALL_GIFTCARD_BUY_LOADING";
export const GET_ALL_GIFTCARD_BUY_SUCCESS = "GET_ALL_GIFTCARD_BUY_SUCCESS";

export const GET_A_GIFTCARD_BUY_ERROR = "GET_A_GIFTCARD_BUY_ERROR";
export const GET_A_GIFTCARD_BUY_LOADING = "GET_A_GIFTCARD_BUY_LOADING";
export const GET_A_GIFTCARD_BUY_SUCCESS = "GET_A_GIFTCARD_BUY_SUCCESS";

export const EDIT_A_GIFTCARD_BUY_ERROR = "EDIT_A_GIFTCARD_BUY_ERROR";
export const EDIT_A_GIFTCARD_BUY_LOADING = "EDIT_A_GIFTCARD_BUY_LOADING";
export const EDIT_A_GIFTCARD_BUY_SUCCESS = "EDIT_A_GIFTCARD_BUY_SUCCESS";

export const HIDE_A_GIFTCARD_BUY_ERROR = "HIDE_A_GIFTCARD_BUY_ERROR";
export const HIDE_A_GIFTCARD_BUY_LOADING = "HIDE_A_GIFTCARD_BUY_LOADING";
export const HIDE_A_GIFTCARD_BUY_SUCCESS = "HIDE_A_GIFTCARD_BUY_SUCCESS";

export const GET_ALL_GIFTCARD_SELL_ERROR = "GET_ALL_GIFTCARD_SELL_ERROR";
export const GET_ALL_GIFTCARD_SELL_LOADING = "GET_ALL_GIFTCARD_SELL_LOADING";
export const GET_ALL_GIFTCARD_SELL_SUCCESS = "GET_ALL_GIFTCARD_SELL_SUCCESS";

export const GET_A_GIFTCARD_SELL_ERROR = "GET_A_GIFTCARD_SELL_ERROR";
export const GET_A_GIFTCARD_SELL_LOADING = "GET_A_GIFTCARD_SELL_LOADING";
export const GET_A_GIFTCARD_SELL_SUCCESS = "GET_A_GIFTCARD_SELL_SUCCESS";

export const EDIT_A_GIFTCARD_SELL_ERROR = "EDIT_A_GIFTCARD_SELL_ERROR";
export const EDIT_A_GIFTCARD_SELL_LOADING = "EDIT_A_GIFTCARD_SELL_LOADING";
export const EDIT_A_GIFTCARD_SELL_SUCCESS = "EDIT_A_GIFTCARD_SELL_SUCCESS";

export const HIDE_A_GIFTCARD_SELL_ERROR = "HIDE_A_GIFTCARD_SELL_ERROR";
export const HIDE_A_GIFTCARD_SELL_LOADING = "HIDE_A_GIFTCARD_SELL_LOADING";
export const HIDE_A_GIFTCARD_SELL_SUCCESS = "HIDE_A_GIFTCARD_SELL_SUCCESS";

export const GET_ALL_PENDING_GIFTCARD_SELL_TXN_ERROR =
  "GET_ALL_PENDING_GIFTCARD_SELL_TXN_ERROR";
export const GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING =
  "GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING";
export const GET_ALL_PENDING_GIFTCARD_SELL_TXN_SUCCESS =
  "GET_ALL_PENDING_GIFTCARD_SELL_TXN_SUCCESS";

export const GET_A_PENDING_GIFTCARD_SELL_TXN_ERROR =
  "GET_A_PENDING_GIFTCARD_SELL_TXN_ERROR";
export const GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING =
  "GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING";
export const GET_A_PENDING_GIFTCARD_SELL_TXN_SUCCESS =
  "GET_A_PENDING_GIFTCARD_SELL_TXN_SUCCESS";

export const A_PENDING_GIFTCARD_SELL_ACTION_ERROR =
  "A_PENDING_GIFTCARD_SELL_ACTION_ERROR";
export const A_PENDING_GIFTCARD_SELL_ACTION_LOADING =
  "A_PENDING_GIFTCARD_SELL_ACTION_LOADING";
export const A_PENDING_GIFTCARD_SELL_ACTION_SUCCESS =
  "A_PENDING_GIFTCARD_SELL_ACTION_SUCCESS";

export const CREATE_AN_ADMIN_ERROR = "CREATE_AN_ADMIN_ERROR";
export const CREATE_AN_ADMIN_LOADING = "CREATE_AN_ADMIN_LOADING";
export const CREATE_AN_ADMIN_SUCCESS = "CREATE_AN_ADMIN_SUCCESS";

export const GET_ALL_ADMINS_ERROR = "GET_ALL_ADMINS_ERROR";
export const GET_ALL_ADMINS_LOADING = "GET_ALL_ADMINS_LOADING";
export const GET_ALL_ADMINS_SUCCESS = "GET_ALL_ADMINS_SUCCESS";

export const GET_AN_ADMIN_ERROR = "GET_AN_ADMIN_ERROR";
export const GET_AN_ADMIN_LOADING = "GET_AN_ADMIN_LOADING";
export const GET_AN_ADMIN_SUCCESS = "GET_AN_ADMIN_SUCCESS";

export const DELETE_AN_ADMIN_ERROR = "DELETE_AN_ADMIN_ERROR";
export const DELETE_AN_ADMIN_LOADING = "DELETE_AN_ADMIN_LOADING";
export const DELETE_AN_ADMIN_SUCCESS = "DELETE_AN_ADMIN_SUCCESS";

export const EDIT_AN_ADMIN_ERROR = "EDIT_AN_ADMIN_ERROR";
export const EDIT_AN_ADMIN_LOADING = "EDIT_AN_ADMIN_LOADING";
export const EDIT_AN_ADMIN_SUCCESS = "EDIT_AN_ADMIN_SUCCESS";

export const GRANT_REVOKE_ADMIN_ACCESS_ERROR =
  "GRANT_REVOKE_ADMIN_ACCESS_ERROR";
export const GRANT_REVOKE_ADMIN_ACCESS_LOADING =
  "GRANT_REVOKE_ADMIN_ACCESS_LOADING";
export const GRANT_REVOKE_ADMIN_ACCESS_SUCCESS =
  "GRANT_REVOKE_ADMIN_ACCESS_SUCCESS";
