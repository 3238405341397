import axios from "axios";
import { apiBaseUrl } from "../../constants/url";
import {
  ALL_USER_LOADING,
  ALL_USER_SUCCESS,
  A_USER_LOADING,
  A_USER_SUCCESS,
  CHANGE_USER_STATUS_LOADING,
  CHANGE_USER_STATUS_SUCCESS,
  DEPOSIT_INTO_USER_WALLET_LOADING,
  DEPOSIT_INTO_USER_WALLET_SUCCESS,
  RESET_USER_2FA_LOADING,
  RESET_USER_2FA_SUCCESS,
  WITHDRAW_FROM_USER_LOADING,
  WITHDRAW_FROM_USER_SUCCESS,
} from "../reducers/types";
import headers from "./header";
import { toast } from "react-toastify";

export const getAllUsers =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_USER_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/user/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: ALL_USER_LOADING, payload: null });
      dispatch({ type: ALL_USER_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: ALL_USER_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const getAUser = (id, page) => async (dispatch) => {
  dispatch({ type: A_USER_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/user/${id}?page=${page}&limit=50`,
      headers()
    );
    dispatch({ type: A_USER_LOADING, payload: null });
    dispatch({ type: A_USER_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_USER_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const resetAUser2fa = (id) => async (dispatch) => {
  dispatch({ type: RESET_USER_2FA_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/user/reset/2fa/${id}`,
      headers()
    );
    dispatch({ type: RESET_USER_2FA_LOADING, payload: null });
    dispatch({ type: RESET_USER_2FA_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: RESET_USER_2FA_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const changeUserStatus = (id, data) => async (dispatch) => {
  dispatch({ type: CHANGE_USER_STATUS_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/user/${id}/change-status`,
      data,
      headers()
    );
    dispatch({ type: CHANGE_USER_STATUS_LOADING, payload: null });
    dispatch({ type: CHANGE_USER_STATUS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: CHANGE_USER_STATUS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const depositIntoUserWallet = (id, data) => async (dispatch) => {
  dispatch({ type: DEPOSIT_INTO_USER_WALLET_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/user/${id}/deposit/wallet`,
      data,
      headers()
    );
    dispatch({ type: DEPOSIT_INTO_USER_WALLET_LOADING, payload: null });
    dispatch({
      type: DEPOSIT_INTO_USER_WALLET_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: DEPOSIT_INTO_USER_WALLET_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const withdrawFromUserWallet = (id, data) => async (dispatch) => {
  dispatch({ type: WITHDRAW_FROM_USER_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/user/${id}/withdraw/wallet`,
      data,
      headers()
    );
    dispatch({ type: WITHDRAW_FROM_USER_LOADING, payload: null });
    dispatch({
      type: WITHDRAW_FROM_USER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: WITHDRAW_FROM_USER_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
