import {
  ALL_OTC_ERROR,
  ALL_OTC_LOADING,
  ALL_OTC_SUCCESS,
  A_OTC_ERROR,
  A_OTC_LOADING,
  A_OTC_SUCCESS,
  DELETE_OTC_ERROR,
  DELETE_OTC_LOADING,
  UPDATE_OTC_ERROR,
  UPDATE_OTC_LOADING,
  UPDATE_OTC_SUCCESS,
} from "./types";

const initialState = {
  allOtcLootcing: null,
  allOtc: null,
  allOtcError: null,
  deleteOtcLootcing: null,
  deleteOtcError: null,
  createOtcLootcing: null,
  createOtcSuccess: null,
  createOtcError: null,
  editOtcLootcing: null,
  editOtcSuccess: null,
  editOtcError: null,
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_OTC_LOADING:
      return { ...state, allOtcLootcing: action.payload };
    case ALL_OTC_SUCCESS:
      return { ...state, allOtc: action.payload };
    case ALL_OTC_ERROR:
      return { ...state, allOtcError: action.payload };
    case A_OTC_LOADING:
      return { ...state, aOtcLootcing: action.payload };
    case A_OTC_SUCCESS:
      return { ...state, aOtc: action.payload };
    case A_OTC_ERROR:
      return { ...state, aOtcError: action.payload };
    case DELETE_OTC_LOADING:
      return { ...state, deleteOtcLoading: action.payload };
    case DELETE_OTC_ERROR:
      return { ...state, deleteOtcError: action.payload };
    case UPDATE_OTC_LOADING:
      return { ...state, editOtcLoading: action.payload };
    case UPDATE_OTC_SUCCESS:
      return { ...state, editOtcSuccess: action.payload };
    case UPDATE_OTC_ERROR:
      return { ...state, editOtcError: action.payload };
    default:
      return state;
  }
}
