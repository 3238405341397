/* eslint-disable no-useless-escape */
import * as yup from "yup";
import "yup-phone";

const editAdminSchema = yup.object().shape({
  email: yup
    .string()
    .required("Provide an email address ")
    .email("Provide a valid email address"),
  fullName: yup.string().required("Please provide full name"),
  password: yup.string().required("Please provide a password").optional(),
  role: yup.string().required("Please select role"),
  phone: yup
    .string()
    .test(
      "Check if it include country code",
      "Pls remove country code",
      async function (value) {
        if (value && value.includes("+")) {
          return false;
        }
        return true;
      }
    )
    .phone(undefined, undefined, "Invalid phone number")
    .required(),
});

export default editAdminSchema;
