import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: rgba(248, 249, 251, 0.8);
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const DisableSiteCmp = () => {
  return (
    <>
      <Wrapper></Wrapper>
    </>
  );
};

export default DisableSiteCmp;
