import { Pencil, Trash } from "@styled-icons/boxicons-regular";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import ActionModal from "../../components/Modals/ActionModal";
import TableCmp from "../../components/TableCmp/TableCmp";
import { convertToLocalDateTime } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { deleteACareer, getAllCareer } from "../../redux/actions/careerActions";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const WrapperImageText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllCareerCmp = ({ setGetAllCareer, setDeleteACareer }) => {
  const [careerData, setCareerData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAllCareer = async () => {
      setLoadingState(true);
      const response = await setGetAllCareer(currentPage);

      setCareerData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllCareer();
    } else {
      getAllCareer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const [showActionModal, setShowActionModal] = useState(false);
  const [item, setItem] = useState();

  const navigate = useNavigate();

  const handleDeleteAction = () => {
    setDeleteACareer(item._id);
    setRefresh(true);
  };

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : careerData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...careerData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "Job title",
                accessor: "jobTitle",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <WrapperImageText>
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#3E4954",
                        }}
                      >
                        {props.value}
                      </p>
                    </WrapperImageText>
                  );
                },
              },
              {
                Header: "Job type",
                accessor: "jobType",
                maxWidth: 300,
                minWidth: 100,
                width: 120,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Category",
                accessor: "category",
                maxWidth: 300,
                minWidth: 100,
                width: 150,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Location",
                accessor: "location",
                maxWidth: 300,
                minWidth: 100,
                width: 160,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Date Created",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 220,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {convertToLocalDateTime(props.value)}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        <SolidButton
                          text={
                            <>
                              <Pencil color="#2a9bf2" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#2a9bf2"
                          borderHoverColor="#2a9bf2"
                          onClick={() => {
                            navigate(`/careers/edit/${row.values._id}`);
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#2a9bf2"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#2a9bf2"
                        />
                        <SolidButton
                          text={
                            <>
                              <Trash color="#d60000" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#D60000"
                          borderHoverColor="#D60000"
                          onClick={() => {
                            setShowActionModal(true);
                            setItem(row.values);
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#D60000"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#D60000"
                        />
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No career added yet"
            desc="Please add career to populate this area"
            btnText={"Create new job"}
            link="/careers/create"
          />
        )}
      </Wrapper>
      <ActionModal
        maxWidth="450px"
        wrapperPadding={"1rem 1rem 2rem"}
        showCloseIcon={true}
        isOpen={showActionModal}
        onRequestClose={() => {
          setShowActionModal(false);
        }}
        runAction={handleDeleteAction}
        actionOnReject={() => {
          setItem();
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.career.allCareerLoading,
  error: state.career.allCareerError,
  career: state.career.allCareer,
});

const mapDispatch = (dispatch) => ({
  setGetAllCareer: (page) => dispatch(getAllCareer(page)),
  setDeleteACareer: (id) => dispatch(deleteACareer(id)),
});

export default connect(mapState, mapDispatch)(AllCareerCmp);
