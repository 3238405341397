import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllUser from "./AllUser";

const Wrapper = styled.div``;

const User = () => {
  return (
    <>
      <DashboardLayout pageTitle="Users">
        <Wrapper>
          <AllUser />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default User;
