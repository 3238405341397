import axios from "axios";
import {
  GET_ALL_GIFTCARD_BUY_LOADING,
  GET_ALL_GIFTCARD_BUY_SUCCESS,
  GET_A_GIFTCARD_BUY_LOADING,
  GET_A_GIFTCARD_BUY_SUCCESS,
  EDIT_A_GIFTCARD_BUY_LOADING,
  HIDE_A_GIFTCARD_BUY_LOADING,
  EDIT_A_GIFTCARD_SELL_LOADING,
  GET_ALL_GIFTCARD_SELL_LOADING,
  GET_ALL_GIFTCARD_SELL_SUCCESS,
  HIDE_A_GIFTCARD_SELL_LOADING,
  GET_A_GIFTCARD_SELL_LOADING,
  GET_A_GIFTCARD_SELL_SUCCESS,
  GET_ALL_PENDING_GIFTCARD_SELL_TXN_SUCCESS,
  GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING,
  GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING,
  GET_A_PENDING_GIFTCARD_SELL_TXN_SUCCESS,
  A_PENDING_GIFTCARD_SELL_ACTION_LOADING,
  A_PENDING_GIFTCARD_SELL_ACTION_ERROR,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllGiftcardBuy = () => async (dispatch) => {
  dispatch({ type: GET_ALL_GIFTCARD_BUY_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/buy/all`,
      headers()
    );
    dispatch({ type: GET_ALL_GIFTCARD_BUY_LOADING, payload: null });
    dispatch({ type: GET_ALL_GIFTCARD_BUY_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_ALL_GIFTCARD_BUY_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const editAGiftcardBuy = (data, id) => async (dispatch) => {
  dispatch({ type: EDIT_A_GIFTCARD_BUY_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/giftcard/buy/rate/edit/${id}`,
      data,
      headers()
    );
    dispatch({ type: EDIT_A_GIFTCARD_BUY_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllGiftcardBuy());
    toast.success("Giftcard updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: EDIT_A_GIFTCARD_BUY_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const hideAGiftcardBuy = (data, id) => async (dispatch) => {
  dispatch({ type: HIDE_A_GIFTCARD_BUY_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/giftcard/buy/hide/${id}`,
      data,
      headers()
    );
    dispatch({ type: HIDE_A_GIFTCARD_BUY_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAllGiftcardBuy());
    toast.success("Giftcard updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: HIDE_A_GIFTCARD_BUY_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getAGiftcardBuy = (id) => async (dispatch) => {
  dispatch({ type: GET_A_GIFTCARD_BUY_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/buy/single/${id}`,
      headers()
    );
    dispatch({ type: GET_A_GIFTCARD_BUY_LOADING, payload: null });
    dispatch({ type: GET_A_GIFTCARD_BUY_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_A_GIFTCARD_BUY_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getAllGiftcardSell = (page) => async (dispatch) => {
  dispatch({ type: GET_ALL_GIFTCARD_SELL_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/sell/all?page=${page}&limit=50`,
      headers()
    );
    dispatch({ type: GET_ALL_GIFTCARD_SELL_LOADING, payload: null });
    dispatch({ type: GET_ALL_GIFTCARD_SELL_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_ALL_GIFTCARD_SELL_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const editAGiftcardSell = (data, productId) => async (dispatch) => {
  dispatch({ type: EDIT_A_GIFTCARD_SELL_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/giftcard/sell/rate/edit/${productId}`,
      data,
      headers()
    );
    dispatch({ type: EDIT_A_GIFTCARD_SELL_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllGiftcardSell());
    toast.success("Giftcard updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: EDIT_A_GIFTCARD_SELL_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const hideAGiftcardSell = (data, id) => async (dispatch) => {
  dispatch({ type: HIDE_A_GIFTCARD_SELL_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/giftcard/sell/hide/${id}`,
      data,
      headers()
    );
    dispatch({ type: HIDE_A_GIFTCARD_SELL_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAllGiftcardSell());
    toast.success("Giftcard updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: HIDE_A_GIFTCARD_SELL_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getAGiftcardSell = (productId) => async (dispatch) => {
  dispatch({ type: GET_A_GIFTCARD_SELL_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/sell/single/${productId}`,
      headers()
    );
    dispatch({ type: GET_A_GIFTCARD_SELL_LOADING, payload: null });
    dispatch({ type: GET_A_GIFTCARD_SELL_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_A_GIFTCARD_SELL_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getAllPendingTransactions = (page) => async (dispatch) => {
  dispatch({ type: GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/sell/get-pending-transactions?page=${page}&limit=50`,
      headers()
    );
    dispatch({
      type: GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING,
      payload: null,
    });
    dispatch({
      type: GET_ALL_PENDING_GIFTCARD_SELL_TXN_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING,
      payload: null,
    });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getAPendingTransaction = (id) => async (dispatch) => {
  dispatch({ type: GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/sell/get-single-pending-transaction/${id}`,
      headers()
    );
    dispatch({ type: GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING, payload: null });
    dispatch({
      type: GET_A_PENDING_GIFTCARD_SELL_TXN_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const aGiftcardSellAction = (data, id) => async (dispatch) => {
  dispatch({ type: A_PENDING_GIFTCARD_SELL_ACTION_LOADING, payload: true });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/giftcard/sell/pending-transaction/action/${id}`,
      data,
      headers()
    );
    dispatch({ type: A_PENDING_GIFTCARD_SELL_ACTION_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAllPendingTransactions());
    toast.success("Giftcard updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: A_PENDING_GIFTCARD_SELL_ACTION_ERROR, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
