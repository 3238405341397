import axios from "axios";
import {
  ALL_NAIRA_WALLET_LOADING,
  ALL_NAIRA_WALLET_SUCCESS,
  A_NAIRA_WALLET_LOADING,
  A_NAIRA_WALLET_SUCCESS,
  BANK_LIST_ERROR,
  BANK_LIST_LOADING,
  BANK_LIST_SUCCESS,
  GET_NAIRA_WALLET_SETTINGS_LOADING,
  GET_NAIRA_WALLET_SETTINGS_SUCCESS,
  UPDATE_NAIRA_WALLET_LOADING,
  UPDATE_NAIRA_WALLET_SETTINGS_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllWalletTxns = (type, page) => async (dispatch) => {
  dispatch({ type: ALL_NAIRA_WALLET_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/wallet/all?type=${type}&page=${page}&limit=50`,
      headers()
    );
    dispatch({ type: ALL_NAIRA_WALLET_LOADING, payload: null });
    dispatch({ type: ALL_NAIRA_WALLET_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ALL_NAIRA_WALLET_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getATicket = (id) => async (dispatch) => {
  dispatch({ type: A_NAIRA_WALLET_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/ticket/get-ticket/${id}`,
      headers()
    );
    dispatch({ type: A_NAIRA_WALLET_LOADING, payload: null });
    dispatch({ type: A_NAIRA_WALLET_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_NAIRA_WALLET_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const updateWalletTxnStatus = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_NAIRA_WALLET_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/wallet/txn/status/${id}`,
      data,
      headers()
    );
    dispatch({ type: UPDATE_NAIRA_WALLET_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllWalletTxns());
    toast.success("Wallet transaction updated successfully", {
      toastId: "Success",
    });
    return response.data;
  } catch (err) {
    dispatch({ type: UPDATE_NAIRA_WALLET_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getWalletSettings = () => async (dispatch) => {
  dispatch({ type: GET_NAIRA_WALLET_SETTINGS_LOADING, payload: true });
  try {
    const response = await axios.get(`${apiBaseUrl}/setting/get`, headers());
    dispatch({ type: GET_NAIRA_WALLET_SETTINGS_LOADING, payload: null });
    dispatch({
      type: GET_NAIRA_WALLET_SETTINGS_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_NAIRA_WALLET_SETTINGS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const updateWalletSettings = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_NAIRA_WALLET_SETTINGS_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/setting/edit`,
      data,
      headers()
    );
    dispatch({ type: UPDATE_NAIRA_WALLET_SETTINGS_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    toast.success("Wallet settings updated successfully", {
      toastId: "Success",
    });
    return response.data;
  } catch (err) {
    dispatch({ type: UPDATE_NAIRA_WALLET_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getListOfBanks = () => async (dispatch) => {
  dispatch({ type: BANK_LIST_LOADING, payload: true });
  dispatch({ type: BANK_LIST_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/setting/banks/list`,
      headers()
    );
    dispatch({ type: BANK_LIST_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message, {
        toastId: "Error",
      });
    }
    dispatch({ type: BANK_LIST_SUCCESS, payload: response.data.data });
    return;
  } catch (err) {
    dispatch({ type: BANK_LIST_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.data}`, {
        toastId: "Validation error",
      });
    } else {
      return toast.error(`There is an error`, { toastId: "There is an error" });
    }
  }
};
