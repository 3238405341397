import axios from "axios";
import {
  ALL_DASHBOARD_STAT_LOADING,
  ALL_DASHBOARD_STAT_SUCCESS,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllDashboardStat = () => async (dispatch) => {
  dispatch({ type: ALL_DASHBOARD_STAT_LOADING, payload: true });
  try {
    const response = await axios.get(`${apiBaseUrl}/stat/get`, headers());
    dispatch({ type: ALL_DASHBOARD_STAT_LOADING, payload: null });
    dispatch({ type: ALL_DASHBOARD_STAT_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ALL_DASHBOARD_STAT_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
