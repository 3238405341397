import {
  ALL_FAQ_ERROR,
  ALL_FAQ_LOADING,
  ALL_FAQ_SUCCESS,
  A_FAQ_ERROR,
  A_FAQ_LOADING,
  A_FAQ_SUCCESS,
  CREATE_FAQ_ERROR,
  CREATE_FAQ_LOADING,
  CREATE_FAQ_SUCCESS,
  DELETE_FAQ_ERROR,
  DELETE_FAQ_LOADING,
  UPDATE_FAQ_ERROR,
  UPDATE_FAQ_LOADING,
  UPDATE_FAQ_SUCCESS,
} from "./types";

const initialState = {
  allFaqLoading: null,
  allFaq: null,
  allFaqError: null,
  deleteFaqLoading: null,
  deleteFaqError: null,
  createFaqLoading: null,
  createFaqSuccess: null,
  createFaqError: null,
  updateFaqLoading: null,
  updateFaqSuccess: null,
  updateFaqError: null,
};

export default function updateReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_FAQ_LOADING:
      return { ...state, allFaqLoading: action.payload };
    case ALL_FAQ_SUCCESS:
      return { ...state, allFaq: action.payload };
    case ALL_FAQ_ERROR:
      return { ...state, allFaqError: action.payload };
    case DELETE_FAQ_LOADING:
      return { ...state, deleteFaqLoading: action.payload };
    case DELETE_FAQ_ERROR:
      return { ...state, deleteFaqError: action.payload };
    case CREATE_FAQ_LOADING:
      return { ...state, createFaqLoading: action.payload };
    case CREATE_FAQ_SUCCESS:
      return { ...state, createFaqSuccess: action.payload };
    case CREATE_FAQ_ERROR:
      return { ...state, createFaqError: action.payload };
    case UPDATE_FAQ_LOADING:
      return { ...state, updateFaqLoading: action.payload };
    case UPDATE_FAQ_SUCCESS:
      return { ...state, updateFaqSuccess: action.payload };
    case UPDATE_FAQ_ERROR:
      return { ...state, updateFaqError: action.payload };
    case A_FAQ_LOADING:
      return { ...state, aTicketLoading: action.payload };
    case A_FAQ_SUCCESS:
      return { ...state, aTicket: action.payload };
    case A_FAQ_ERROR:
      return { ...state, aTicketError: action.payload };
    default:
      return state;
  }
}
