import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import SolidButton from "../ButtonCmp/Button";

const TxnWrapper = styled.div`
  background-color: ${appTheme.COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  height: 430px;
`;

const NoTxnWrapper = styled.div`
  max-width: 264px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
`;

const NoTxnTitle = styled.h2`
  margin: 0;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  color: #323232;
`;

const NoTxnDesc = styled.p`
  margin: 0;
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 14px;
  color: #000000;
`;

const EmptyTableDataCmp = ({ title, desc, btnText, link }) => {
  const navigate = useNavigate();
  return (
    <TxnWrapper>
      <NoTxnWrapper>
        <NoTxnTitle>{title}</NoTxnTitle>
        <NoTxnDesc>{desc}</NoTxnDesc>
        {btnText && link && (
          <SolidButton
            text={btnText}
            type="submit"
            weighty="500"
            textFontSize="14px"
            onClick={() => {
              navigate(link);
            }}
            specifyPadding="0.8rem 0.5rem"
            color="#fff"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            maxWidth="170px"
            borderRadius="6px"
            backColor="#1a8fdd"
            backgroundHoverColor="#1a8fdd"
            textHoverColor="#fff"
            margin="1rem auto 0"
          />
        )}
      </NoTxnWrapper>
    </TxnWrapper>
  );
};

export default EmptyTableDataCmp;
