import Papa from "papaparse";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  }

  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.autoPrint({ variant: "non-conform" });
    doc.save(`Jessie-data-${new Date().toLocaleString()}.pdf`);

    return false;
  }
}

function getExportFileName({ fileType, all }) {
  if (fileType === "csv") {
    return `Jessie-data-${new Date().toLocaleString()}`;
  }
}

export { getExportFileBlob, getExportFileName };
