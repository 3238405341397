import axios from "axios";
import {
  GET_ALL_TRANSACTIONS_LOADING,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING,
  GET_ALL_TRANSACTIONS_WITH_STATUS_SUCCESS,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllTransactions =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_TRANSACTIONS_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/transaction/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: GET_ALL_TRANSACTIONS_LOADING, payload: null });
      dispatch({ type: GET_ALL_TRANSACTIONS_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: GET_ALL_TRANSACTIONS_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const getAllTxnWithStatus =
  (status, page = 1) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/transaction/all-txn/${status}?page=${page}&limit=50`,
        headers()
      );
      dispatch({
        type: GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING,
        payload: null,
      });
      dispatch({
        type: GET_ALL_TRANSACTIONS_WITH_STATUS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING,
        payload: null,
      });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };
