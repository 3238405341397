/* eslint-disable no-useless-escape */
import * as yup from "yup";

const resetValidatorSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password field is required")
    .oneOf([yup.ref("password"), null], "Password must match"),
});

export default resetValidatorSchema;
