/* eslint-disable no-useless-escape */
import * as yup from "yup";

const editPmSettingsSchema = yup.object().shape({
  name: yup.string().required("Add a name"),
  accountNumber: yup.string().required("Add an account number"),
  nairaBuyingRate: yup.string().required("Add rate"),
  nairaSellingRate: yup.string().required("Add rate"),
});

export default editPmSettingsSchema;
