import axios from "axios";
import {
  ALL_PM_SETTINGS_LOADING,
  ALL_PM_SETTINGS_SUCCESS,
  A_PM_SETTING_LOADING,
  A_PM_SETTING_SUCCESS,
  EDIT_A_PM_SETTING_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllPmSettings = () => async (dispatch) => {
  dispatch({ type: ALL_PM_SETTINGS_LOADING, payload: true });
  try {
    const response = await axios.get(`${apiBaseUrl}/pmsetting/get`, headers());
    dispatch({ type: ALL_PM_SETTINGS_LOADING, payload: null });
    dispatch({ type: ALL_PM_SETTINGS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ALL_PM_SETTINGS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getAPmSetting = (id) => async (dispatch) => {
  dispatch({ type: A_PM_SETTING_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/pmsetting/${id}`,
      headers()
    );
    dispatch({ type: A_PM_SETTING_LOADING, payload: null });
    dispatch({ type: A_PM_SETTING_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_PM_SETTING_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const editAPmSetting = (data, id) => async (dispatch) => {
  dispatch({ type: EDIT_A_PM_SETTING_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/pmsetting/edit/${id}`,
      data,
      headers()
    );
    dispatch({ type: EDIT_A_PM_SETTING_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    // dispatch(getAPmSetting(id));
    toast.success("Settings updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: EDIT_A_PM_SETTING_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
