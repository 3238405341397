import axios from "axios";
import {
  ALL_NEWS_LOADING,
  ALL_NEWS_SUCCESS,
  A_NEWS_LOADING,
  A_NEWS_SUCCESS,
  CREATE_NEWS_LOADING,
  DELETE_NEWS_LOADING,
  EDIT_NEWS_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllNews =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_NEWS_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/news/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: ALL_NEWS_LOADING, payload: null });
      dispatch({ type: ALL_NEWS_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: ALL_NEWS_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const deleteANews = (id) => async (dispatch) => {
  dispatch({ type: DELETE_NEWS_LOADING, payload: true });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/news/delete-news/${id}`,
      headers()
    );
    dispatch({ type: DELETE_NEWS_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllNews());
    toast.success("News deleted successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_NEWS_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const createNews = (data) => async (dispatch) => {
  dispatch({ type: CREATE_NEWS_LOADING, payload: true });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/news/add-news`,
      data,
      headers()
    );
    dispatch({ type: CREATE_NEWS_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllNews());
    toast.success("News created successfully", { toastId: "News success" });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_NEWS_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${
          err.response?.data?.errors[0]?.title ||
          err.response?.data?.errors[0]?.newsImage ||
          err.response?.data?.errors[0]?.content
        }`
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const editNews = (data, id) => async (dispatch) => {
  dispatch({ type: EDIT_NEWS_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/news/edit-news/${id}`,
      data,
      headers()
    );
    dispatch({ type: EDIT_NEWS_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllNews());
    toast.success("News updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: EDIT_NEWS_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getANews = (id) => async (dispatch) => {
  dispatch({ type: A_NEWS_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/news/get-news/${id}`,
      headers()
    );
    dispatch({ type: A_NEWS_LOADING, payload: null });
    dispatch({ type: A_NEWS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_NEWS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
