import {
  ALL_NAIRA_WALLET_ERROR,
  ALL_NAIRA_WALLET_LOADING,
  ALL_NAIRA_WALLET_SUCCESS,
  A_NAIRA_WALLET_ERROR,
  A_NAIRA_WALLET_LOADING,
  A_NAIRA_WALLET_SUCCESS,
  BANK_LIST_ERROR,
  BANK_LIST_LOADING,
  BANK_LIST_SUCCESS,
  GET_NAIRA_WALLET_SETTINGS_ERROR,
  GET_NAIRA_WALLET_SETTINGS_LOADING,
  GET_NAIRA_WALLET_SETTINGS_SUCCESS,
  //   DELETE_NAIRA_WALLET_ERROR,
  //   DELETE_NAIRA_WALLET_LOADING,
  //   VIEW_NAIRA_WALLET_ERROR,
  //   VIEW_NAIRA_WALLET_LOADING,
  //   VIEW_NAIRA_WALLET_SUCCESS,
  UPDATE_NAIRA_WALLET_ERROR,
  UPDATE_NAIRA_WALLET_LOADING,
  UPDATE_NAIRA_WALLET_SETTINGS_ERROR,
  UPDATE_NAIRA_WALLET_SETTINGS_LOADING,
  UPDATE_NAIRA_WALLET_SETTINGS_SUCCESS,
  UPDATE_NAIRA_WALLET_SUCCESS,
} from "./types";

const initialState = {
  allNairaWalletLoading: null,
  allNairaWallet: null,
  allNairaWalletError: null,
  aNairaWalletLoading: null,
  aNairaWallet: null,
  aNairaWalletError: null,
  deleteNairaWalletLoading: null,
  deleteNairaWalletError: null,
  viewNairaWalletLoading: null,
  viewNairaWalletuccess: null,
  viewNairaWalletError: null,
  updateNairaWalletLoading: null,
  updateNairaWalletSuccess: null,
  updateNairaWalletError: null,
  updateNairaWalletSettingsLoading: null,
  updateNairaWalletSettingsSuccess: null,
  updateNairaWalletSettingsError: null,
  bankListLoading: null,
  bankListSuccess: null,
  bankListError: null,
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_NAIRA_WALLET_LOADING:
      return { ...state, allNairaWalletLoading: action.payload };
    case ALL_NAIRA_WALLET_SUCCESS:
      return { ...state, allNairaWallet: action.payload };
    case ALL_NAIRA_WALLET_ERROR:
      return { ...state, allNairaWalletError: action.payload };
    case A_NAIRA_WALLET_LOADING:
      return { ...state, aNairaWalletLoading: action.payload };
    case A_NAIRA_WALLET_SUCCESS:
      return { ...state, aNairaWallet: action.payload };
    case A_NAIRA_WALLET_ERROR:
      return { ...state, aNairaWalletError: action.payload };
    // case DELETE_NAIRA_WALLET_LOADING:
    //   return { ...state, deleteNairaWalletLoading: action.payload };
    // case DELETE_NAIRA_WALLET_ERROR:
    //   return { ...state, deleteNairaWalletError: action.payload };
    // case VIEW_NAIRA_WALLET_LOADING:
    //   return { ...state, viewNairaWalletLoading: action.payload };
    // case VIEW_NAIRA_WALLET_SUCCESS:
    //   return { ...state, viewNairaWalletuccess: action.payload };
    // case VIEW_NAIRA_WALLET_ERROR:
    //   return { ...state, viewNairaWalletError: action.payload };
    case UPDATE_NAIRA_WALLET_LOADING:
      return { ...state, updateNairaWalletLoading: action.payload };
    case UPDATE_NAIRA_WALLET_SUCCESS:
      return { ...state, updateNairaWalletSuccess: action.payload };
    case UPDATE_NAIRA_WALLET_ERROR:
      return { ...state, updateNairaWalletError: action.payload };
    case UPDATE_NAIRA_WALLET_SETTINGS_LOADING:
      return { ...state, updateNairaWalletSettingsLoading: action.payload };
    case UPDATE_NAIRA_WALLET_SETTINGS_SUCCESS:
      return { ...state, updateNairaWalletSettingsSuccess: action.payload };
    case UPDATE_NAIRA_WALLET_SETTINGS_ERROR:
      return { ...state, updateNairaWalletSettingsError: action.payload };
    case GET_NAIRA_WALLET_SETTINGS_LOADING:
      return { ...state, getNairaWalletSettingsLoading: action.payload };
    case GET_NAIRA_WALLET_SETTINGS_SUCCESS:
      return { ...state, getNairaWalletSettingsSuccess: action.payload };
    case GET_NAIRA_WALLET_SETTINGS_ERROR:
      return { ...state, getNairaWalletSettingsError: action.payload };
    case BANK_LIST_LOADING:
      return { ...state, bankListLoading: action.payload };
    case BANK_LIST_SUCCESS:
      return { ...state, bankListSuccess: action.payload };
    case BANK_LIST_ERROR:
      return { ...state, bankListError: action.payload };
    default:
      return state;
  }
}
