import React, { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import CustomFileCmp from "../../components/InputsCmp/CustomFileCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Formik } from "formik";
import createAdSchema from "../../validators/createAdValidator";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import { createAd } from "../../redux/actions/adActions";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 550px;
  width: 100%;
  padding: 0rem 1rem;
`;

const AdFormWrapper = styled(Form)``;

const ImagePreviewWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
`;

const Image = styled(LazyLoadImage)`
  background-size: cover;
  width: 100%;
  height: 200px;
`;

const AdWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const AdTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const CreateAd = ({ setCreateAd, loading }) => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState();
  return (
    <DashboardLayout pageTitle="Create In App Ad">
      <Wrapper>
        <Formik
          initialValues={{
            file: null,
            link: "",
            showOn: "",
          }}
          validationSchema={createAdSchema}
          onSubmit={async (values, { resetForm }) => {
            // console.log(values, content);
            // resetForm();

            let formData = new FormData();
            formData.append("adImage", values.file[0], values.file[0].name);
            formData.append("link", values.link);
            formData.append("showOn", values.showOn);

            const response = await setCreateAd(formData);

            if (
              response &&
              response.status === 201 &&
              response.message === "In App Ad created"
            ) {
              return navigate("/in-app-ads");
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            isValid,
            touched,
          }) => (
            <>
              <AdFormWrapper noValidate onSubmit={handleSubmit}>
                <AdTitle>Create Ad</AdTitle>
                <AdWrapper>
                  {imagePreview && (
                    <ImagePreviewWrapper>
                      <Image src={imagePreview} alt="Preview" effect="blur" />
                    </ImagePreviewWrapper>
                  )}

                  <InputWrapper>
                    <InputLabel>Link</InputLabel>
                    <CustomInputCmp
                      required={true}
                      values={values}
                      onChange={handleChange}
                      type="text"
                      name="link"
                      placeholder="Enter Link"
                      showMessage
                      message={`${errors["link"] ? errors["link"] : ""}`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Upload Image</InputLabel>
                    <CustomFileCmp
                      maxFiles={1}
                      maxSize={1000000}
                      accept={{
                        "image/png": [".png"],
                        "image/jpeg": [".jpg", ".jpeg"],
                      }}
                      customFn={(blob) => {
                        setImagePreview(blob);
                      }}
                      type="text"
                      placeholder="Click to upload file"
                      values={values}
                      setFieldValue={setFieldValue}
                      name="file"
                      showMessage
                      message={`${errors["file"] ? errors["file"] : ""}`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Show on</InputLabel>
                    <CustomInputSelectCmp
                      background="rgba(225, 235, 245, 0.1)"
                      required={true}
                      name="showOn"
                      values={values}
                      onChange={handleChange}
                      placeholder="Select"
                      data={["Web", "App"]}
                      message={`${errors["showOn"] ? errors["showOn"] : ""}`}
                      showMessage={true}
                    />
                  </InputWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        loading ? (
                          <>
                            <SpinnerCmp
                              enabled={true}
                              color={appTheme.COLORS.bluePrimary}
                              size={20}
                              secondaryColor={appTheme.COLORS.white}
                            />
                          </>
                        ) : (
                          "Create"
                        )
                      }
                      margin="2rem 0"
                      type="submit"
                      weighty="500"
                      textFontSize="16px"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      maxWidth="200px"
                      specifyPadding="1rem 1.5rem"
                      color="#fff"
                      borderRadius="7px"
                      backColor="#016CBA"
                      backgroundHoverColor="#016CBA"
                      textHoverColor="#fff"
                      disabled={loading ? true : false}
                    />
                  </ButtonWrapper>
                </AdWrapper>
              </AdFormWrapper>
            </>
          )}
        </Formik>
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.ad.createAdLoading,
});

const mapDispatch = (dispatch) => ({
  setCreateAd: (data) => dispatch(createAd(data)),
});

export default connect(mapState, mapDispatch)(CreateAd);
