import React, { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FieldArray, Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import { useEffect } from "react";
import {
  editAGiftcardBuy,
  getAGiftcardBuy,
} from "../../redux/actions/giftcardActions";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import editBuyGiftcardSchema from "../../validators/editBuyGiftcardValidator";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  /* max-width: 768px; */
  width: 100%;
  padding: 0rem 2rem;

  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const NewsFormWrapper = styled(Form)``;

const ImagePreviewWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
`;

const Image = styled(LazyLoadImage)`
  background-size: cover;
  width: 100%;
  height: 200px;
`;

const NewsWrapper = styled.div`
  /* max-width: 500px; */
  /* margin: 0 auto; */
  width: 100%;
  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

// const InputWrapper2 = styled.div`
//   padding-bottom: 1.5rem;
//   text-align: left;
//   width: 100%;
// `;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const EditBuyGiftcard = ({
  setEditAGiftcardBuy,
  loading,
  setGetAGiftcardBuy,
  aGiftcardBuy,
  aGiftcardBuyLoading,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState();

  const [, setAGiftcardData] = useState({});

  useEffect(() => {
    const getAGiftcard = async () => {
      const response = await setGetAGiftcardBuy(id);

      setAGiftcardData(response.data);
      setImagePreview(response.data?.logoUrls[0]);
    };
    getAGiftcard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout pageTitle="Edit Giftcard Details">
      {aGiftcardBuy && (
        <Wrapper>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: aGiftcardBuy?.data?.brand?.brandName,
              cardType: aGiftcardBuy?.data?.cardType,
              rate:
                aGiftcardBuy?.data?.supportedCountries?.map((v) => {
                  return {
                    nairaDollarRate: v?.nairaDollarRate,
                    productId: v?.productId,
                    country: v?.country,
                  };
                }) || [],
            }}
            validationSchema={editBuyGiftcardSchema}
            onSubmit={async (values, { resetForm }) => {
              // console.log(values, content);
              // resetForm();

              const response = await setEditAGiftcardBuy(
                { rateData: values?.rate },
                id
              );

              if (
                response &&
                response.status === 201 &&
                response.message === "Giftcard rate updated"
              ) {
                return navigate("/giftcards/buy");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              isValid,
              touched,
            }) => (
              <>
                <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                  <NewsTitle>Buy Giftcard Details</NewsTitle>
                  <NewsWrapper>
                    {imagePreview && (
                      <ImagePreviewWrapper>
                        <Image src={imagePreview} alt="Preview" effect="blur" />
                      </ImagePreviewWrapper>
                    )}
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="name"
                          placeholder="Enter Giftcard Name"
                          showMessage
                          message={`${errors["name"] ? errors["name"] : ""}`}
                          disabled={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Card Type</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="cardType"
                          placeholder="Enter Card Type"
                          showMessage
                          message={`${
                            errors["cardType"] ? errors["cardType"] : ""
                          }`}
                          disabled={true}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>

                    <NewsTitle style={{ padding: "1rem 0" }}>Rate</NewsTitle>
                    <FieldArray
                      name="rate"
                      render={(arrayHelpers) => (
                        <>
                          {values?.rate?.map((v, i) => (
                            <div key={i}>
                              <InputGroupWrapper>
                                <InputWrapper>
                                  <InputLabel>
                                    <span>
                                      <img
                                        style={{
                                          width: "40px",
                                          aspectRatio: "3/2",
                                        }}
                                        src={v?.country?.flagUrl}
                                        alt={v?.country?.name}
                                      />
                                    </span>{" "}
                                    {v?.country?.name}
                                  </InputLabel>
                                  <CustomPriceInputCmp
                                    background="rgba(225, 235, 245, 0.1)"
                                    required={true}
                                    type="text"
                                    name={`rate[${i}].nairaDollarRate`}
                                    placeholder="Enter rate"
                                    value={values?.rate[i]?.nairaDollarRate}
                                    setFieldValue={setFieldValue}
                                    showMessage
                                    message={`${
                                      errors[`rate[${i}].nairaDollarRate`]
                                        ? errors[`rate[${i}].nairaDollarRate`]
                                        : ""
                                    }`}
                                  />
                                </InputWrapper>
                                <InputWrapper style={{ display: "none" }}>
                                  <InputLabel>Product Id</InputLabel>
                                  <CustomInputCmp
                                    required={true}
                                    value={values?.rate[i]?.productId}
                                    onChange={handleChange}
                                    type="text"
                                    name={`rate[${i}].productId`}
                                    placeholder="Enter Card Type"
                                    showMessage
                                    message={`${
                                      errors[`rate[${i}].productId`]
                                        ? errors[`rate[${i}].productId`]
                                        : ""
                                    }`}
                                    disabled={true}
                                  />
                                </InputWrapper>
                              </InputGroupWrapper>
                            </div>
                          ))}
                        </>
                      )}
                    ></FieldArray>
                    <ButtonWrapper>
                      <SolidButton
                        text={
                          loading ? (
                            <>
                              <SpinnerCmp
                                enabled={true}
                                color={appTheme.COLORS.bluePrimary}
                                size={20}
                                secondaryColor={appTheme.COLORS.white}
                              />
                            </>
                          ) : (
                            "Update"
                          )
                        }
                        margin="2rem 0"
                        type="submit"
                        weighty="500"
                        textFontSize="16px"
                        widthWebkit="100%"
                        widthMoz="100%"
                        widthNormal="100%"
                        maxWidth="200px"
                        specifyPadding="1rem 1.5rem"
                        color="#fff"
                        borderRadius="7px"
                        backColor="#016CBA"
                        backgroundHoverColor="#016CBA"
                        textHoverColor="#fff"
                        disabled={loading ? true : false}
                      />
                    </ButtonWrapper>
                  </NewsWrapper>
                </NewsFormWrapper>
              </>
            )}
          </Formik>
          {/* <RenderEditorCmp /> */}
        </Wrapper>
      )}
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.giftcard.editGiftcardBuyLoading,
  aGiftcardBuyLoading: state.giftcard.aGiftcardBuyLoading,
  aGiftcardBuy: state.giftcard.aGiftcardBuy,
});

const mapDispatch = (dispatch) => ({
  setEditAGiftcardBuy: (data, id) => dispatch(editAGiftcardBuy(data, id)),
  setGetAGiftcardBuy: (id) => dispatch(getAGiftcardBuy(id)),
});

export default connect(mapState, mapDispatch)(EditBuyGiftcard);
