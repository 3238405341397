/* eslint-disable no-useless-escape */
import * as yup from "yup";

const editCryptoSettingsSchema = yup.object().shape({
  coinName: yup.string().required("Add a name"),
  nairaBuyingRate: yup.string().required("Add rate"),
  nairaSellingRate: yup.string().required("Add rate"),
  dollarInternal: yup.string().required(),
  dollarHigh: yup.string().required(),
  dollarLow: yup.string().required(),
  dollarMedium: yup.string().required(),
  shortcode: yup.string().required(),
});

export default editCryptoSettingsSchema;
