import React from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import CustomTextAreaCmp from "../../components/InputsCmp/CustomTextAreaCmp";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import { categories } from "../../constants/categories";
import { createFaq } from "../../redux/actions/faqActions";
import createFaqSchema from "../../validators/createFaqValidator";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 550px;
  width: 100%;
  padding: 0rem 1rem;
`;

const NewsFormWrapper = styled(Form)``;

const NewsWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;

  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const CreateFaq = ({ setCreateFaq, loading }) => {
  const navigate = useNavigate();
  return (
    <DashboardLayout pageTitle="Create FAQs">
      <Wrapper>
        <Formik
          initialValues={{
            question: "",
            answer: "",
            showOnHome: "",
            category: "",
          }}
          validationSchema={createFaqSchema}
          onSubmit={async (values, { resetForm }) => {
            // resetForm();
            const response = await setCreateFaq({
              ...values,
              showOnHome: values.showOnHome === "Yes" ? true : false,
            });
            if (
              response &&
              response.status === 201 &&
              response.message === "Faq created"
            ) {
              return navigate("/help-center/faqs");
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                <NewsTitle>FAQs Details</NewsTitle>
                <NewsWrapper>
                  <InputWrapper>
                    <InputLabel>Question</InputLabel>
                    <CustomInputCmp
                      required={true}
                      values={values}
                      onChange={handleChange}
                      type="text"
                      name="question"
                      placeholder="Enter question"
                      showMessage
                      message={`${
                        errors["question"] ? errors["question"] : ""
                      }`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Category</InputLabel>
                    <CustomInputSelectCmp
                      background="rgba(225, 235, 245, 0.1)"
                      required={true}
                      name="category"
                      values={values}
                      onChange={handleChange}
                      placeholder="Select"
                      data={categories}
                      message={`${
                        errors["category"] ? errors["category"] : ""
                      }`}
                      showMessage={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Answer</InputLabel>
                    <CustomTextAreaCmp
                      background="#fff"
                      as="textarea"
                      rows={3}
                      required={true}
                      onChange={handleChange}
                      values={values}
                      type="text"
                      name="answer"
                      placeholder="Enter content"
                      showMessage
                      message={`${errors["answer"] ? errors["answer"] : ""}`}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Show on homepage</InputLabel>
                    <CustomInputSelectCmp
                      background="rgba(225, 235, 245, 0.1)"
                      required={true}
                      name="showOnHome"
                      values={values}
                      onChange={handleChange}
                      placeholder="Select"
                      data={["Yes", "No"]}
                      message={`${
                        errors["showOnHome"] ? errors["showOnHome"] : ""
                      }`}
                      showMessage={true}
                    />
                  </InputWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        loading ? (
                          <>
                            <SpinnerCmp
                              enabled={true}
                              color={appTheme.COLORS.bluePrimary}
                              size={20}
                              secondaryColor={appTheme.COLORS.white}
                            />
                          </>
                        ) : (
                          "Create"
                        )
                      }
                      margin="2rem 0"
                      type="submit"
                      weighty="500"
                      textFontSize="16px"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      maxWidth="200px"
                      specifyPadding="1rem 1.5rem"
                      color="#fff"
                      borderRadius="7px"
                      backColor="#016CBA"
                      backgroundHoverColor="#016CBA"
                      textHoverColor="#fff"
                      disabled={loading ? true : false}
                    />
                  </ButtonWrapper>
                </NewsWrapper>
              </NewsFormWrapper>
            </>
          )}
        </Formik>
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.faq.createFaqLoading,
});

const mapDispatch = (dispatch) => ({
  setCreateFaq: (data) => dispatch(createFaq(data)),
});

export default connect(mapState, mapDispatch)(CreateFaq);
