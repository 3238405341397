import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import newsReducer from "./newsReducer";
import updateReducer from "./updateReducer";
import ticketReducer from "./ticketReducer";
import faqReducer from "./faqReducer";
import adReducer from "./adReducer";
import careerReducer from "./careerReducer";
import otcReducer from "./otcReducer";
import statReducer from "./statReducer";
import walletReducer from "./walletReducer";
import userReducer from "./userReducer";
import transactionReducer from "./transactionReducer";
import pmReducer from "./pmReducer";
import cryptoReducer from "./cryptoReducer";
import giftcardReducer from "./giftcardReducer";
import roleReducer from "./roleReducer";

const rootReducer = {
  auth: authReducer,
  dashboard: dashboardReducer,
  news: newsReducer,
  update: updateReducer,
  ticket: ticketReducer,
  faq: faqReducer,
  ad: adReducer,
  career: careerReducer,
  otc: otcReducer,
  stat: statReducer,
  wallet: walletReducer,
  user: userReducer,
  transaction: transactionReducer,
  pm: pmReducer,
  crypto: cryptoReducer,
  giftcard: giftcardReducer,
  role: roleReducer,
};

export default rootReducer;
