export const categories = [
  "Giftcard",
  "Crypto",
  "Perfect Money",
  "Renew",
  "Excess Airtime to Cash",
  "Wallets",
  "2FA & Security",
  "Login & Account",
  "Transaction",
  "Limits & KYC Verification",
];
