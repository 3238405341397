import React from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { useExportData } from "react-table-plugins";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { GlobalFilter } from "./Filter";
import TableActionsCmp from "./TableActionsCmp";
import { getExportFileBlob, getExportFileName } from "./functions";
import PaginationCmp from "../PaginationCmp/PaginationCmp";

const FilterAndSearchWrapper = styled.div`
  padding: 1rem 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 884px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TableWrapper = styled.div`
  background-color: ${appTheme.COLORS.white};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const Container = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  width: ${(props) => (props.width ? props.width : "100%")};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  /* max-width: 80%;
  width: 100%; */
`;

const TableTag = styled.table``;

const TableHead = styled.thead`
  border-bottom: 1px solid #c4c4c4;
  th {
    padding: 1rem 0;
  }
  tr,
  th {
    font-weight: ${appTheme.WEIGHT.bold};
    font-size: 15px;
    color: #323232;
  }
`;

const TableBody = styled.tbody`
  tr:hover {
    box-shadow: 1px 1px 17px rgba(218, 216, 216, 0.33);
  }
  tr td {
    padding: 1rem 0;
  }
`;

const TableR = styled.tr``;

const TableH = styled.th``;

const TableD = styled.td``;

const TableCmp = (props) => {
  //useMemo on data and columns does not make the
  const data = React.useMemo(
    () => props.data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.data]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => props.columns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    exportData,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn,
      initialState: { pageSize: props.defaultPageSize || 2 },
      getExportFileBlob,
      getExportFileName,
    },
    useFilters,
    useGlobalFilter, // useGlobalFilter!
    usePagination,
    useExportData
  );

  return (
    <>
      {(props.showSearch || props.showCustomFilter) && (
        <FilterAndSearchWrapper>
          {props.showSearch && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              searchPlaceholderText={props.searchPlaceholderText}
            />
          )}
          {props.showActionBtns && <TableActionsCmp exportData={exportData} />}
        </FilterAndSearchWrapper>
      )}
      <TableWrapper>
        <Container
          width={props.containerWidth}
          maxWidth={props.containerMaxWidth}
          height={props.containerHeight}
          maxHeight={props.containerMaxHeight}
        >
          <TableTag {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableR {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <TableH
                      key={i}
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                        },
                      })}
                    >
                      {column.render("Header")}
                      {/* Render the columns filter UI */}
                      {/* <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div> */}
                    </TableH>
                  ))}
                </TableR>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {props.enablePagination
                ? page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableR key={i} {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                          return (
                            <TableD key={index} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </TableD>
                          );
                        })}
                      </TableR>
                    );
                  })
                : rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableR key={i} {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                          return (
                            <TableD key={index} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </TableD>
                          );
                        })}
                      </TableR>
                    );
                  })}
            </TableBody>
          </TableTag>
        </Container>
      </TableWrapper>
      {props.enablePagination && (
        <PaginationCmp
          totalRows={props.dataCount}
          pageChangeHandler={props.setCurrentPage}
          currentPageNo={props.currentPage}
          rowsPerPage={50}
        />
      )}
    </>
  );
};

export default TableCmp;
