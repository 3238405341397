import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Logout from "./components/LogoutCmp/LogoutCmp";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import News from "./pages/News/News";
import { getUserRole } from "./redux/actions/authActions";
import { loadToken } from "./redux/token";
import PrivateRoute from "./components/PrivateRouteCmp/PrivateRoute";
import CreateNews from "./pages/News/CreateNews";
import CreateUpdate from "./pages/Updates/CreateUpdate";
import Updates from "./pages/Updates/Updates";
import Tickets from "./pages/Tickets/Tickets";
import ViewTicket from "./pages/Tickets/ViewTicket";
import EditNews from "./pages/News/EditNews";
import Faqs from "./pages/Faqs/Faqs";
import CreateFaq from "./pages/Faqs/CreateFaq";
import EditFaq from "./pages/Faqs/EditFaq";
import Ad from "./pages/Ads/Ad";
import CreateAd from "./pages/Ads/CreateAd";
import EditAd from "./pages/Ads/EditAd";
import Careers from "./pages/Career/Careers";
import CreateCareer from "./pages/Career/CreateCareer";
import EditCareer from "./pages/Career/EditCareer";
import Otc from "./pages/Otc/Otc";
import EditOtc from "./pages/Otc/EditOtc";
import NairaWallet from "./pages/NairaWallet/NairaWallet";
import EditWalletSettings from "./pages/NairaWallet/EditWalletSettings";
import ViewDepositWallet from "./pages/NairaWallet/ViewDepositWallet";
import ViewWithdrawalWallet from "./pages/NairaWallet/ViewWithdrawalWallet";
import User from "./pages/User/User";
import ViewUser from "./pages/User/ViewUser";
import AllTransactions from "./pages/Transaction/AllTransactions";
import AllTransactionsWithStatus from "./pages/Transaction/AllTransactionsWithStatus";
import PerfectMoney from "./pages/PerfectMoney/PerfectMoney";
import EditPerfectMoney from "./pages/PerfectMoney/EditPerfectMoney";
import Crypto from "./pages/Crypto/Crypto";
import EditCrypto from "./pages/Crypto/EditCrypto";
import GiftcardBuy from "./pages/Giftcard/GiftcardBuy";
import EditBuyGiftcard from "./pages/Giftcard/EditBuyGiftcard";
import GiftcardSell from "./pages/Giftcard/GiftcardSell";
import SellGiftcardCountry from "./pages/Giftcard/SellGiftcardCountry";
import EditSellGiftcard from "./pages/Giftcard/EditSellGiftcard";
import PendingTxns from "./pages/Giftcard/PendingTxns";
import ViewAGiftcardTransaction from "./pages/Giftcard/ViewAGiftcardTransaction";
import Roles from "./pages/Roles/Roles";
import CreateRole from "./pages/Roles/CreateRole";
import EditRole from "./pages/Roles/EditRole";

const RouteCmp = ({ auth, userRole, setGetUserRole }) => {
  useEffect(() => {
    if (!userRole) {
      setGetUserRole();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/forgot/password" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        {!auth.authenticated ||
          (!loadToken() && (
            <Route path="*" element={<Navigate to="/login" />} />
          ))}

        <Route path="/" element={<PrivateRoute />}>
          {userRole !== undefined && (
            <>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="help-center/tickets" element={<Tickets />} />
              <Route path="help-center/tickets/:id" element={<ViewTicket />} />
              <Route path="help-center/blogs" element={<News />} />
              <Route path="help-center/blogs/create" element={<CreateNews />} />
              <Route path="help-center/blogs/edit/:id" element={<EditNews />} />
              <Route path="help-center/faqs" element={<Faqs />} />
              <Route path="help-center/faqs/create" element={<CreateFaq />} />
              <Route path="help-center/faqs/edit/:id" element={<EditFaq />} />
              <Route path="news-and-updates" element={<Updates />} />
              <Route
                path="news-and-updates/create"
                element={<CreateUpdate />}
              />
              <Route path="in-app-ads" element={<Ad />} />
              <Route path="in-app-ads/create" element={<CreateAd />} />
              <Route path="in-app-ads/edit/:id" element={<EditAd />} />
              <Route path="otc" element={<Otc />} />
              <Route path="otc/:id" element={<EditOtc />} />
              <Route path="careers" element={<Careers />} />
              <Route path="careers/create" element={<CreateCareer />} />
              <Route path="careers/edit/:id" element={<EditCareer />} />
              <Route
                path="naira-wallet/transactions"
                element={<NairaWallet />}
              />
              <Route
                path="naira-wallet/settings"
                element={<EditWalletSettings />}
              />
              <Route
                path="naira-wallet/deposit/:depositId"
                element={<ViewDepositWallet />}
              />
              <Route
                path="naira-wallet/withdrawal/:withdrawalId"
                element={<ViewWithdrawalWallet />}
              />
              <Route path="giftcards/buy" element={<GiftcardBuy />} />
              <Route
                path="giftcards/buy/edit/:id"
                element={<EditBuyGiftcard />}
              />
              <Route path="giftcards/sell" element={<GiftcardSell />} />
              <Route
                path="giftcards/pending/transactions"
                element={<PendingTxns />}
              />
              <Route
                path="giftcards/pending/transaction/:id"
                element={<ViewAGiftcardTransaction />}
              />
              <Route
                path="giftcards/sell/country"
                element={<SellGiftcardCountry />}
              />
              <Route
                path="giftcards/sell/edit/:productId"
                element={<EditSellGiftcard />}
              />
              <Route path="crypto/settings" element={<Crypto />} />
              <Route path="crypto/settings/edit/:id" element={<EditCrypto />} />
              <Route path="perfect-money/settings" element={<PerfectMoney />} />
              <Route
                path="perfect-money/settings/edit/:id"
                element={<EditPerfectMoney />}
              />
              <Route path="transactions/all" element={<AllTransactions />} />
              <Route
                path="transactions/:status"
                element={<AllTransactionsWithStatus />}
              />
              <Route path="users" element={<User />} />
              <Route path="user/:id" element={<ViewUser />} />
              <Route path="roles/:userRole" element={<Roles />} />
              <Route path="roles-create/:userRole" element={<CreateRole />} />
              <Route
                path="roles-edit/:userRole/:adminId"
                element={<EditRole />}
              />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

const mapState = (state) => ({
  auth: state.auth,
  userRole: state.auth.userRole,
});

const mapDispatch = (dispatch) => ({
  setGetUserRole: () => dispatch(getUserRole()),
});

export default connect(mapState, mapDispatch)(RouteCmp);
