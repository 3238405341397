/* eslint-disable no-useless-escape */
import * as yup from "yup";

const editSellGiftcardSchema = yup.object().shape({
  giftcardType: yup.string().required("Please add card type"),
  rateData: yup
    .array()
    .of(
      yup.object().shape({
        nairaDollarRate: yup.string().required(),
        denomination: yup.number().required(),
        _id: yup.string().required(),
      })
    )
    .required("Must have rate"),
});

export default editSellGiftcardSchema;
