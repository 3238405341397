import React, { useEffect, useState } from "react";
import { stateToHTML } from "draft-js-export-html";
import parse from "html-react-parser";

const RenderEditorCmp = ({ contentState, unstyledBlockStyle }) => {
  const styleOptions = {
    inlineStyles: {
      BOLD: {
        style: { fontSize: 16, color: "#323232", ...unstyledBlockStyle },
      },
      ITALIC: {
        style: { fontSize: 16, color: "#323232", ...unstyledBlockStyle },
      },
      UNDERLINE: {
        style: { fontSize: 16, color: "#323232", ...unstyledBlockStyle },
      },
      MONOSPACE: {
        style: {
          fontSize: 16,
          color: "#323232",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          fontFamily: "Inconsolata, Menlo, Consolas, monospace",
          padding: "2px",
          ...unstyledBlockStyle,
        },
      },
    },
    blockStyleFn: (block) => {
      const type = block.getType();
      // console.log(block.getType());
      if (type === "header-one") {
        return {
          style: {
            fontSize: 40,
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "header-two") {
        return {
          style: {
            fontSize: 35,
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "header-three") {
        return {
          style: {
            fontSize: 30,
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "header-four") {
        return {
          style: {
            fontSize: 25,
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "header-five") {
        return {
          style: {
            fontSize: 20,
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "header-six") {
        return {
          style: {
            fontSize: 15,
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "blockquote") {
        return {
          style: {
            borderLeft: "5px solid #016cba",
            fontStyle: "italic",
            margin: "16px 0",
            padding: "10px 20px",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "unstyled") {
        return {
          style: {
            fontSize: 16,
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "unordered-list-item") {
        return {
          style: {
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "ordered-list-item") {
        return {
          style: {
            color: "#323232",
            ...unstyledBlockStyle,
          },
        };
      } else if (type === "code-block") {
        return {
          style: {
            color: "#323232",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            // fontSize: 16,
            padding: "20px",
            ...unstyledBlockStyle,
          },
        };
      }
    },
  };

  const [html, setHtml] = useState("");

  useEffect(() => {
    const convertToHtml = stateToHTML(contentState, styleOptions);
    // console.log(convertToHtml);
    setHtml(convertToHtml);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentState]);
  return <>{parse(html)}</>;
};

export default RenderEditorCmp;
