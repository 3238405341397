/* eslint-disable no-useless-escape */
import * as yup from "yup";

const editWalletSettingsSchema = yup.object().shape({
  nairaWalletDepositFee: yup.string().required("Type an amount"),
  peerWithdrawalFee: yup.string().required("Type an amount"),
  jessieUserFee: yup.string().required("Type an amount"),
  primaryAccountNumber: yup.string().required("Type account number"),
  primaryAccountName: yup.string().required("Type account name"),
  primaryBankName: yup.string().required("Type a bank name"),
  secondaryAccountNumber: yup.string().required("Type account number"),
  secondaryAccountName: yup.string().required("Type account name"),
  secondaryBankName: yup.string().required("Type an bank name"),
});

export default editWalletSettingsSchema;
