/* eslint-disable no-useless-escape */
import * as yup from "yup";

const nairaDepositSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Type an amount")
    .test("Check minimum value", "Deposit minimum of ₦100", function (value) {
      console.log(value);
      if (parseInt(this.parent.amount) < 100) {
        return false;
      }
      return true;
    })
    .strict(),
});

export default nairaDepositSchema;
