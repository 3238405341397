import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Eye } from "@styled-icons/ionicons-outline/Eye";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import TableCmp from "../../components/TableCmp/TableCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { iconCategory, statusColorMap } from "../../constants/others";
import NumberFormat from "react-number-format";
import { getAllPendingTransactions } from "../../redux/actions/giftcardActions";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate } from "react-router-dom";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div``;

const TxnWrapper = styled.div`
  padding-top: 1rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const PendingTxns = ({
  setGetAllPendingTransactions,
  loading,
  error,
  success,
}) => {
  const [transactionData, setTransactionData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    async function callGetAllTransactions() {
      setLoadingState(true);
      const response = await setGetAllPendingTransactions(currentPage);
      setTransactionData(response.data.results);
      setDataCount(response.data.count);
      setLoadingState(false);
    }

    callGetAllTransactions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  return (
    <>
      <DashboardLayout pageTitle="Pending Transactions">
        <Wrapper>
          <TxnWrapper>
            {loadingState ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SpinnerCmp
                  enabled={true}
                  color={appTheme.COLORS.bluePrimary}
                  size={24}
                  secondaryColor={appTheme.COLORS.white}
                />
              </div>
            ) : transactionData.length > 0 ? (
              <TableCmp
                showActionBtns={true}
                enablePagination={true}
                showSearch={true}
                showCustomFilter={true}
                searchPlaceholderText={"Search here"}
                containerHeight="350px"
                defaultPageSize={50}
                data={[...transactionData]}
                dataCount={dataCount}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                columns={[
                  {
                    Header: "",
                    accessor: "txnType",
                    id: "icon",
                    maxWidth: 100,
                    minWidth: 60,
                    width: 100,
                    Cell: (props) => {
                      return (
                        <LazyLoadImage
                          style={{ width: "40px", height: "40px" }}
                          src={iconCategory[props?.value]}
                          alt="icon"
                          effect="blur"
                        />
                      );
                    },
                  },
                  {
                    Header: "Transaction ID",
                    accessor: "txnId",
                    maxWidth: 200,
                    minWidth: 180,
                    width: 200,
                    Cell: (props) => {
                      return <p style={{ margin: "0" }}>{props.value}</p>;
                    },
                  },
                  {
                    Header: "Username",
                    accessor: "userId",
                    maxWidth: 200,
                    minWidth: 180,
                    width: 200,
                    Cell: (props) => {
                      return (
                        <p style={{ margin: "0" }}>
                          {props.value.username || props.value.businessName}
                        </p>
                      );
                    },
                  },
                  {
                    Header: "Type",
                    accessor: "txnType",
                    id: "txnType",
                    maxWidth: 200,
                    minWidth: 180,
                    width: 200,
                    Cell: (props) => {
                      return (
                        <p
                          style={{
                            fontWeight: "700",
                            margin: "0",
                          }}
                        >
                          {props.value}
                        </p>
                      );
                    },
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    id: "status",
                    maxWidth: 200,
                    minWidth: 100,
                    width: 200,
                    Cell: (props) => {
                      return (
                        <p
                          style={{
                            color: statusColorMap[props.value],
                            margin: "0",
                            fontWeight: "700",
                          }}
                        >
                          {props.value}
                        </p>
                      );
                    },
                  },
                  {
                    Header: "Amount",
                    accessor: "totalAmount",
                    id: "amount",
                    maxWidth: 200,
                    minWidth: 100,
                    width: 200,
                    Cell: (props) => {
                      return (
                        <p
                          style={{
                            fontWeight: "700",
                            margin: "0",
                          }}
                        >
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={props.value}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </p>
                      );
                    },
                  },
                  {
                    Header: "Date",
                    accessor: "createdAt",
                    id: "date",
                    maxWidth: 200,
                    minWidth: 140,
                    width: 200,
                    Cell: (props) => {
                      return (
                        <p style={{ margin: "0" }}>
                          {new Date(props.value).toDateString()}
                        </p>
                      );
                    },
                  },
                  {
                    Header: "Action",
                    accessor: "_id",
                    maxWidth: 200,
                    minWidth: 100,
                    width: 200,
                    Cell: ({ row }) => {
                      return (
                        <>
                          <ActionsWrapper>
                            <SolidButton
                              text={
                                <>
                                  <Eye color="#58c283" size={15} />
                                </>
                              }
                              type="submit"
                              weighty="500"
                              textFontSize="14px"
                              borderColor="#58c283"
                              borderHoverColor="#58c283"
                              onClick={() => {
                                navigate(
                                  `/giftcards/pending/transaction/${row.values._id}`
                                );
                              }}
                              specifyPadding="0.5rem 0.3rem"
                              color="#58c283"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              maxWidth="35px"
                              borderRadius="6px"
                              backColor="transparent"
                              backgroundHoverColor="transparent"
                              textHoverColor="#58c283"
                            />
                          </ActionsWrapper>
                        </>
                      );
                    },
                  },
                ]}
              />
            ) : (
              <EmptyTableDataCmp title="No transaction available" />
            )}
          </TxnWrapper>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  loading: state.giftcard.allPendingGiftcardSellTxnLoading,
  error: state.giftcard.allPendingGiftcardSellTxnError,
  success: state.giftcard.allPendingGiftcardSellTxn,
});

const mapDispatch = (dispatch) => ({
  setGetAllPendingTransactions: (page) =>
    dispatch(getAllPendingTransactions(page)),
});

export default connect(mapState, mapDispatch)(PendingTxns);
