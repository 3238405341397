import {
  GET_ALL_TRANSACTIONS_ERROR,
  GET_ALL_TRANSACTIONS_LOADING,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_WITH_STATUS_ERROR,
  GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING,
  GET_ALL_TRANSACTIONS_WITH_STATUS_SUCCESS,
} from "./types";

const initialState = {
  allTransactionLoading: null,
  allTransaction: null,
  allTransactionError: null,
  getAllTransactionWithStatusLoading: null,
  getAllTransactionWithStatus: null,
  getAllTransactionWithStatusError: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS_LOADING:
      return { ...state, allTransactionLoading: action.payload };
    case GET_ALL_TRANSACTIONS_SUCCESS:
      return { ...state, allTransaction: action.payload };
    case GET_ALL_TRANSACTIONS_ERROR:
      return { ...state, allTransactionError: action.payload };
    case GET_ALL_TRANSACTIONS_WITH_STATUS_LOADING:
      return { ...state, getAllTransactionWithStatusLoading: action.payload };
    case GET_ALL_TRANSACTIONS_WITH_STATUS_SUCCESS:
      return { ...state, getAllTransactionWithStatus: action.payload };
    case GET_ALL_TRANSACTIONS_WITH_STATUS_ERROR:
      return { ...state, getAllTransactionWithStatusError: action.payload };
    default:
      return state;
  }
}
