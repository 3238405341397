import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllSellGiftcardCmp from "./AllSellGiftcard";

const Wrapper = styled.div``;

const GiftcardSell = () => {
  return (
    <>
      <DashboardLayout pageTitle="Sell Giftcard">
        <Wrapper>
          <AllSellGiftcardCmp />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default GiftcardSell;
