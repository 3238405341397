import { Eye } from "@styled-icons/fa-solid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import TableCmp from "../../components/TableCmp/TableCmp";
import { statusColorMap } from "../../constants/others";
import {
  getAllWalletTxns,
  updateWalletTxnStatus,
} from "../../redux/actions/walletActions";
import { convertToLocalDateTime } from "../../utils/utils";
import MoneyFormatter from "../../components/MoneyFormatterCmp/MoneyFormatter";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllNairaWalletTxnsCmp = ({
  setGetAllWalletTxns,
  setUpdateWalletTxnStatus,
}) => {
  const [walletTxnsData, setWalletTxnsData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getAllTxns = async () => {
      setLoadingState(true);
      const response = await setGetAllWalletTxns("pending", currentPage);

      setWalletTxnsData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllTxns();
    } else {
      getAllTxns();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : walletTxnsData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...walletTxnsData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "TransactionID",
                accessor: "txn",
                maxWidth: 500,
                minWidth: 180,
                width: 500,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#323232",
                      }}
                    >
                      {props.value.txnId}
                    </p>
                  );
                },
              },
              {
                Header: "Username",
                accessor: "userId",
                id: "username",
                maxWidth: 500,
                minWidth: 180,
                width: 500,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value.username}
                    </p>
                  );
                },
              },
              {
                Header: "Amount",
                accessor: "txn",
                id: "amount",
                maxWidth: 300,
                minWidth: 180,
                width: 180,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      <>
                        <MoneyFormatter value={props.value.amount} />
                      </>
                    </p>
                  );
                },
              },
              {
                Header: "Total Amount",
                accessor: "txn",
                id: "total-amount",
                maxWidth: 300,
                minWidth: 180,
                width: 180,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      <>
                        <MoneyFormatter value={props.value.totalAmount} />
                      </>
                    </p>
                  );
                },
              },
              {
                Header: "Txn Type",
                accessor: "onModel",
                id: "txnType",
                maxWidth: 300,
                minWidth: 180,
                width: 180,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Date Created",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {convertToLocalDateTime(props.value)}
                    </p>
                  );
                },
              },
              {
                Header: "Status",
                accessor: "txn",
                id: "status",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        color: statusColorMap[props.value.status],
                        margin: "0",
                        fontWeight: "700",
                        fontSize: "15px",
                      }}
                    >
                      {props.value.status}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ value, row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        <SolidButton
                          text={
                            <>
                              <Eye color="#3E4954" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#3E4954"
                          borderHoverColor="#3E4954"
                          onClick={() => {
                            navigate(
                              `/naira-wallet/${
                                row.original.onModel === "Deposit"
                                  ? "deposit"
                                  : "withdrawal"
                              }/${value}`,
                              { state: { ...row.original } }
                            );
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#3E4954"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#3E4954"
                        />
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp title="No transactions available" />
        )}
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  loading: state.wallet.allWalletTxnsLoading,
  error: state.wallet.allWalletTxnsError,
  allWalletTxns: state.wallet.allWalletTxns,
});

const mapDispatch = (dispatch) => ({
  setGetAllWalletTxns: (type, page) => dispatch(getAllWalletTxns(type, page)),
  setUpdateWalletTxnStatus: (status, id) =>
    dispatch(updateWalletTxnStatus(status, id)),
});

export default connect(mapState, mapDispatch)(AllNairaWalletTxnsCmp);
