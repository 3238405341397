import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

const StatWrapper = styled.div`
  background-color: #fff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 180px;
  width: 100%;
  height: 120px;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 424px) {
    max-width: 150px;
  }
`;

const StatInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: "5px";
`;

const StatTitle = styled.h2`
  font-size: 13px;
  font-weight: 300;
  color: #000;

  @media only screen and (max-width: 424px) {
    font-size: 11px;
  }
`;

const Amount = styled.h2`
  font-size: 20px;
  font-weight: 800;
  color: #323232;
  margin: 0;

  @media only screen and (max-width: 424px) {
    font-size: 16px;
  }
`;

const SmallAmount = styled.p`
  font-size: 13px;
  font-weight: 300;
  color: #323232;
  margin: 0;
  padding-top: 8px;
`;

// const MiniText = styled.p`
//   font-weight: 400;
//   font-size: 12px;
//   color: #969ba0;
//   margin: 0;

//   @media only screen and (max-width: 424px) {
//     font-size: 10px;
//   }
// `;

const IconImg = styled(LazyLoadImage)``;

const StatCardCmp = ({ statTitle, amount, smallAmount, icon }) => {
  return (
    <>
      <StatWrapper>
        <StatInfoWrapper>
          <StatTextWrapper>
            <StatTitle>{statTitle}</StatTitle>
            <Amount>{amount}</Amount>
            <SmallAmount>{smallAmount}</SmallAmount>
          </StatTextWrapper>
          <IconImg src={icon} effect="blur" alt="statTitle" />
        </StatInfoWrapper>
        {/* <MiniText>4% (30 days)</MiniText> */}
      </StatWrapper>
    </>
  );
};

export default StatCardCmp;
