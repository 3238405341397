import { Pencil, Trash } from "@styled-icons/boxicons-regular";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import ActionModal from "../../components/Modals/ActionModal";
import TableCmp from "../../components/TableCmp/TableCmp";
import { deleteAFaq, getAllFaq } from "../../redux/actions/faqActions";
import { convertToLocalDateTime, trimText } from "../../utils/utils";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllFaqsCmp = ({ setGetAllFaq, setDeleteAFaq, userRole }) => {
  const [faqData, setFaqData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllFaq = async () => {
      setLoadingState(true);
      const response = await setGetAllFaq(currentPage);

      setFaqData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllFaq();
    } else {
      getAllFaq();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const [showActionModal, setShowActionModal] = useState(false);
  const [item, setItem] = useState();

  const handleDeleteAction = () => {
    setDeleteAFaq(item._id);
    setRefresh(true);
  };

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : faqData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...faqData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "Question",
                accessor: "question",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {trimText(props.value, 25)}
                    </p>
                  );
                },
              },
              {
                Header: "Answer",
                accessor: "answer",
                id: "answer",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {trimText(props.value, 25)}
                    </p>
                  );
                },
              },
              {
                Header: "Category",
                accessor: "category",
                id: "category",
                maxWidth: 300,
                minWidth: 180,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Date Created",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {convertToLocalDateTime(props.value)}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        {["Super Admin", "Admin", "Support"].includes(
                          userRole
                        ) && (
                          <>
                            {" "}
                            <SolidButton
                              text={
                                <>
                                  <Pencil color="#2a9bf2" size={15} />
                                </>
                              }
                              type="submit"
                              weighty="500"
                              textFontSize="14px"
                              borderColor="#2a9bf2"
                              borderHoverColor="#2a9bf2"
                              onClick={() => {
                                navigate(
                                  `/help-center/faqs/edit/${row.values._id}`
                                );
                              }}
                              specifyPadding="0.5rem 0.3rem"
                              color="#2a9bf2"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              maxWidth="35px"
                              borderRadius="6px"
                              backColor="transparent"
                              backgroundHoverColor="transparent"
                              textHoverColor="#2a9bf2"
                            />
                            <SolidButton
                              text={
                                <>
                                  <Trash color="#d60000" size={15} />
                                </>
                              }
                              type="submit"
                              weighty="500"
                              textFontSize="14px"
                              borderColor="#D60000"
                              borderHoverColor="#D60000"
                              onClick={() => {
                                setShowActionModal(true);
                                setItem(row.values);
                              }}
                              specifyPadding="0.5rem 0.3rem"
                              color="#D60000"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              maxWidth="35px"
                              borderRadius="6px"
                              backColor="transparent"
                              backgroundHoverColor="transparent"
                              textHoverColor="#D60000"
                            />
                          </>
                        )}
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No faqs added yet"
            desc="Please add faq to populate this area"
            btnText={"Create Faq"}
            link="/help-center/faqs/create"
          />
        )}
      </Wrapper>
      <ActionModal
        maxWidth="450px"
        wrapperPadding={"1rem 1rem 2rem"}
        showCloseIcon={true}
        isOpen={showActionModal}
        onRequestClose={() => {
          setShowActionModal(false);
        }}
        runAction={handleDeleteAction}
        actionOnReject={() => {
          setItem();
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.faq.allFaqLoading,
  error: state.faq.allFaqError,
  faqs: state.faq.allFaq,
  userRole: state.auth.userRole,
});

const mapDispatch = (dispatch) => ({
  setGetAllFaq: (page) => dispatch(getAllFaq(page)),
  setDeleteAFaq: (id) => dispatch(deleteAFaq(id)),
});

export default connect(mapState, mapDispatch)(AllFaqsCmp);
