import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { getAllTransactions } from "../../redux/actions/transactionAction";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import TableCmp from "../../components/TableCmp/TableCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { iconCategory, statusColorMap } from "../../constants/others";
import NumberFormat from "react-number-format";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div``;

const TxnWrapper = styled.div`
  padding-top: 1rem;
`;

const AllTransactions = ({
  setGetAllTransactions,
  loading,
  error,
  success,
}) => {
  const [transactionData, setTransactionData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    async function callGetAllTransactions() {
      setLoadingState(true);
      const response = await setGetAllTransactions(currentPage);
      setTransactionData(response.data.results);
      setDataCount(response.data.count);
      setLoadingState(false);
    }

    callGetAllTransactions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  return (
    <>
      <DashboardLayout pageTitle="All Transactions">
        <Wrapper>
          <TxnWrapper>
            {loadingState ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SpinnerCmp
                  enabled={true}
                  color={appTheme.COLORS.bluePrimary}
                  size={24}
                  secondaryColor={appTheme.COLORS.white}
                />
              </div>
            ) : (
              <>
                {transactionData.length > 0 ? (
                  <TableCmp
                    showActionBtns={true}
                    enablePagination={true}
                    showSearch={true}
                    showCustomFilter={true}
                    searchPlaceholderText={"Search here"}
                    containerHeight="350px"
                    defaultPageSize={50}
                    data={[...transactionData]}
                    dataCount={dataCount}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    columns={[
                      {
                        Header: "",
                        accessor: "txn",
                        id: "icon",
                        maxWidth: 100,
                        minWidth: 60,
                        width: 100,
                        Cell: (props) => {
                          return (
                            <LazyLoadImage
                              style={{ width: "40px", height: "40px" }}
                              src={iconCategory[props.value.txnType]}
                              alt="icon"
                              effect="blur"
                            />
                          );
                        },
                      },
                      {
                        Header: "Transaction ID",
                        accessor: "txn",
                        Cell: (props) => {
                          return (
                            <p style={{ margin: "0" }}>
                              {props.value.txnId || props.value.localTxnId}
                            </p>
                          );
                        },
                      },
                      {
                        Header: "Username",
                        accessor: "userId",
                        Cell: (props) => {
                          return (
                            <p style={{ margin: "0" }}>
                              {props.value.username || props.value.businessName}
                            </p>
                          );
                        },
                      },
                      {
                        Header: "Type",
                        accessor: "txn",
                        id: "txnType",
                        maxWidth: 200,
                        minWidth: 180,
                        width: 200,
                        Cell: (props) => {
                          return (
                            <p
                              style={{
                                fontWeight: "700",
                                margin: "0",
                              }}
                            >
                              {props.value.txnType}
                            </p>
                          );
                        },
                      },
                      {
                        Header: "Status",
                        accessor: "txn",
                        id: "status",
                        maxWidth: 200,
                        minWidth: 100,
                        width: 200,
                        Cell: (props) => {
                          return (
                            <p
                              style={{
                                color: statusColorMap[props.value.status],
                                margin: "0",
                                fontWeight: "700",
                              }}
                            >
                              {props.value.status}
                            </p>
                          );
                        },
                      },
                      {
                        Header: "Amount",
                        accessor: "txn",
                        id: "amount",
                        maxWidth: 200,
                        minWidth: 100,
                        width: 200,
                        Cell: (props) => {
                          return (
                            <p
                              style={{
                                fontWeight: "700",
                                margin: "0",
                              }}
                            >
                              <NumberFormat
                                thousandSeparator
                                prefix="₦"
                                value={
                                  props.value.otherPrice ||
                                  props.value.totalAmount ||
                                  props.value.amount
                                }
                                decimalScale={2}
                                defaultValue="0.00"
                                allowNegative={false}
                                placeholder="0.00"
                                displayType="text"
                                disabled
                              />
                            </p>
                          );
                        },
                      },
                      {
                        Header: "Date",
                        accessor: "txn",
                        id: "date",
                        maxWidth: 200,
                        minWidth: 140,
                        width: 200,
                        Cell: (props) => {
                          return (
                            <p style={{ margin: "0" }}>
                              {new Date(props.value.createdAt).toDateString()}
                            </p>
                          );
                        },
                      },
                    ]}
                  />
                ) : (
                  <EmptyTableDataCmp title="No transaction available" />
                )}
              </>
            )}
          </TxnWrapper>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  loading: state.transaction.allTransactionLoading,
  error: state.transaction.allTransactionError,
  success: state.transaction.allTransaction,
});

const mapDispatch = (dispatch) => ({
  setGetAllTransactions: (page) => dispatch(getAllTransactions(page)),
});

export default connect(mapState, mapDispatch)(AllTransactions);
