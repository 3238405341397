import {
  ALL_CRYPTO_SETTINGS_ERROR,
  ALL_CRYPTO_SETTINGS_LOADING,
  ALL_CRYPTO_SETTINGS_SUCCESS,
  A_CRYPTO_SETTING_ERROR,
  A_CRYPTO_SETTING_LOADING,
  A_CRYPTO_SETTING_SUCCESS,
  EDIT_A_CRYPTO_SETTING_ERROR,
  EDIT_A_CRYPTO_SETTING_LOADING,
  EDIT_A_CRYPTO_SETTING_SUCCESS,
  GET_ADMIN_CRYPTO_INFO_ERROR,
  GET_ADMIN_CRYPTO_INFO_LOADING,
  GET_ADMIN_CRYPTO_INFO_SUCCESS,
} from "./types";

const initialState = {
  allCryptoLoading: null,
  allCrypto: null,
  allCryptoError: null,
  aCryptoLoading: null,
  aCrypto: null,
  aCryptoError: null,
  editACryptoLoading: null,
  editACryptoSuccess: null,
  editACryptoError: null,
  getAdminCryptoInfoLoading: null,
  getAdminCryptoInfoSuccess: null,
  getAdminCryptoInfoError: null,
};

export default function cryptoReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_CRYPTO_SETTINGS_LOADING:
      return { ...state, allCryptoLoading: action.payload };
    case ALL_CRYPTO_SETTINGS_SUCCESS:
      return { ...state, allCrypto: action.payload };
    case ALL_CRYPTO_SETTINGS_ERROR:
      return { ...state, allCryptoError: action.payload };
    case EDIT_A_CRYPTO_SETTING_LOADING:
      return { ...state, editACryptoLoading: action.payload };
    case EDIT_A_CRYPTO_SETTING_SUCCESS:
      return { ...state, editACryptoSuccess: action.payload };
    case EDIT_A_CRYPTO_SETTING_ERROR:
      return { ...state, editACryptoError: action.payload };
    case A_CRYPTO_SETTING_LOADING:
      return { ...state, aCryptoLoading: action.payload };
    case A_CRYPTO_SETTING_SUCCESS:
      return { ...state, aCrypto: action.payload };
    case A_CRYPTO_SETTING_ERROR:
      return { ...state, aCryptoError: action.payload };
    case GET_ADMIN_CRYPTO_INFO_LOADING:
      return { ...state, getAdminCryptoInfoLoading: action.payload };
    case GET_ADMIN_CRYPTO_INFO_SUCCESS:
      return { ...state, getAdminCryptoInfo: action.payload };
    case GET_ADMIN_CRYPTO_INFO_ERROR:
      return { ...state, getAdminCryptoInfoError: action.payload };
    default:
      return state;
  }
}
