import { Trash } from "@styled-icons/boxicons-regular";
import { Eye } from "@styled-icons/fa-solid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import ActionModal from "../../components/Modals/ActionModal";
import TableCmp from "../../components/TableCmp/TableCmp";
import { statusColorMap } from "../../constants/others";
import { deleteATicket, getAllTicket } from "../../redux/actions/ticketActions";
import { convertToLocalDateTime, trimText } from "../../utils/utils";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllTicketsCmp = ({
  setGetAllTicket,
  ticket,
  setDeleteATicket,
  userRole,
}) => {
  const [ticketData, setTicketData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getAllTicket = async () => {
      setLoadingState(true);
      const response = await setGetAllTicket(currentPage);

      setTicketData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllTicket();
    } else {
      getAllTicket();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const [showActionModal, setShowActionModal] = useState(false);
  const [item, setItem] = useState();

  const handleDeleteAction = () => {
    setDeleteATicket(item._id);
    setRefresh(true);
  };

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : ticketData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...ticketData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "TransactionID",
                accessor: "txnId",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#323232",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Email",
                accessor: "email",
                id: "email",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Description",
                accessor: "description",
                maxWidth: 300,
                minWidth: 180,
                width: 240,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {trimText(props.value, 20)}
                    </p>
                  );
                },
              },
              {
                Header: "Date Created",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {convertToLocalDateTime(props.value)}
                    </p>
                  );
                },
              },
              {
                Header: "Status",
                accessor: "status",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        color: statusColorMap[props.value],
                        margin: "0",
                        fontWeight: "700",
                        fontSize: "15px",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ value, row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        {["Super Admin", "Admin", "Support"].includes(
                          userRole
                        ) && (
                          <SolidButton
                            text={
                              <>
                                <Eye color="#3E4954" size={15} />
                              </>
                            }
                            type="submit"
                            weighty="500"
                            textFontSize="14px"
                            borderColor="#3E4954"
                            borderHoverColor="#3E4954"
                            onClick={() => {
                              navigate(`/help-center/tickets/${value}`);
                            }}
                            specifyPadding="0.5rem 0.3rem"
                            color="#3E4954"
                            widthWebkit="100%"
                            widthMoz="100%"
                            widthNormal="100%"
                            maxWidth="35px"
                            borderRadius="6px"
                            backColor="transparent"
                            backgroundHoverColor="transparent"
                            textHoverColor="#3E4954"
                          />
                        )}
                        {["Super Admin", "Admin"].includes(userRole) && (
                          <SolidButton
                            text={
                              <>
                                <Trash color="#d60000" size={15} />
                              </>
                            }
                            type="submit"
                            weighty="500"
                            textFontSize="14px"
                            borderColor="#D60000"
                            borderHoverColor="#D60000"
                            onClick={() => {
                              setShowActionModal(true);
                              setItem(row.values);
                            }}
                            specifyPadding="0.5rem 0.3rem"
                            color="#D60000"
                            widthWebkit="100%"
                            widthMoz="100%"
                            widthNormal="100%"
                            maxWidth="35px"
                            borderRadius="6px"
                            backColor="transparent"
                            backgroundHoverColor="transparent"
                            textHoverColor="#D60000"
                          />
                        )}
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp title="No ticket available" />
        )}
      </Wrapper>
      <ActionModal
        maxWidth="450px"
        wrapperPadding={"1rem 1rem 2rem"}
        showCloseIcon={true}
        isOpen={showActionModal}
        onRequestClose={() => {
          setShowActionModal(false);
        }}
        runAction={handleDeleteAction}
        actionOnReject={() => {
          setItem();
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.ticket.allTicketLoading,
  error: state.ticket.allTicketError,
  ticket: state.ticket.allTicket,
  userRole: state.auth.userRole,
});

const mapDispatch = (dispatch) => ({
  setGetAllTicket: (page) => dispatch(getAllTicket(page)),
  setDeleteATicket: (id) => dispatch(deleteATicket(id)),
});

export default connect(mapState, mapDispatch)(AllTicketsCmp);
