import React, { useEffect } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getCountryCallingCode } from "react-phone-number-input";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import CustomPhoneInputCmp from "../../components/InputsCmp/CustomPhoneInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import rolesOption from "../../constants/roles";
import { countryCodeSwap } from "../../constants/countries";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import CustomInputPasswordIconCmp from "../../components/InputsCmp/CustomInputPasswordIconCmp";
import createAdminSchema from "../../validators/createAdminValidator";
import { parsePhoneNumber } from "react-phone-number-input";
import { createAnAdmin } from "../../redux/actions/roleAction";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  width: 100%;
  padding: 0rem 1rem;
`;

const NewsFormWrapper = styled(Form)``;

const NewsWrapper = styled.div`
  margin: 0 auto;

  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const CreateRole = ({ setCreateAnAdmin, loading, admin, error }) => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!params?.userRole && !rolesOption[params?.userRole]) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout pageTitle={`Add ${rolesOption[params?.userRole]}`}>
      <Wrapper>
        <Formik
          initialValues={{
            fullName: "",
            email: "",
            password: "",
            role: rolesOption[params?.userRole] || "",
            phone: "",
          }}
          validationSchema={createAdminSchema}
          onSubmit={async (values, { resetForm }) => {
            // resetForm();
            const fullPhoneNo = parsePhoneNumber(
              `+${getCountryCallingCode(countryCodeSwap[`Nigeria`])}${
                values.phone
              }`
            );

            const response = await setCreateAnAdmin({
              fullName: values.fullName,
              email: values.email,
              password: values.password,
              role: values.role,
              phone: fullPhoneNo.number,
            });
            if (
              response &&
              response.status === 200 &&
              response.message === "User created successfully"
            ) {
              return navigate(`/roles/${params?.userRole}`);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                <NewsTitle>{rolesOption[params?.userRole]} Details</NewsTitle>
                <NewsWrapper>
                  <InputGroupWrapper>
                    {" "}
                    <InputWrapper>
                      <InputLabel>Full Name</InputLabel>
                      <CustomInputCmp
                        required={true}
                        values={values}
                        onChange={handleChange}
                        type="text"
                        name="fullName"
                        placeholder="Enter Full Name"
                        showMessage
                        message={`${
                          errors["fullName"] ? errors["fullName"] : ""
                        }`}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Email</InputLabel>
                      <CustomInputCmp
                        required={true}
                        values={values}
                        onChange={handleChange}
                        type="text"
                        name="email"
                        placeholder="Enter email address"
                        showMessage
                        message={`${errors["email"] ? errors["email"] : ""}`}
                      />
                    </InputWrapper>
                  </InputGroupWrapper>
                  <InputGroupWrapper>
                    <InputWrapper>
                      <InputLabel>Phone Number</InputLabel>
                      <CustomPhoneInputCmp
                        background="rgba(225, 235, 245, 0.1)"
                        required={true}
                        name="phone"
                        placeholder="Enter Phone Number"
                        values={values}
                        phoneCode={`+${getCountryCallingCode(
                          countryCodeSwap[`Nigeria`]
                        )}`}
                        flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                          countryCodeSwap[`Nigeria`]
                        }.svg`}
                        onChange={handleChange}
                        message={`${errors["phone"] ? errors["phone"] : ""}`}
                        showMessage={true}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Password</InputLabel>
                      <CustomInputPasswordIconCmp
                        background="rgba(225, 235, 245, 0.1)"
                        required={true}
                        name="password"
                        placeholder="Choose Password"
                        values={values}
                        onChange={handleChange}
                        message={`${
                          errors["password"] ? errors["password"] : ""
                        }`}
                        showMessage={true}
                      />
                    </InputWrapper>
                  </InputGroupWrapper>
                  <InputGroupWrapper>
                    <InputWrapper style={{ width: "100%" }}>
                      <InputLabel>Admin Role</InputLabel>
                      <CustomInputSelectCmp
                        background="rgba(225, 235, 245, 0.1)"
                        required={true}
                        name="role"
                        values={values}
                        onChange={handleChange}
                        placeholder="Select"
                        data={[
                          "Super Admin",
                          "Admin",
                          "Agent",
                          "Sale",
                          "Account",
                          "Support",
                        ]}
                        message={`${errors["role"] ? errors["role"] : ""}`}
                        showMessage={true}
                      />
                    </InputWrapper>
                  </InputGroupWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        loading ? (
                          <>
                            <SpinnerCmp
                              enabled={true}
                              color={appTheme.COLORS.bluePrimary}
                              size={20}
                              secondaryColor={appTheme.COLORS.white}
                            />
                          </>
                        ) : (
                          "Create Account"
                        )
                      }
                      margin="2rem 0"
                      type="submit"
                      weighty="500"
                      textFontSize="16px"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      maxWidth="200px"
                      specifyPadding="1rem 1.5rem"
                      color="#fff"
                      borderRadius="7px"
                      backColor="#016CBA"
                      backgroundHoverColor="#016CBA"
                      textHoverColor="#fff"
                      disabled={loading ? true : false}
                    />
                  </ButtonWrapper>
                </NewsWrapper>
              </NewsFormWrapper>
            </>
          )}
        </Formik>
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.role.createAnAdminLoading,
  admin: state.role.createAnAdmin,
  error: state.role.createAnAdminError,
});

const mapDispatch = (dispatch) => ({
  setCreateAnAdmin: (data) => dispatch(createAnAdmin(data)),
});

export default connect(mapState, mapDispatch)(CreateRole);
