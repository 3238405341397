import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 3rem 1rem 2rem;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
  margin: 0;
`;

const Desc = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  color: #323232;
  margin: 0;
  text-align: center;
`;

const NotFound = ({ text, titleText = "Card not found" }) => {
  return (
    <>
      <Wrapper>
        <Title>{titleText}</Title>
        <Desc>
          <span style={{ fontWeight: "bold" }}>"{text}"</span> is not available
          at the moment
        </Desc>
      </Wrapper>
    </>
  );
};

export default NotFound;
