import React, { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import CustomFileCmp from "../../components/InputsCmp/CustomFileCmp";
import CustomEditorCmp from "../../components/InputsCmp/CustomEditorCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { convertToRaw, EditorState } from "draft-js";
import { Formik } from "formik";
import createNewsSchema from "../../validators/createNewsValidator";
import { createNews } from "../../redux/actions/newsAction";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import CustomTextAreaCmp from "../../components/InputsCmp/CustomTextAreaCmp";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import CustomMultiSelectCreatable from "../../components/InputsCmp/CustomMulitSelectCreatable";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  /* max-width: 768px; */
  width: 100%;
  padding: 0rem 2rem;

  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const NewsFormWrapper = styled(Form)``;

const ImagePreviewWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
`;

const Image = styled(LazyLoadImage)`
  background-size: cover;
  width: 100%;
  height: 200px;
`;

const NewsWrapper = styled.div`
  /* max-width: 500px; */
  /* margin: 0 auto; */
  width: 100%;
  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputWrapper2 = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 100%;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const CreateNews = ({ setCreateNews, loading }) => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState();
  return (
    <DashboardLayout pageTitle="Add blog post">
      <Wrapper>
        <Formik
          initialValues={{
            editorState: EditorState.createEmpty(),
            file: null,
            title: "",
            snippet: "",
            showOnHome: "",
            tags: [],
          }}
          validationSchema={createNewsSchema}
          onSubmit={async (values, { resetForm }) => {
            const contentState = values.editorState.getCurrentContent();
            const content = JSON.stringify(convertToRaw(contentState));
            // console.log(values, content);
            // resetForm();

            const tagsArr = values.tags.map((v, i) => v.value);

            let formData = new FormData();
            formData.append("newsImage", values.file[0], values.file[0].name);
            formData.append("title", values.title);
            formData.append("content", content);
            formData.append("snippet", values.snippet);
            formData.append(
              "showOnHome",
              values.showOnHome === "Yes" ? true : false
            );

            tagsArr.forEach((item) => formData.append("tags", item));

            const response = await setCreateNews(formData);

            if (
              response &&
              response.status === 201 &&
              response.message === "News created"
            ) {
              return navigate("/help-center/blogs");
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            isValid,
            touched,
          }) => (
            <>
              <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                <NewsTitle>Blog Details</NewsTitle>
                <NewsWrapper>
                  {imagePreview && (
                    <ImagePreviewWrapper>
                      <Image src={imagePreview} alt="Preview" effect="blur" />
                    </ImagePreviewWrapper>
                  )}
                  <InputGroupWrapper>
                    <InputWrapper>
                      <InputLabel>Title</InputLabel>
                      <CustomInputCmp
                        required={true}
                        values={values}
                        onChange={handleChange}
                        type="text"
                        name="title"
                        placeholder="Enter Blog Title"
                        showMessage
                        message={`${errors["title"] ? errors["title"] : ""}`}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Tags</InputLabel>
                      <CustomMultiSelectCreatable
                        name="tags"
                        placeholder="Crypto, Giftcard"
                        isMulti={true}
                        options={[
                          { value: "giftcard", label: "giftcard" },
                          { value: "perfect money", label: "perfect money" },
                          { value: "renew", label: "renew" },
                          { value: "crypto", label: "crypto" },
                        ]}
                        message={`${errors["tags"] ? errors["tags"] : ""}`}
                        showMessage
                        value={values.tags}
                        isValid={isValid}
                        onBlur={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched}
                      />
                    </InputWrapper>
                  </InputGroupWrapper>
                  <InputGroupWrapper>
                    <InputWrapper>
                      <InputLabel>Upload Image</InputLabel>
                      <CustomFileCmp
                        maxFiles={1}
                        maxSize={1000000}
                        accept={{
                          "image/png": [".png"],
                          "image/jpeg": [".jpg", ".jpeg"],
                          "image/svg+xml": [".svg"],
                        }}
                        customFn={(blob) => {
                          setImagePreview(blob);
                        }}
                        type="text"
                        placeholder="Click to upload file"
                        values={values}
                        setFieldValue={setFieldValue}
                        name="file"
                        showMessage
                        message={`${errors["file"] ? errors["file"] : ""}`}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Snippet</InputLabel>
                      <CustomTextAreaCmp
                        background="#fff"
                        as="textarea"
                        rows={1}
                        required={true}
                        onChange={handleChange}
                        values={values}
                        type="text"
                        name="snippet"
                        placeholder="Enter Blog Snippet"
                        showMessage
                        message={`${
                          errors["snippet"] ? errors["snippet"] : ""
                        }`}
                      />
                    </InputWrapper>
                  </InputGroupWrapper>
                  <InputGroupWrapper>
                    <InputWrapper2>
                      <InputLabel>Content</InputLabel>
                      <CustomEditorCmp
                        placeholder="Enter Blog Content"
                        editorState={values.editorState}
                        setFieldValue={setFieldValue}
                        spellCheck={false}
                        showMessage
                        message={`${
                          errors["editorState"] ? errors["editorState"] : ""
                        }`}
                      />
                    </InputWrapper2>
                  </InputGroupWrapper>
                  <InputGroupWrapper>
                    <InputWrapper2>
                      <InputLabel>Show on home page</InputLabel>
                      <CustomInputSelectCmp
                        background="rgba(225, 235, 245, 0.1)"
                        required={true}
                        name="showOnHome"
                        values={values}
                        onChange={handleChange}
                        placeholder="Select"
                        data={["Yes", "No"]}
                        message={`${
                          errors["showOnHome"] ? errors["showOnHome"] : ""
                        }`}
                        showMessage={true}
                      />
                    </InputWrapper2>
                  </InputGroupWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        loading ? (
                          <>
                            <SpinnerCmp
                              enabled={true}
                              color={appTheme.COLORS.bluePrimary}
                              size={20}
                              secondaryColor={appTheme.COLORS.white}
                            />
                          </>
                        ) : (
                          "Create"
                        )
                      }
                      margin="2rem 0"
                      type="submit"
                      weighty="500"
                      textFontSize="16px"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      maxWidth="200px"
                      specifyPadding="1rem 1.5rem"
                      color="#fff"
                      borderRadius="7px"
                      backColor="#016CBA"
                      backgroundHoverColor="#016CBA"
                      textHoverColor="#fff"
                      disabled={loading ? true : false}
                    />
                  </ButtonWrapper>
                </NewsWrapper>
              </NewsFormWrapper>
            </>
          )}
        </Formik>
        {/* <RenderEditorCmp /> */}
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.news.createNewsLoading,
});

const mapDispatch = (dispatch) => ({
  setCreateNews: (data) => dispatch(createNews(data)),
});

export default connect(mapState, mapDispatch)(CreateNews);
