import React, { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import { useEffect } from "react";
import { editAPmSetting, getAPmSetting } from "../../redux/actions/pmActions";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import editPmSettingsSchema from "../../validators/editPmSettingsValidator";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  /* max-width: 768px; */
  width: 100%;
  padding: 0rem 2rem;

  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const NewsFormWrapper = styled(Form)``;

const NewsWrapper = styled.div`
  /* max-width: 500px; */
  /* margin: 0 auto; */
  width: 100%;
  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const EditPerfectMoneySettings = ({
  loading,
  setEditPmSetting,
  setGetAPmSetting,
  aPmLoading,
  aPm,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [aPmData, setAPmData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getANews = async () => {
      const response = await setGetAPmSetting(id);

      setAPmData(response.data);
      setRefresh(false);
    };
    if (refresh) {
      getANews();
    } else {
      getANews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <DashboardLayout pageTitle="Edit Perfect Money">
      {aPm && (
        <Wrapper>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: aPmData?.name,
              accountNumber: aPmData?.accountNumber,
              nairaBuyingRate: aPmData?.nairaBuyingRate,
              nairaSellingRate: aPmData?.nairaSellingRate,
              iconUrl: aPmData?.iconUrl,
            }}
            validationSchema={editPmSettingsSchema}
            onSubmit={async (values, { resetForm }) => {
              // console.log(values, content);
              // resetForm();

              const response = await setEditPmSetting({ ...values }, id);

              if (
                response &&
                response.status === 200 &&
                response.message ===
                  "Perfect money setting updated successfully"
              ) {
                return navigate("/perfect-money/settings");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              isValid,
              touched,
            }) => (
              <>
                <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                  <NewsTitle>Perfect money Details</NewsTitle>
                  <NewsWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Name of PM</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="name"
                          placeholder="Enter name"
                          showMessage
                          message={`${errors["name"] ? errors["name"] : ""}`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="accountNumber"
                          placeholder="Enter account number"
                          showMessage
                          message={`${
                            errors["accountNumber"]
                              ? errors["accountNumber"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Buying Rate (₦)</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="nairaBuyingRate"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["nairaBuyingRate"]
                              ? errors["nairaBuyingRate"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Selling Rate (₦)</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="nairaSellingRate"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["nairaSellingRate"]
                              ? errors["nairaSellingRate"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <ButtonWrapper>
                      <SolidButton
                        text={
                          loading ? (
                            <>
                              <SpinnerCmp
                                enabled={true}
                                color={appTheme.COLORS.bluePrimary}
                                size={20}
                                secondaryColor={appTheme.COLORS.white}
                              />
                            </>
                          ) : (
                            "Update"
                          )
                        }
                        margin="2rem 0"
                        type="submit"
                        weighty="500"
                        textFontSize="16px"
                        widthWebkit="100%"
                        widthMoz="100%"
                        widthNormal="100%"
                        maxWidth="200px"
                        specifyPadding="1rem 1.5rem"
                        color="#fff"
                        borderRadius="7px"
                        backColor="#016CBA"
                        backgroundHoverColor="#016CBA"
                        textHoverColor="#fff"
                        disabled={loading ? true : false}
                      />
                    </ButtonWrapper>
                  </NewsWrapper>
                </NewsFormWrapper>
              </>
            )}
          </Formik>
        </Wrapper>
      )}
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.pm.editAPmLoading,
  aPmLoading: state.pm.aPmLoading,
  aPm: state.pm.aPm,
});

const mapDispatch = (dispatch) => ({
  setEditPmSetting: (data, id) => dispatch(editAPmSetting(data, id)),
  setGetAPmSetting: (id) => dispatch(getAPmSetting(id)),
});

export default connect(mapState, mapDispatch)(EditPerfectMoneySettings);
