import { Pencil } from "@styled-icons/boxicons-regular";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import TableCmp from "../../components/TableCmp/TableCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { getAllPmSettings } from "../../redux/actions/pmActions";
import NumberFormat from "react-number-format";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const WrapperImageText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BlogImgWrapper = styled(LazyLoadImage)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllPerfectMoneyCmp = ({ setGetAllPmSettings }) => {
  const [settingsData, setSettingsData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAllSettings = async () => {
      setLoadingState(true);
      const response = await setGetAllPmSettings();

      setSettingsData(response.data);
      setDataCount(response.data.length);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllSettings();
    } else {
      getAllSettings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : settingsData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...settingsData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "PM",
                accessor: "name",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <WrapperImageText>
                      <BlogImgWrapper
                        src={props.row.original.iconUrl}
                        alt={props.row.original.name}
                        effect="blur"
                      />
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#3E4954",
                        }}
                      >
                        {props.value}
                      </p>
                    </WrapperImageText>
                  );
                },
              },
              {
                Header: "Account No.",
                accessor: "accountNumber",
                id: "desc",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Buying Rate (₦)",
                accessor: "nairaBuyingRate",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={props.value}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      }
                    </p>
                  );
                },
              },
              {
                Header: "Selling Rate (₦)",
                accessor: "nairaSellingRate",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={props.value}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      }
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        <SolidButton
                          text={
                            <>
                              <Pencil color="#2a9bf2" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#2a9bf2"
                          borderHoverColor="#2a9bf2"
                          onClick={() => {
                            navigate(
                              `/perfect-money/settings/edit/${row.values._id}`
                            );
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#2a9bf2"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#2a9bf2"
                        />
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No settings added yet"
            desc="Perfect money settings will be here"
          />
        )}
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  loading: state.pm.allPmLoading,
  error: state.pm.allPmError,
  news: state.pm.allPm,
});

const mapDispatch = (dispatch) => ({
  setGetAllPmSettings: () => dispatch(getAllPmSettings()),
});

export default connect(mapState, mapDispatch)(AllPerfectMoneyCmp);
