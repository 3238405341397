import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllPerfectMoney from "./AllPerfectMoney";

const Wrapper = styled.div``;

const PerfectMoney = () => {
  return (
    <>
      <DashboardLayout pageTitle="Perfect Money">
        <Wrapper>
          <AllPerfectMoney />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default PerfectMoney;
