import React, { useState } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const TableActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionWrapper = styled.div`
  width: 100px;
  height: 50px;
  background-color: ${(props) => (props.currentAction ? "#a9dbff" : "#F1F1F1")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ActionText = styled.p`
  color: ${(props) => (props.currentAction ? "#016cba" : "#AAAAAA")};
  font-weight: ${appTheme.WEIGHT.medium};
  font-size: 12px;
  margin: 0;
`;

const TableActionsCmp = (props) => {
  const [currentAction, setCurrentAction] = useState("Save to csv");

  const handleCurrentActionClick = (text) => {
    return setCurrentAction(text);
  };
  return (
    <>
      <TableActionsWrapper>
        <ActionWrapper
          onClick={() => {
            handleCurrentActionClick("Save to csv");
            props.exportData("csv", false);
          }}
          currentAction={currentAction === "Save to csv" ? true : false}
        >
          <ActionText
            currentAction={currentAction === "Save to csv" ? true : false}
          >
            Save to CSV
          </ActionText>
        </ActionWrapper>
        {/* <ActionWrapper
          onClick={() => {
            handleCurrentActionClick("Copy");
          }}
          currentAction={currentAction === "Copy" ? true : false}
        >
          <ActionText currentAction={currentAction === "Copy" ? true : false}>
            Copy
          </ActionText>
        </ActionWrapper> */}
        <ActionWrapper
          onClick={() => {
            handleCurrentActionClick("Print");
            props.exportData("pdf", false);
          }}
          currentAction={currentAction === "Print" ? true : false}
        >
          <ActionText currentAction={currentAction === "Print" ? true : false}>
            Print
          </ActionText>
        </ActionWrapper>
      </TableActionsWrapper>
    </>
  );
};

export default TableActionsCmp;
