import {
  ALL_TICKETS_ERROR,
  ALL_TICKETS_LOADING,
  ALL_TICKETS_SUCCESS,
  A_TICKET_ERROR,
  A_TICKET_LOADING,
  A_TICKET_SUCCESS,
  DELETE_TICKET_ERROR,
  DELETE_TICKET_LOADING,
  VIEW_TICKET_ERROR,
  VIEW_TICKET_LOADING,
  VIEW_TICKET_SUCCESS,
  UPDATE_TICKET_STATUS_ERROR,
  UPDATE_TICKET_STATUS_LOADING,
  UPDATE_TICKET_STATUS_SUCCESS,
} from "./types";

const initialState = {
  allTicketsLoading: null,
  allTickets: null,
  allTicketsError: null,
  aTicketLoading: null,
  aTicket: null,
  aTicketError: null,
  deleteTicketLoading: null,
  deleteTicketError: null,
  viewTicketLoading: null,
  viewTicketSuccess: null,
  viewTicketError: null,
  updateTicketStatusLoading: null,
  updateTicketStatusSuccess: null,
  updateTicketStatusError: null,
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_TICKETS_LOADING:
      return { ...state, allTicketsLoading: action.payload };
    case ALL_TICKETS_SUCCESS:
      return { ...state, allTickets: action.payload };
    case ALL_TICKETS_ERROR:
      return { ...state, allTicketsError: action.payload };
    case A_TICKET_LOADING:
      return { ...state, aTicketLoading: action.payload };
    case A_TICKET_SUCCESS:
      return { ...state, aTicket: action.payload };
    case A_TICKET_ERROR:
      return { ...state, aTicketError: action.payload };
    case DELETE_TICKET_LOADING:
      return { ...state, deleteTicketLoading: action.payload };
    case DELETE_TICKET_ERROR:
      return { ...state, deleteTicketError: action.payload };
    case VIEW_TICKET_LOADING:
      return { ...state, viewTicketLoading: action.payload };
    case VIEW_TICKET_SUCCESS:
      return { ...state, viewTicketSuccess: action.payload };
    case VIEW_TICKET_ERROR:
      return { ...state, viewTicketError: action.payload };
    case UPDATE_TICKET_STATUS_LOADING:
      return { ...state, updateTicketStatusLoading: action.payload };
    case UPDATE_TICKET_STATUS_SUCCESS:
      return { ...state, updateTicketStatusSuccess: action.payload };
    case UPDATE_TICKET_STATUS_ERROR:
      return { ...state, updateTicketStatusError: action.payload };
    default:
      return state;
  }
}
