/* eslint-disable no-useless-escape */
import * as yup from "yup";

const createAdSchema = yup.object().shape({
  file: yup
    .mixed()
    .required("Please add an image")
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value[0]?.size) {
        return value[0].size <= 500000;
      }
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value[0]?.type) {
        if (
          ["image/jpg", "image/jpeg", "image/gif", "image/png"].includes(
            value[0].type
          )
        ) {
          return true;
        }
      }
    }),
  link: yup.string().required("Please add link"),
  showOn: yup.string().required("Select an option"),
});

export default createAdSchema;
