import {
  GET_ALL_GIFTCARD_BUY_ERROR,
  GET_ALL_GIFTCARD_BUY_LOADING,
  GET_ALL_GIFTCARD_BUY_SUCCESS,
  GET_A_GIFTCARD_BUY_ERROR,
  GET_A_GIFTCARD_BUY_LOADING,
  GET_A_GIFTCARD_BUY_SUCCESS,
  EDIT_A_GIFTCARD_BUY_ERROR,
  EDIT_A_GIFTCARD_BUY_LOADING,
  EDIT_A_GIFTCARD_BUY_SUCCESS,
  HIDE_A_GIFTCARD_BUY_LOADING,
  HIDE_A_GIFTCARD_BUY_SUCCESS,
  HIDE_A_GIFTCARD_BUY_ERROR,
  GET_ALL_GIFTCARD_SELL_LOADING,
  GET_ALL_GIFTCARD_SELL_SUCCESS,
  GET_ALL_GIFTCARD_SELL_ERROR,
  GET_A_GIFTCARD_SELL_LOADING,
  GET_A_GIFTCARD_SELL_SUCCESS,
  GET_A_GIFTCARD_SELL_ERROR,
  EDIT_A_GIFTCARD_SELL_LOADING,
  EDIT_A_GIFTCARD_SELL_SUCCESS,
  EDIT_A_GIFTCARD_SELL_ERROR,
  HIDE_A_GIFTCARD_SELL_LOADING,
  HIDE_A_GIFTCARD_SELL_SUCCESS,
  HIDE_A_GIFTCARD_SELL_ERROR,
  GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING,
  GET_ALL_PENDING_GIFTCARD_SELL_TXN_SUCCESS,
  GET_ALL_PENDING_GIFTCARD_SELL_TXN_ERROR,
  GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING,
  GET_A_PENDING_GIFTCARD_SELL_TXN_SUCCESS,
  GET_A_PENDING_GIFTCARD_SELL_TXN_ERROR,
  A_PENDING_GIFTCARD_SELL_ACTION_LOADING,
  A_PENDING_GIFTCARD_SELL_ACTION_SUCCESS,
  A_PENDING_GIFTCARD_SELL_ACTION_ERROR,
} from "./types";

const initialState = {
  allGiftcardBuyLoading: null,
  allGiftcardBuy: null,
  allGiftcardBuyError: null,
  aGiftcardBuyLoading: null,
  aGiftcardBuySuccess: null,
  aGiftcardBuyError: null,
  hideGiftcardBuyLoading: null,
  hideGiftcardBuySuccess: null,
  hideGiftcardBuyError: null,
  editGiftcardBuyLoading: null,
  editGiftcardBuySuccess: null,
  editGiftcardBuyError: null,
  allGiftcardSellLoading: null,
  allGiftcardSell: null,
  allGiftcardSellError: null,
  aGiftcardSellLoading: null,
  aGiftcardSellSuccess: null,
  aGiftcardSellError: null,
  hideGiftcardSellLoading: null,
  hideGiftcardSellSuccess: null,
  hideGiftcardSellError: null,
  editGiftcardSellLoading: null,
  editGiftcardSellSuccess: null,
  editGiftcardSellError: null,
  allPendingGiftcardSellTxnLoading: null,
  allPendingGiftcardSellTxn: null,
  allPendingGiftcardSellTxnError: null,
  aPendingGiftcardSellTxnLoading: null,
  aPendingGiftcardSellTxn: null,
  aPendingGiftcardSellTxnError: null,
  aPendingGiftcardSellActionLoading: null,
  aPendingGiftcardSellAction: null,
  aPendingGiftcardSellActionError: null,
};

export default function giftcardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GIFTCARD_BUY_LOADING:
      return { ...state, allGiftcardBuyLoading: action.payload };
    case GET_ALL_GIFTCARD_BUY_SUCCESS:
      return { ...state, allGiftcardBuy: action.payload };
    case GET_ALL_GIFTCARD_BUY_ERROR:
      return { ...state, allGiftcardBuyError: action.payload };
    case GET_A_GIFTCARD_BUY_LOADING:
      return { ...state, aGiftcardBuyLoading: action.payload };
    case GET_A_GIFTCARD_BUY_SUCCESS:
      return { ...state, aGiftcardBuy: action.payload };
    case GET_A_GIFTCARD_BUY_ERROR:
      return { ...state, aGiftcardBuyError: action.payload };
    case EDIT_A_GIFTCARD_BUY_LOADING:
      return { ...state, editGiftcardBuyLoading: action.payload };
    case EDIT_A_GIFTCARD_BUY_SUCCESS:
      return { ...state, editGiftcardBuySuccess: action.payload };
    case EDIT_A_GIFTCARD_BUY_ERROR:
      return { ...state, editGiftcardBuyError: action.payload };
    case HIDE_A_GIFTCARD_BUY_LOADING:
      return { ...state, hideGiftcardBuyLoading: action.payload };
    case HIDE_A_GIFTCARD_BUY_SUCCESS:
      return { ...state, hideGiftcardBuySuccess: action.payload };
    case HIDE_A_GIFTCARD_BUY_ERROR:
      return { ...state, hideGiftcardBuyError: action.payload };
    case GET_ALL_GIFTCARD_SELL_LOADING:
      return { ...state, allGiftcardSellLoading: action.payload };
    case GET_ALL_GIFTCARD_SELL_SUCCESS:
      return { ...state, allGiftcardSell: action.payload };
    case GET_ALL_GIFTCARD_SELL_ERROR:
      return { ...state, allGiftcardSellError: action.payload };
    case GET_A_GIFTCARD_SELL_LOADING:
      return { ...state, aGiftcardSellLoading: action.payload };
    case GET_A_GIFTCARD_SELL_SUCCESS:
      return { ...state, aGiftcardSell: action.payload };
    case GET_A_GIFTCARD_SELL_ERROR:
      return { ...state, aGiftcardSellError: action.payload };
    case EDIT_A_GIFTCARD_SELL_LOADING:
      return { ...state, editGiftcardSellLoading: action.payload };
    case EDIT_A_GIFTCARD_SELL_SUCCESS:
      return { ...state, editGiftcardSellSuccess: action.payload };
    case EDIT_A_GIFTCARD_SELL_ERROR:
      return { ...state, editGiftcardSellError: action.payload };
    case HIDE_A_GIFTCARD_SELL_LOADING:
      return { ...state, hideGiftcardSellLoading: action.payload };
    case HIDE_A_GIFTCARD_SELL_SUCCESS:
      return { ...state, hideGiftcardSellSuccess: action.payload };
    case HIDE_A_GIFTCARD_SELL_ERROR:
      return { ...state, hideGiftcardSellError: action.payload };
    case GET_ALL_PENDING_GIFTCARD_SELL_TXN_LOADING:
      return { ...state, allPendingGiftcardSellTxnLoading: action.payload };
    case GET_ALL_PENDING_GIFTCARD_SELL_TXN_SUCCESS:
      return { ...state, allPendingGiftcardSellTxn: action.payload };
    case GET_ALL_PENDING_GIFTCARD_SELL_TXN_ERROR:
      return { ...state, allPendingGiftcardSellTxnError: action.payload };
    case GET_A_PENDING_GIFTCARD_SELL_TXN_LOADING:
      return { ...state, aPendingGiftcardSellTxnLoading: action.payload };
    case GET_A_PENDING_GIFTCARD_SELL_TXN_SUCCESS:
      return { ...state, aPendingGiftcardSellTxn: action.payload };
    case GET_A_PENDING_GIFTCARD_SELL_TXN_ERROR:
      return { ...state, aPendingGiftcardSellTxnError: action.payload };
    case A_PENDING_GIFTCARD_SELL_ACTION_LOADING:
      return { ...state, aPendingGiftcardSellActionLoading: action.payload };
    case A_PENDING_GIFTCARD_SELL_ACTION_SUCCESS:
      return { ...state, aPendingGiftcardSellAction: action.payload };
    case A_PENDING_GIFTCARD_SELL_ACTION_ERROR:
      return { ...state, aPendingGiftcardSellActionError: action.payload };
    default:
      return state;
  }
}
