import Dashboard from "../../assets/dashboard.svg";
import DashboardHover from "../../assets/dashboard-hover.svg";
import Help from "../../assets/help.svg";
import HelpHover from "../../assets/help-hover.svg";
import News from "../../assets/news.svg";
import NewsHover from "../../assets/news-hover.svg";
import OTC from "../../assets/otc.svg";
import OTCHover from "../../assets/otc-hover.svg";
import Careers from "../../assets/careers.svg";
import CareersHover from "../../assets/careers-hover.svg";
import Naira from "../../assets/naira.svg";
import NairaHover from "../../assets/naira-hover.svg";
import Giftcards from "../../assets/giftcards.svg";
import GiftcardsHover from "../../assets/giftcards-hover.svg";
import Crypto from "../../assets/crypto.svg";
import CryptoHover from "../../assets/crypto-hover.svg";
import PM from "../../assets/pm.svg";
import PMHover from "../../assets/pm-hover.svg";
import Transaction from "../../assets/transaction.svg";
import TransactionHover from "../../assets/transaction-hover.svg";
// import Renew from "../../assets/renew.svg";
// import RenewHover from "../../assets/renew-hover.svg";
import User from "../../assets/user.svg";
import UserHover from "../../assets/user-hover.svg";
import Support from "../../assets/support.svg";
import SupportHover from "../../assets/support-hover.svg";
import Sales from "../../assets/sales.svg";
import SalesHover from "../../assets/sales-hover.svg";
import Agents from "../../assets/agents.svg";
import AgentsHover from "../../assets/agents-hover.svg";
import Account from "../../assets/account.svg";
import AccountHover from "../../assets/account-hover.svg";

const superAdminSidebarData = [
  {
    sectionTitle: "Main",
    pages: [
      {
        title: "Dashboard",
        link: "/dashboard",
        submenu: false,
        icons: [`${Dashboard}`, `${DashboardHover}`],
      },
      {
        title: "News and Updates",
        link: "/news-and-updates",
        submenu: false,
        icons: [`${News}`, `${NewsHover}`],
      },
      {
        title: "In-App Ads",
        link: "/in-app-ads",
        submenu: false,
        icons: [`${News}`, `${NewsHover}`],
      },
      {
        title: "Help Center",
        submenu: true,
        icons: [`${Help}`, `${HelpHover}`],
        path: "/help-center",
        children: [
          {
            title: "Tickets",
            path: "/help-center/tickets",
          },
          {
            title: "Blogs",
            path: "/help-center/blogs",
          },
          {
            title: "FAQs",
            path: "/help-center/faqs",
          },
        ],
      },
      {
        title: "OTC",
        link: "/otc",
        submenu: false,
        icons: [`${OTC}`, `${OTCHover}`],
      },
      {
        title: "Careers",
        link: "/careers",
        submenu: false,
        icons: [`${Careers}`, `${CareersHover}`],
      },
    ],
  },
  {
    sectionTitle: "Services",
    pages: [
      {
        title: "Naira wallet",
        path: "/naira-wallet",
        submenu: true,
        icons: [`${Naira}`, `${NairaHover}`],
        children: [
          {
            title: "Transaction",
            path: "/naira-wallet/transactions",
          },
          {
            title: "Settings",
            path: "/naira-wallet/settings",
          },
        ],
      },
      {
        title: "Giftcards",
        submenu: true,
        icons: [`${Giftcards}`, `${GiftcardsHover}`],
        children: [
          {
            title: "Sell giftcards",
            path: "/giftcards/sell",
          },
          {
            title: "Buy giftcards",
            path: "/giftcards/buy",
          },
          {
            title: "Pending Txns",
            path: "/giftcards/pending/transactions",
          },
        ],
      },
      {
        title: "Crypto",
        submenu: true,
        icons: [`${Crypto}`, `${CryptoHover}`],
        children: [
          {
            title: "Settings",
            path: "/crypto/settings",
          },
        ],
      },
      {
        title: "Perfect Money",
        submenu: true,
        children: [
          {
            title: "Settings",
            path: "/perfect-money/settings",
          },
        ],
        icons: [`${PM}`, `${PMHover}`],
      },
      // {
      //   title: "Renew",
      //   link: "/renew",
      //   submenu: false,
      //   icons: [`${Renew}`, `${RenewHover}`],
      // },
      {
        title: "Transactions",
        submenu: true,
        children: [
          {
            title: "All Transactions",
            path: "/transactions/all",
          },
          {
            title: "Pending Transaction",
            path: "/transactions/pending",
          },
          {
            title: "Succesful Transactions",
            path: "/transactions/success",
          },
          {
            title: "Failed Transactions",
            path: "/transactions/failed",
          },
        ],
        icons: [`${Transaction}`, `${TransactionHover}`],
      },
    ],
  },
  {
    sectionTitle: "Role Management",
    pages: [
      {
        title: "User",
        link: "/users",
        submenu: false,
        icons: [`${User}`, `${UserHover}`],
      },
      {
        title: "Support",
        link: "/roles/support",
        submenu: false,
        icons: [`${Support}`, `${SupportHover}`],
      },
      {
        title: "Sale",
        link: "/roles/sale",
        submenu: false,
        icons: [`${Sales}`, `${SalesHover}`],
      },
      {
        title: "Agent",
        link: "/roles/agent",
        submenu: false,
        icons: [`${Agents}`, `${AgentsHover}`],
      },
      {
        title: "Account",
        link: "/roles/account",
        submenu: false,
        icons: [`${Account}`, `${AccountHover}`],
      },
      {
        title: "Admin",
        link: "/roles/admin",
        submenu: false,
        icons: [`${User}`, `${UserHover}`],
      },
      {
        title: "Super Admin",
        link: "/roles/super-admin",
        submenu: false,
        icons: [`${User}`, `${UserHover}`],
      },
    ],
  },
];

const adminSidebarData = [
  {
    sectionTitle: "Main",
    pages: [
      {
        title: "Dashboard",
        link: "/dashboard",
        submenu: false,
        icons: [`${Dashboard}`, `${DashboardHover}`],
      },
      {
        title: "News and Updates",
        link: "/news-and-updates",
        submenu: false,
        icons: [`${News}`, `${NewsHover}`],
      },
      {
        title: "In-App Ads",
        link: "/in-app-ads",
        submenu: false,
        icons: [`${News}`, `${NewsHover}`],
      },
      {
        title: "Help Center",
        submenu: true,
        icons: [`${Help}`, `${HelpHover}`],
        path: "/help-center",
        children: [
          {
            title: "Tickets",
            path: "/help-center/tickets",
          },
          {
            title: "Blogs",
            path: "/help-center/blogs",
          },
          {
            title: "FAQs",
            path: "/help-center/faqs",
          },
        ],
      },
      {
        title: "OTC",
        link: "/otc",
        submenu: false,
        icons: [`${OTC}`, `${OTCHover}`],
      },
      {
        title: "Careers",
        link: "/careers",
        submenu: false,
        icons: [`${Careers}`, `${CareersHover}`],
      },
    ],
  },
  {
    sectionTitle: "Services",
    pages: [
      {
        title: "Naira wallet",
        path: "/naira-wallet",
        submenu: true,
        icons: [`${Naira}`, `${NairaHover}`],
        children: [
          {
            title: "Transaction",
            path: "/naira-wallet/transactions",
          },
        ],
      },
      {
        title: "Giftcards",
        submenu: true,
        icons: [`${Giftcards}`, `${GiftcardsHover}`],
        children: [
          {
            title: "Pending Txns",
            path: "/giftcards/pending/transactions",
          },
        ],
      },
      {
        title: "Crypto",
        submenu: true,
        icons: [`${Crypto}`, `${CryptoHover}`],
        children: [
          // {
          //   title: "Settings",
          //   path: "/crypto/settings",
          // },
        ],
      },
      {
        title: "Perfect Money",
        submenu: true,
        children: [
          // {
          //   title: "Settings",
          //   path: "/perfect-money/settings",
          // },
        ],
        icons: [`${PM}`, `${PMHover}`],
      },
      // {
      //   title: "Renew",
      //   link: "/renew",
      //   submenu: false,
      //   icons: [`${Renew}`, `${RenewHover}`],
      // },
      {
        title: "Transactions",
        submenu: true,
        children: [
          {
            title: "All Transactions",
            path: "/transactions/all",
          },
          {
            title: "Pending Transaction",
            path: "/transactions/pending",
          },
          {
            title: "Succesful Transactions",
            path: "/transactions/success",
          },
          {
            title: "Failed Transactions",
            path: "/transactions/failed",
          },
        ],
        icons: [`${Transaction}`, `${TransactionHover}`],
      },
    ],
  },
  {
    sectionTitle: "Role Management",
    pages: [
      {
        title: "User",
        link: "/users",
        submenu: false,
        icons: [`${User}`, `${UserHover}`],
      },
      {
        title: "Support",
        link: "/roles/support",
        submenu: false,
        icons: [`${Support}`, `${SupportHover}`],
      },
      {
        title: "Sale",
        link: "/roles/sale",
        submenu: false,
        icons: [`${Sales}`, `${SalesHover}`],
      },
      {
        title: "Agent",
        link: "/roles/agent",
        submenu: false,
        icons: [`${Agents}`, `${AgentsHover}`],
      },
      {
        title: "Account",
        link: "/roles/account",
        submenu: false,
        icons: [`${Account}`, `${AccountHover}`],
      },
    ],
  },
];

const supportSidebarData = [
  {
    sectionTitle: "Main",
    pages: [
      {
        title: "Dashboard",
        link: "/dashboard",
        submenu: false,
        icons: [`${Dashboard}`, `${DashboardHover}`],
      },
      {
        title: "Help Center",
        submenu: true,
        icons: [`${Help}`, `${HelpHover}`],
        children: [
          {
            title: "Tickets",
            path: "/help-center/tickets",
          },
          {
            title: "Blogs",
            path: "/help-center/blogs",
          },
          {
            title: "FAQs",
            path: "/help-center/faqs",
          },
        ],
      },
      {
        title: "Transactions",
        submenu: true,
        children: [
          {
            title: "All Transactions",
            path: "/transactions/all",
          },
          {
            title: "Pending Transaction",
            path: "/transactions/pending",
          },
          {
            title: "Succesful Transactions",
            path: "/transactions/success",
          },
          {
            title: "Failed Transactions",
            path: "/transactions/failed",
          },
        ],
        icons: [`${Transaction}`, `${TransactionHover}`],
      },
    ],
  },
];

const saleSidebarData = [
  {
    sectionTitle: "Main",
    pages: [
      {
        title: "Dashboard",
        link: "/dashboard",
        submenu: false,
        icons: [`${Dashboard}`, `${DashboardHover}`],
      },
      {
        title: "OTC",
        link: "/otc",
        submenu: false,
        icons: [`${OTC}`, `${OTCHover}`],
      },
    ],
  },
];

const agentSidebarData = [
  {
    sectionTitle: "Main",
    pages: [
      {
        title: "Dashboard",
        link: "/dashboard",
        submenu: false,
        icons: [`${Dashboard}`, `${DashboardHover}`],
      },
      {
        title: "Transactions",
        submenu: true,
        children: [
          {
            title: "All Transactions",
            path: "/transactions/all",
          },
          {
            title: "Pending Transaction",
            path: "/transactions/pending",
          },
          {
            title: "Succesful Transactions",
            path: "/transactions/success",
          },
          {
            title: "Failed Transactions",
            path: "/transactions/failed",
          },
        ],
        icons: [`${Transaction}`, `${TransactionHover}`],
      },
    ],
  },
];

const accountSidebarData = [
  {
    sectionTitle: "Main",
    pages: [
      {
        title: "Dashboard",
        link: "/dashboard",
        submenu: false,
        icons: [`${Dashboard}`, `${DashboardHover}`],
      },
      {
        title: "Naira wallet",
        path: "/naira-wallet",
        submenu: true,
        icons: [`${Naira}`, `${NairaHover}`],
        children: [
          {
            title: "Transaction",
            path: "/naira-wallet/transactions",
          },
        ],
      },
    ],
  },
];

export {
  superAdminSidebarData,
  adminSidebarData,
  supportSidebarData,
  saleSidebarData,
  agentSidebarData,
  accountSidebarData,
};
