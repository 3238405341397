import React from "react";
import styled from "styled-components";
import ModalCmp from "../ModalCmp/ModalCmp";
import appTheme from "../../constants/theme";
import SolidButton from "../ButtonCmp/Button";

const Wrapper = styled.div`
  text-align: center;
  padding: ${(props) =>
    props.wrapperPadding ? props.wrapperPadding : "2rem 1rem"};
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  padding-bottom: 0.8px;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const ActionModal = (props) => {
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={() => {
          props.onRequestClose();
          props.actionOnReject();
        }}
        contentStyles={{ maxWidth: props.maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Action"
        id="action"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={props.showCloseIcon}
      >
        <Wrapper wrapperPadding={props.wrapperPadding}>
          <Title>Are you sure you want to perform this action?</Title>
          <ButtonWrapper>
            <SolidButton
              onClick={() => {
                props.runAction();
                props.onRequestClose();
              }}
              text={"Yes"}
              type="submit"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#fff"
              widthWebkit={props.actionButtonWidth || "80px"}
              widthMoz={props.actionButtonWidth || "80px"}
              widthNormal={props.actionButtonWidth || "80px"}
              borderRadius="8px"
              backColor="#1a8fdd"
              backgroundHoverColor="#1a8fdd"
              textHoverColor="#fff"
            />
            <SolidButton
              onClick={() => {
                props.onRequestClose();
                props.actionOnReject();
              }}
              text={"No"}
              type="submit"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#1a8fdd"
              widthWebkit={props.actionButtonWidth || "80px"}
              widthMoz={props.actionButtonWidth || "80px"}
              widthNormal={props.actionButtonWidth || "80px"}
              borderRadius="8px"
              backColor="transparent"
              backgroundHoverColor="transparent"
              textHoverColor="#1a8fdd"
              borderColor="#1a8fdd"
              borderHoverColor="#1a8fdd"
            />
          </ButtonWrapper>
        </Wrapper>
      </ModalCmp>
    </>
  );
};

export default ActionModal;
