import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import { editOtc, getAOtc } from "../../redux/actions/otcActions";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 550px;
  width: 100%;
  padding: 0rem 1rem;

  @media only screen and (max-width: 700px) {
    max-width: unset;
  }
`;

const ParentWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const OtcFormWrapper = styled(Form)``;

const OtcWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const OtcTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const OtcTitle2 = styled(OtcTitle)`
  padding: 1rem 0;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const EditOtc = ({ setEditOtc, loading, setGetAOtc, aOtcLoading, aOtc }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [aOtcData, setAOtcData] = useState({});
  const [refresh, setRefresh] = useState(false);

  const btnClick = async (text) => {
    const response = await setEditOtc({ status: text }, id);

    if (
      response &&
      response.status === 201 &&
      response.message === "Otc updated"
    ) {
      return navigate("/otc");
    }
  };

  useEffect(() => {
    const getAOtc = async () => {
      const response = await setGetAOtc(id);

      setAOtcData(response.data);
      setRefresh(false);
    };
    if (refresh) {
      getAOtc();
    } else {
      getAOtc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <DashboardLayout pageTitle="View Otc">
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: aOtcData.name,
          email: aOtcData.email,
          country: aOtcData.country,
          phone: aOtcData.phone,
          cryptoType: aOtcData.cryptoType,
          walletAddress: aOtcData.walletAddress,
          transactionAmount: aOtcData.transactionAmount,
          bankName: aOtcData.bankName,
          accountName: aOtcData.accountName,
          accountNumber: aOtcData.accountNumber,
        }}
        // validationSchema={createOtcSchema}
        onSubmit={async (values, { resetForm }) => {
          // console.log(values, content);
          // resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue,
          setFieldTouched,
          isValid,
          touched,
        }) => (
          <>
            <div noValidate onSubmit={handleSubmit}>
              <ParentWrapper>
                <Wrapper>
                  <OtcFormWrapper>
                    <OtcTitle>{aOtcData.transactionType} Otc</OtcTitle>
                    <OtcWrapper>
                      <InputWrapper>
                        <InputLabel>Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          disabled
                          showMessage
                          message={`${errors["name"] ? errors["name"] : ""}`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Email</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          disabled
                          showMessage
                          message={`${errors["email"] ? errors["email"] : ""}`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="country"
                          placeholder="Enter Country"
                          disabled
                          showMessage
                          message={`${
                            errors["country"] ? errors["country"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Phone Number</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="phone"
                          placeholder="Enter Phone Number"
                          disabled
                          showMessage
                          message={`${errors["phone"] ? errors["phone"] : ""}`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Crypto Type</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="cryptoType"
                          placeholder="Enter Crypto Type"
                          disabled
                          showMessage
                          message={`${
                            errors["cryptoType"] ? errors["cryptoType"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                </Wrapper>
                <Wrapper>
                  <OtcFormWrapper>
                    <OtcTitle>Wallet Information</OtcTitle>
                    <OtcWrapper>
                      <InputWrapper>
                        <InputLabel>Wallet Address</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="walletAddress"
                          placeholder="Enter Wallet Address"
                          disabled
                          showMessage
                          message={`${
                            errors["walletAddress"]
                              ? errors["walletAddress"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Transaction Amount</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="transactionAmount"
                          placeholder="Enter Transaction Amount"
                          disabled
                          showMessage
                          message={`${
                            errors["transactionAmount"]
                              ? errors["transactionAmount"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                  <OtcFormWrapper>
                    <OtcTitle2>Bank Information</OtcTitle2>
                    <OtcWrapper>
                      <InputWrapper>
                        <InputLabel>Bank Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="bankName"
                          placeholder="Enter Bank Name"
                          disabled
                          showMessage
                          message={`${
                            errors["bankName"] ? errors["bankName"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Number</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="accountNumber"
                          placeholder="Enter Account Number"
                          disabled
                          showMessage
                          message={`${
                            errors["accountNumber"]
                              ? errors["accountNumber"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="accountName"
                          placeholder="Enter Account Name"
                          disabled
                          showMessage
                          message={`${
                            errors["accountName"] ? errors["accountName"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                </Wrapper>
              </ParentWrapper>
              <ButtonWrapper>
                <SolidButton
                  onClick={() => {
                    btnClick("Reached");
                  }}
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Reached"
                    )
                  }
                  margin="2rem 0"
                  type="submit"
                  weighty="500"
                  textFontSize="16px"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  maxWidth="150px"
                  specifyPadding="1rem 0rem"
                  color="#fff"
                  borderRadius="7px"
                  backColor="#2BC155"
                  backgroundHoverColor="#2BC155"
                  textHoverColor="#fff"
                  disabled={loading ? true : false}
                />
                <SolidButton
                  onClick={() => {
                    btnClick("Not Reached");
                  }}
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Not Reached"
                    )
                  }
                  margin="2rem 0"
                  type="submit"
                  weighty="500"
                  textFontSize="16px"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  maxWidth="150px"
                  specifyPadding="1rem 0rem"
                  color="#fff"
                  borderRadius="7px"
                  backColor="#C12B2B"
                  backgroundHoverColor="#C12B2B"
                  textHoverColor="#fff"
                  disabled={loading ? true : false}
                />
              </ButtonWrapper>
            </div>
          </>
        )}
      </Formik>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.otc.editOtcLoading,
  aOtcLoading: state.otc.aOtcLoading,
  aOtc: state.otc.aOtc,
});

const mapDispatch = (dispatch) => ({
  setEditOtc: (data, id) => dispatch(editOtc(data, id)),
  setGetAOtc: (id) => dispatch(getAOtc(id)),
});

export default connect(mapState, mapDispatch)(EditOtc);
