import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllNairaWalletTxns from "./AllNairaWalletTxns";

const Wrapper = styled.div``;

const NairaWallet = () => {
  return (
    <>
      <DashboardLayout pageTitle="Naira Wallet">
        <Wrapper>
          <AllNairaWalletTxns />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default NairaWallet;
