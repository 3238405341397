import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";
import {
  AUTHENTICATED,
  ERROR,
  LOADING,
  LOGIN_DATA,
  USER_ROLE_SUCCESS,
} from "../reducers/types";
import { saveToken } from "../token";
import headers from "./header";

export const loginUser = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/auth/login`, data);
    dispatch({ type: LOADING, payload: null });
    if (response.data.message === "Login successful") {
      saveToken(response.data.data.token);
      dispatch({ type: AUTHENTICATED, payload: true });
      dispatch({ type: LOGIN_DATA, payload: response.data.data.user });
      localStorage.setItem("name", response.data.data.user.fullName);
    }
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.email}`);
    }
  }
};

export const forgetPassword = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/auth/password-reset`,
      data
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    // console.log(err.response);
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.email}`);
    }
  }
};

export const verifyResetPassword = (token) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/auth/verify-reset/${token}`
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${err.response?.data?.errors[0]?.token}` || "Invalid Reset Token"
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const resetPassword = (data, token) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/auth/reset-password/${token}`,
      data
    );
    dispatch({ type: LOADING, payload: null });

    console.log(response.data);
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    console.log(err.response);
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.password}`);
    }
  }
};

export const getUserRole = () => async (dispatch) => {
  // dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  dispatch({ type: USER_ROLE_SUCCESS, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/auth/admin/role`,
      headers()
    );
    dispatch({ type: LOADING, payload: null });
    dispatch({ type: USER_ROLE_SUCCESS, payload: response.data.data.role });
    return;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
