import axios from "axios";
import {
  CREATE_AN_ADMIN_LOADING,
  DELETE_AN_ADMIN_LOADING,
  EDIT_AN_ADMIN_LOADING,
  GET_ALL_ADMINS_LOADING,
  GET_ALL_ADMINS_SUCCESS,
  GET_AN_ADMIN_LOADING,
  GET_AN_ADMIN_SUCCESS,
  GRANT_REVOKE_ADMIN_ACCESS_LOADING,
} from "../reducers/types";
import { apiBaseUrl } from "../../constants/url";
import { toast } from "react-toastify";
import headers from "./header";

export const getAllAdmins = (query) => async (dispatch) => {
  dispatch({ type: GET_ALL_ADMINS_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/roles/admin-account/get-all${query || ""}`,
      headers()
    );
    dispatch({ type: GET_ALL_ADMINS_LOADING, payload: null });
    dispatch({ type: GET_ALL_ADMINS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_ALL_ADMINS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const createAnAdmin = (data) => async (dispatch) => {
  dispatch({ type: CREATE_AN_ADMIN_LOADING, payload: true });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/auth/admin/account/create`,
      data,
      headers()
    );
    dispatch({ type: CREATE_AN_ADMIN_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAllAdmins());
    toast.success("Admin created successfully", { toastId: "Admin success" });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_AN_ADMIN_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${
          err.response?.data?.errors[0]?.fullName ||
          err.response?.data?.errors[0]?.phone ||
          err.response?.data?.errors[0]?.email
        }`
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getAnAdmin = (adminId) => async (dispatch) => {
  dispatch({ type: GET_AN_ADMIN_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/roles/admin-account/get/${adminId}`,
      headers()
    );
    dispatch({ type: GET_AN_ADMIN_LOADING, payload: null });
    dispatch({ type: GET_AN_ADMIN_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_AN_ADMIN_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const deleteAnAdmin = (adminId) => async (dispatch) => {
  dispatch({ type: DELETE_AN_ADMIN_LOADING, payload: true });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/roles/admin-account/delete/${adminId}`,
      headers()
    );
    dispatch({ type: DELETE_AN_ADMIN_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllAdmins());
    toast.success("Admin deleted successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_AN_ADMIN_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.adminId}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const editAnAdmin = (data, adminId) => async (dispatch) => {
  dispatch({ type: EDIT_AN_ADMIN_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/roles/admin-account/edit/${adminId}`,
      data,
      headers()
    );
    dispatch({ type: EDIT_AN_ADMIN_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAnAdmin(adminId));
    toast.success("Admin edited successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: EDIT_AN_ADMIN_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${
          err.response?.data?.errors[0]?.fullName ||
          err.response?.data?.errors[0]?.phone ||
          err.response?.data?.errors[0]?.email
        }`
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const grantRevokeAdminAccess = (data, adminId) => async (dispatch) => {
  dispatch({ type: GRANT_REVOKE_ADMIN_ACCESS_LOADING, payload: true });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/roles/admin-account/access/${adminId}`,
      data,
      headers()
    );
    dispatch({ type: GRANT_REVOKE_ADMIN_ACCESS_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    dispatch(getAllAdmins());
    toast.success("Admin updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: GRANT_REVOKE_ADMIN_ACCESS_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.adminId}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
