import React from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import CustomEditorCmp from "../../components/InputsCmp/CustomEditorCmp";
import { convertToRaw, EditorState } from "draft-js";
import { Formik } from "formik";
import createCareerSchema from "../../validators/createCareerValidator";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import { nigerianStates } from "../../constants/others";
import { createCareer } from "../../redux/actions/careerActions";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  /* max-width: 768px; */
  width: 100%;
  padding: 0rem 2rem;

  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const CareerFormWrapper = styled(Form)``;

const CareerWrapper = styled.div`
  /* max-width: 500px; */
  /* margin: 0 auto; */
  width: 100%;
  padding-bottom: 2rem;
`;

const CareerTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputWrapper2 = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 100%;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const CreateCareer = ({ setCreateCareer, loading }) => {
  const navigate = useNavigate();
  return (
    <DashboardLayout pageTitle="Add career">
      <Wrapper>
        <Formik
          initialValues={{
            editorState: EditorState.createEmpty(),
            jobTitle: "",
            jobType: "",
            category: "",
            location: "",
          }}
          validationSchema={createCareerSchema}
          onSubmit={async (values, { resetForm }) => {
            const contentState = values.editorState.getCurrentContent();
            const content = JSON.stringify(convertToRaw(contentState));
            // console.log(values, content);
            // resetForm();

            let formData = new FormData();
            formData.append("jobTitle", values.jobTitle);
            formData.append("jobType", values.jobType);
            formData.append("category", values.category);
            formData.append("location", values.location);
            formData.append("content", content);

            const response = await setCreateCareer(formData);

            if (
              response &&
              response.status === 201 &&
              response.message === "Career created"
            ) {
              return navigate("/careers");
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            isValid,
            touched,
          }) => (
            <>
              <CareerFormWrapper noValidate onSubmit={handleSubmit}>
                <CareerTitle>Career Details</CareerTitle>
                <CareerWrapper>
                  <InputGroupWrapper>
                    <InputWrapper>
                      <InputLabel>Job Title</InputLabel>
                      <CustomInputCmp
                        required={true}
                        values={values}
                        onChange={handleChange}
                        type="text"
                        name="jobTitle"
                        placeholder="Enter Job Title"
                        showMessage
                        message={`${
                          errors["jobTitle"] ? errors["jobTitle"] : ""
                        }`}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Job Type</InputLabel>
                      <CustomInputSelectCmp
                        background="rgba(225, 235, 245, 0.1)"
                        required={true}
                        name="jobType"
                        values={values}
                        onChange={handleChange}
                        placeholder="Select"
                        data={["Full Time", "Part Time"]}
                        message={`${
                          errors["jobType"] ? errors["jobType"] : ""
                        }`}
                        showMessage={true}
                      />
                    </InputWrapper>
                  </InputGroupWrapper>
                  <InputGroupWrapper>
                    <InputWrapper>
                      <InputLabel>Category</InputLabel>
                      <CustomInputSelectCmp
                        background="rgba(225, 235, 245, 0.1)"
                        required={true}
                        name="category"
                        values={values}
                        onChange={handleChange}
                        placeholder="Select"
                        data={[
                          "Product",
                          "Marketing",
                          "Human Resources",
                          "Payments",
                          "Sales",
                        ]}
                        message={`${
                          errors["category"] ? errors["category"] : ""
                        }`}
                        showMessage={true}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel>Location</InputLabel>
                      <CustomInputSelectCmp
                        background="rgba(225, 235, 245, 0.1)"
                        required={true}
                        name="location"
                        values={values}
                        onChange={handleChange}
                        placeholder="Select"
                        data={[...nigerianStates]}
                        message={`${
                          errors["location"] ? errors["location"] : ""
                        }`}
                        showMessage={true}
                      />
                    </InputWrapper>
                  </InputGroupWrapper>
                  <InputGroupWrapper>
                    <InputWrapper2>
                      <InputLabel>Content</InputLabel>
                      <CustomEditorCmp
                        placeholder="Enter Career Content"
                        editorState={values.editorState}
                        setFieldValue={setFieldValue}
                        spellCheck={false}
                        showMessage
                        message={`${
                          errors["editorState"] ? errors["editorState"] : ""
                        }`}
                      />
                    </InputWrapper2>
                  </InputGroupWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        loading ? (
                          <>
                            <SpinnerCmp
                              enabled={true}
                              color={appTheme.COLORS.bluePrimary}
                              size={20}
                              secondaryColor={appTheme.COLORS.white}
                            />
                          </>
                        ) : (
                          "Create"
                        )
                      }
                      margin="2rem 0"
                      type="submit"
                      weighty="500"
                      textFontSize="16px"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      maxWidth="200px"
                      specifyPadding="1rem 1.5rem"
                      color="#fff"
                      borderRadius="7px"
                      backColor="#016CBA"
                      backgroundHoverColor="#016CBA"
                      textHoverColor="#fff"
                      disabled={loading ? true : false}
                    />
                  </ButtonWrapper>
                </CareerWrapper>
              </CareerFormWrapper>
            </>
          )}
        </Formik>
        {/* <RenderEditorCmp /> */}
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.career.createCareerLoading,
});

const mapDispatch = (dispatch) => ({
  setCreateCareer: (data) => dispatch(createCareer(data)),
});

export default connect(mapState, mapDispatch)(CreateCareer);
