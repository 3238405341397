import React, { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import { useEffect } from "react";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import {
  editACryptoSetting,
  getACryptoSetting,
  getAdminCryptoInfo,
} from "../../redux/actions/cryptoActions";
import editCryptoSettingsSchema from "../../validators/editCryptoValidator";
import CustomInputWithClipboardCmp from "../../components/InputsCmp/CustomInputWithClipboardCmp";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  /* max-width: 768px; */
  width: 100%;
  padding: 0rem 2rem;

  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const NewsFormWrapper = styled(Form)``;

const NewsWrapper = styled.div`
  /* max-width: 500px; */
  /* margin: 0 auto; */
  width: 100%;
  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const EditCryptoSettings = ({
  loading,
  setEditCryptoSetting,
  setGetACryptoSetting,
  aCryptoLoading,
  aCrypto,
  setGetAdminCryptoInfo,
  getAdminCryptoInfo,
  getAdminCryptoInfoLoading,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [aCryptoData, setaCryptoData] = useState({});
  const [info, setInfo] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getACrypto = async () => {
      const response = await setGetACryptoSetting(id);

      setaCryptoData(response.data);
      setRefresh(false);
    };
    if (refresh) {
      getACrypto();
    } else {
      getACrypto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    const getInfo = async () => {
      const response = await setGetAdminCryptoInfo(
        aCryptoData?.shortcode.toLowerCase()
      );

      setInfo(response.data);
      setRefresh(false);
    };
    if (refresh) {
      getInfo();
    } else {
      getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aCryptoData]);
  return (
    <DashboardLayout pageTitle="Edit Crypto">
      {aCrypto && (
        <Wrapper>
          <Formik
            enableReinitialize={true}
            initialValues={{
              coinName: aCryptoData?.coinName,
              shortcode: aCryptoData?.shortcode,
              nairaBuyingRate: aCryptoData?.nairaBuyingRate,
              nairaSellingRate: aCryptoData?.nairaSellingRate,
              dollarInternal: aCryptoData?.dollarInternal,
              dollarHigh: aCryptoData?.dollarHigh,
              dollarLow: aCryptoData?.dollarLow,
              dollarMedium: aCryptoData?.dollarMedium,
              iconUrl: aCryptoData?.iconUrl,
            }}
            validationSchema={editCryptoSettingsSchema}
            onSubmit={async (values, { resetForm }) => {
              // console.log(values, content);
              // resetForm();

              const response = await setEditCryptoSetting({ ...values }, id);

              if (
                response &&
                response.status === 200 &&
                response.message === "Crypto setting updated successfully"
              ) {
                return navigate("/crypto/settings");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              isValid,
              touched,
            }) => (
              <>
                <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                  <NewsTitle>{aCryptoData?.coinName} Details</NewsTitle>
                  <NewsWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Name of Coin</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="coinName"
                          placeholder="Enter name"
                          disabled={true}
                          showMessage
                          message={`${
                            errors["coinName"] ? errors["coinName"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Shortcode</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          disabled={true}
                          type="text"
                          name="shortcode"
                          placeholder="Enter shortcode"
                          showMessage
                          message={`${
                            errors["shortcode"] ? errors["shortcode"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Buying Rate (₦)</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="nairaBuyingRate"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["nairaBuyingRate"]
                              ? errors["nairaBuyingRate"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Selling Rate (₦)</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="nairaSellingRate"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["nairaSellingRate"]
                              ? errors["nairaSellingRate"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Dollar Internal ($)</InputLabel>
                        <CustomPriceInputCmp
                          prefix="$"
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="dollarInternal"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["dollarInternal"]
                              ? errors["dollarInternal"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Dollar High ($)</InputLabel>
                        <CustomPriceInputCmp
                          prefix="$"
                          decimalScale={6}
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="dollarHigh"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["dollarHigh"] ? errors["dollarHigh"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <InputGroupWrapper>
                      <InputWrapper>
                        <InputLabel>Dollar Low ($)</InputLabel>
                        <CustomPriceInputCmp
                          prefix="$"
                          decimalScale={6}
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="dollarLow"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["dollarLow"] ? errors["dollarLow"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Dollar Medium ($)</InputLabel>
                        <CustomPriceInputCmp
                          prefix="$"
                          decimalScale={6}
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="dollarMedium"
                          placeholder="Enter rate"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["dollarMedium"] ? errors["dollarMedium"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </InputGroupWrapper>
                    <ButtonWrapper>
                      <SolidButton
                        text={
                          loading ? (
                            <>
                              <SpinnerCmp
                                enabled={true}
                                color={appTheme.COLORS.bluePrimary}
                                size={20}
                                secondaryColor={appTheme.COLORS.white}
                              />
                            </>
                          ) : (
                            "Update"
                          )
                        }
                        margin="2rem 0"
                        type="submit"
                        weighty="500"
                        textFontSize="16px"
                        widthWebkit="100%"
                        widthMoz="100%"
                        widthNormal="100%"
                        maxWidth="200px"
                        specifyPadding="1rem 1.5rem"
                        color="#fff"
                        borderRadius="7px"
                        backColor="#016CBA"
                        backgroundHoverColor="#016CBA"
                        textHoverColor="#fff"
                        disabled={loading ? true : false}
                      />
                    </ButtonWrapper>
                  </NewsWrapper>
                </NewsFormWrapper>
              </>
            )}
          </Formik>

          <div>
            <NewsTitle>Admin Wallet</NewsTitle>
            <InputGroupWrapper>
              <InputWrapper>
                <InputLabel>Wallet address</InputLabel>
                <CustomInputWithClipboardCmp
                  required={true}
                  value={info?.address}
                  disabled={true}
                  type="text"
                  name="shortcode"
                />
              </InputWrapper>
              <InputWrapper>
                <InputLabel>Wallet balance</InputLabel>
                <CustomInputCmp
                  required={true}
                  value={`${info?.balance} ${aCryptoData?.shortcode}`}
                  disabled={true}
                  type="text"
                  name="shortcode"
                />
              </InputWrapper>
            </InputGroupWrapper>
          </div>
        </Wrapper>
      )}
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.crypto.editACryptoLoading,
  aCryptoLoading: state.crypto.aCryptoLoading,
  aCrypto: state.crypto.aCrypto,
  getAdminCryptoInfoLoading: state.crypto.getAdminCryptoInfoLoading,
  getAdminCryptoInfo: state.crypto.getAdminCryptoInfoSuccess,
});

const mapDispatch = (dispatch) => ({
  setEditCryptoSetting: (data, id) => dispatch(editACryptoSetting(data, id)),
  setGetACryptoSetting: (id) => dispatch(getACryptoSetting(id)),
  setGetAdminCryptoInfo: (shortcode) => dispatch(getAdminCryptoInfo(shortcode)),
});

export default connect(mapState, mapDispatch)(EditCryptoSettings);
