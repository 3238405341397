import React from "react";
import CurrencyFormat from "react-currency-format";

const MoneyFormatter = (props) => {
  return (
    <>
      <CurrencyFormat
        value={props.value}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"₦"}
      />
    </>
  );
};

export default MoneyFormatter;
