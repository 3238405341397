/* eslint-disable no-useless-escape */
import * as yup from "yup";

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("It must be a valid email")
    .required("Provide a valid email"),
});

export default forgotPasswordSchema;
