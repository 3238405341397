import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllTickets from "./AllTickets";

const Wrapper = styled.div``;

const Tickets = () => {
  return (
    <>
      <DashboardLayout pageTitle="Tickets">
        <Wrapper>
          <AllTickets />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Tickets;
