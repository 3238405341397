import {
  ALL_CAREER_ERROR,
  ALL_CAREER_LOADING,
  ALL_CAREER_SUCCESS,
  A_CAREER_ERROR,
  A_CAREER_LOADING,
  A_CAREER_SUCCESS,
  CREATE_CAREER_ERROR,
  CREATE_CAREER_LOADING,
  CREATE_CAREER_SUCCESS,
  DELETE_CAREER_ERROR,
  DELETE_CAREER_LOADING,
  UPDATE_CAREER_ERROR,
  UPDATE_CAREER_LOADING,
  UPDATE_CAREER_SUCCESS,
} from "./types";

const initialState = {
  allCareerLoading: null,
  allCareer: null,
  allCareerError: null,
  aCareerLoading: null,
  aCareer: null,
  aCareerError: null,
  deleteCareerLoading: null,
  deleteCareerError: null,
  createCareerLoading: null,
  createCareerSuccess: null,
  createCareerError: null,
  updateCareerLoading: null,
  updateCareerSuccess: null,
  updateCareerError: null,
};

export default function careerReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_CAREER_LOADING:
      return { ...state, allCareerLoading: action.payload };
    case ALL_CAREER_SUCCESS:
      return { ...state, allCareer: action.payload };
    case ALL_CAREER_ERROR:
      return { ...state, allCareerError: action.payload };
    case A_CAREER_LOADING:
      return { ...state, aCareerLoading: action.payload };
    case A_CAREER_SUCCESS:
      return { ...state, aCareer: action.payload };
    case A_CAREER_ERROR:
      return { ...state, aCareerError: action.payload };
    case DELETE_CAREER_LOADING:
      return { ...state, deleteCareerLoading: action.payload };
    case DELETE_CAREER_ERROR:
      return { ...state, deleteCareerError: action.payload };
    case CREATE_CAREER_LOADING:
      return { ...state, createCareerLoading: action.payload };
    case CREATE_CAREER_SUCCESS:
      return { ...state, createCareerSuccess: action.payload };
    case CREATE_CAREER_ERROR:
      return { ...state, createCareerError: action.payload };
    case UPDATE_CAREER_LOADING:
      return { ...state, updateCareerLoading: action.payload };
    case UPDATE_CAREER_SUCCESS:
      return { ...state, updateCareerSuccess: action.payload };
    case UPDATE_CAREER_ERROR:
      return { ...state, updateCareerError: action.payload };
    default:
      return state;
  }
}
