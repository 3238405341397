import React, { useRef } from "react";
import { Editor, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import styled from "styled-components";
import appTheme from "../../constants/theme";

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" },
];

var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" },
];

const StyleButton = (props) => {
  let className = "RichEditor-styleButton";
  if (props.active) {
    className += " RichEditor-activeButton";
  }
  return (
    <span
      className={className}
      onMouseDown={(e) => {
        e.preventDefault();
        props.onToggle(props.style);
      }}
    >
      {props.label}
    </span>
  );
};

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const EditorWrapper = styled.div`
  border: ${(props) => (props.message ? "1px solid red" : "1px solid #ADB5BD")};
  background: ${(props) => (props.background ? props.background : "#fff")};
  border-radius: 8px;
  color: #374151;
  font-weight: 400;
  font-size: 15px;
  /* padding: 15px; */
  overflow: auto;
  height: 300px;
  &:focus {
    box-shadow: none;
    border: 1px solid ${appTheme.COLORS.blueSecondary};
    background: rgba(243, 244, 246, 0.7);
  }
`;

const ControlsWrapper = styled.div`
  position: sticky;
  top: 0;
  padding-bottom: 5px;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid #adb5bd;
  padding: 10px 15px;
  margin-bottom: 5px;
`;

const TextAreaWrapper = styled.div`
  cursor: text;
  font-size: 16px;
  padding: 0 15px 15px;
`;

const ErrorMessageWrapper = styled.div`
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 13px;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
`;

const CustomEditorCmp = ({
  editorState,
  onChange,
  onBlur,
  placeholder,
  spellCheck,
  background,
  message,
  showMessage,
}) => {
  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = "RichEditor-editor";
  var contentState = editorState.getCurrentContent();
  // console.log(editorState.getCurrentContent());
  //   console.log(JSON.stringify(convertToRaw(contentState)));
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  const editor = useRef();
  return (
    <>
      <EditorWrapper
        className="RichEditor-root"
        background={background}
        message={message || null}
      >
        <ControlsWrapper>
          <BlockStyleControls
            editorState={editorState}
            onToggle={(blockType) => {
              onChange(
                "editorState",
                RichUtils.toggleBlockType(editorState, blockType)
              );
            }}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={(inlineStyle) => {
              onChange(
                "editorState",
                RichUtils.toggleInlineStyle(editorState, inlineStyle)
              );
            }}
          />
        </ControlsWrapper>
        <TextAreaWrapper
          className={className}
          onClick={() => {
            editor.current.focus();
          }}
        >
          <Editor
            editorState={editorState}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            handleKeyCommand={(command) => {
              const newState = RichUtils.handleKeyCommand(editorState, command);
              if (newState) {
                onChange("editorState", newState);
                return true;
              }
              return false;
            }}
            onChange={(editorState) => {
              onChange("editorState", editorState);
            }}
            onTab={(e) => {
              const maxDepth = 4;
              onChange(
                "editorState",
                RichUtils.onTab(e, editorState, maxDepth)
              );
            }}
            placeholder={placeholder}
            ref={editor}
            spellCheck={spellCheck}
          />
        </TextAreaWrapper>
      </EditorWrapper>
      <ErrorMessageWrapper>
        <ErrorMessage>{showMessage ? message : ""}</ErrorMessage>
      </ErrorMessageWrapper>
    </>
  );
};

export default CustomEditorCmp;
