import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import CustomInputIconCmp from "../InputsCmp/CustomInputIconCmp";
import { Email } from "@styled-icons/material-outlined";
import SolidButton from "../ButtonCmp/Button";
import { Formik } from "formik";
import forgotPasswordSchema from "../../validators/forgotPasswordValidator";
import ResetLinkSentModal from "../Modals/ResetLinkSentModal";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled(Form)`
  padding-top: 0.5rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputWrapper = styled.div``;

// const TextWrapper = styled.div`
//   padding-top: 0.5rem;
// `;

// const Text = styled.p`
//   color: ${appTheme.COLORS.grey1};
//   font-weight: ${appTheme.WEIGHT.small3};
//   font-size: 14px;
// `;

// const Span = styled.span`
//   color: #1a8fdd;
//   cursor: pointer;
// `;

const ButtonWrapper = styled.div`
  padding-top: 1.3rem;
`;

const ForgotPasswordForm = ({ loading, error, setForgetPassword }) => {
  const navigate = useNavigate();

  const [resetSuccess, setResetSuccess] = useState(false);

  return (
    <>
      <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={{
          businessEmail: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          console.log("Submitted");
          // console.log(values);
          // resetForm();

          const response = await setForgetPassword({
            email: values.email,
          });

          if (
            response &&
            response.status === 201 &&
            response.message === "Reset mail sent"
          ) {
            setResetSuccess(true);
          }

          // console.log(response);
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <>
            <Wrapper noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <CustomInputIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Email size={20} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    values={values}
                    onChange={handleChange}
                    message={`${errors["email"] ? errors["email"] : ""}`}
                    showMessage={false}
                  />
                </InputWrapper>
              </FormWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Reset Password"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={loading ? true : false}
                />
              </ButtonWrapper>
              <ResetLinkSentModal
                isOpen={resetSuccess}
                onRequestClose={() => {
                  setResetSuccess(false);
                  navigate("/login");
                }}
              />
            </Wrapper>
          </>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
});

const mapDispatch = (dispatch) => ({
  setForgetPassword: (data) => dispatch(forgetPassword(data)),
});

export default connect(mapState, mapDispatch)(ForgotPasswordForm);
