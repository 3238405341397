import {
  DASHBOARD_BALANCE_ERROR,
  DASHBOARD_BALANCE_LOADING,
  DASHBOARD_BALANCE_SUCCESS,
  DASHBOARD_TXN_LIMIT_ERROR,
  DASHBOARD_TXN_LIMIT_LOADING,
  DASHBOARD_TXN_LIMIT_SUCCESS,
} from "./types";

const initialState = {
  dashboardBalanceLoading: null,
  dashboardBalance: null,
  dashboardBalanceError: null,
  dashboardTxnLimitLoading: null,
  dashboardTxnLimit: null,
  dashboardTxnLimitError: null,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_BALANCE_LOADING:
      return { ...state, dashboardBalanceLoading: action.payload };
    case DASHBOARD_BALANCE_SUCCESS:
      return { ...state, dashboardBalance: action.payload };
    case DASHBOARD_BALANCE_ERROR:
      return { ...state, dashboardBalanceError: action.payload };
    case DASHBOARD_TXN_LIMIT_LOADING:
      return { ...state, dashboardTxnLimitLoading: action.payload };
    case DASHBOARD_TXN_LIMIT_SUCCESS:
      return { ...state, dashboardTxnLimit: action.payload };
    case DASHBOARD_TXN_LIMIT_ERROR:
      return { ...state, dashboardTxnLimitError: action.payload };
    default:
      return state;
  }
}
