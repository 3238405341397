import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllCrypto from "./AllCrypto";

const Wrapper = styled.div``;

const PerfectMoney = () => {
  return (
    <>
      <DashboardLayout pageTitle="Crypto">
        <Wrapper>
          <AllCrypto />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default PerfectMoney;
