import { Trash } from "@styled-icons/boxicons-regular";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import ActionModal from "../../components/Modals/ActionModal";
import TableCmp from "../../components/TableCmp/TableCmp";
import { statusColorMap } from "../../constants/others";
import { deleteAUpdate, getAllUpdate } from "../../redux/actions/updateActions";
import { convertToLocalDateTime, trimText } from "../../utils/utils";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllUpdatesCmp = ({ setGetAllUpdate, setDeleteAUpdate }) => {
  const [updateData, setUpdateData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAllUpdate = async () => {
      setLoadingState(true);
      const response = await setGetAllUpdate(currentPage);

      setUpdateData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllUpdate();
    } else {
      getAllUpdate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const [showActionModal, setShowActionModal] = useState(false);
  const [item, setItem] = useState();

  const handleDeleteAction = () => {
    setDeleteAUpdate(item._id);
    setRefresh(true);
  };

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : updateData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...updateData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "Title",
                accessor: "title",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {trimText(props.value, 25)}
                    </p>
                  );
                },
              },
              {
                Header: "Content",
                accessor: "content",
                id: "desc",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {trimText(props.value, 25)}
                    </p>
                  );
                },
              },
              {
                Header: "Date Created",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {convertToLocalDateTime(props.value)}
                    </p>
                  );
                },
              },
              {
                Header: "Status",
                accessor: "status",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        color: statusColorMap[props.value],
                        margin: "0",
                        fontWeight: "700",
                        fontSize: "15px",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        <SolidButton
                          text={
                            <>
                              <Trash color="#d60000" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#D60000"
                          borderHoverColor="#D60000"
                          onClick={() => {
                            setShowActionModal(true);
                            setItem(row.values);
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#D60000"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#D60000"
                        />
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No update added yet"
            desc="Please add update to populate this area"
            btnText={"Create Update"}
            link="/news-and-updates/create"
          />
        )}
      </Wrapper>
      <ActionModal
        maxWidth="450px"
        wrapperPadding={"1rem 1rem 2rem"}
        showCloseIcon={true}
        isOpen={showActionModal}
        onRequestClose={() => {
          setShowActionModal(false);
        }}
        runAction={handleDeleteAction}
        actionOnReject={() => {
          setItem();
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.update.allUpdateLoading,
  error: state.update.allUpdateError,
  news: state.update.allUpdate,
});

const mapDispatch = (dispatch) => ({
  setGetAllUpdate: (page) => dispatch(getAllUpdate(page)),
  setDeleteAUpdate: (id) => dispatch(deleteAUpdate(id)),
});

export default connect(mapState, mapDispatch)(AllUpdatesCmp);
