import React, { useEffect } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import StatCardCmp from "../../components/StatCardCmp/StatCardCmp";
import UserSvg from "../../assets/user-svg.svg";
import DisputeSvg from "../../assets/dispute-svg.svg";
import OtcSvg from "../../assets/otc-svg.svg";
import TranxSvg from "../../assets/tranx-svg.svg";
import { getAllDashboardStat } from "../../redux/actions/statActions";
import { connect } from "react-redux";
import millify from "millify";
import appTheme from "../../constants/theme";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import NumberFormat from "react-number-format";

const Wrapper = styled.div``;

const StatWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 346px) {
    justify-content: center;
  }
`;

const Dashboard = ({ setGetAllDashboardStat, loading, error, stat }) => {
  useEffect(() => {
    const getAllStat = async () => {
      await setGetAllDashboardStat();
    };
    getAllStat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapper = {
    totalUsers: { title: "Total User", svg: `${UserSvg}` },
    totalTxns: { title: "Total Tranx", svg: `${TranxSvg}` },
    totalBalance: { title: "Total Balance", svg: `${TranxSvg}` },
    totalDeposit: { title: "Total Deposit", svg: `${TranxSvg}` },
    totalWithdrawal: { title: "Total Withdrawal", svg: `${TranxSvg}` },
    totalSpent: { title: "Total Spent", svg: `${TranxSvg}` },
    totalOtcTxns: { title: "Total OTC Tranx", svg: `${OtcSvg}` },
    totalResolvedDisputes: { title: "Resolved Disputes", svg: `${DisputeSvg}` },
    totalPendingDisputes: { title: "Pending Disputes", svg: `${DisputeSvg}` },
    totalUnresolvedDisputes: {
      title: "Unresolved Disputes",
      svg: `${DisputeSvg}`,
    },
    totalPendingOtc: { title: "Pending Otc", svg: `${OtcSvg}` },
    totalReachedOtc: { title: "Reached Otc", svg: `${OtcSvg}` },
    totalNotReachedOtc: { title: "Unreached Otc", svg: `${OtcSvg}` },
    totalDeclinedTxn: { title: "Total Declined Txn", svg: `${TranxSvg}` },
    totalPendingTxn: { title: "Total Pending Txn", svg: `${TranxSvg}` },
    totalSuccessfulTxn: { title: "Total Success Txn", svg: `${TranxSvg}` },
    totalAgents: { title: "Total Agent", svg: `${UserSvg}` },
  };
  return (
    <>
      <DashboardLayout
        // backButton={true}
        // backText={"Back to Wallet"}
        pageTitle="Dashboard"
      >
        <Wrapper>
          <StatWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SpinnerCmp
                  enabled={true}
                  color={appTheme.COLORS.bluePrimary}
                  size={40}
                  secondaryColor={appTheme.COLORS.white}
                />
              </div>
            ) : (
              stat?.data &&
              Object.keys(stat?.data)?.map((v, i) => {
                return (
                  <StatCardCmp
                    key={i}
                    statTitle={mapper[v]?.title}
                    amount={
                      loading ? (
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      ) : (
                        millify(stat?.data[v])
                      )
                    }
                    smallAmount={
                      loading ? (
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      ) : (
                        <NumberFormat
                          thousandSeparator
                          value={stat?.data[v]}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      )
                    }
                    icon={mapper[v]?.svg}
                  />
                );
              })
            )}
          </StatWrapper>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  loading: state.stat.allDashboardStatLoading,
  error: state.stat.allDashboardStatError,
  stat: state.stat.allDashboardStat,
});

const mapDispatch = (dispatch) => ({
  setGetAllDashboardStat: () => dispatch(getAllDashboardStat()),
});

export default connect(mapState, mapDispatch)(Dashboard);
