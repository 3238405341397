import { Pencil, Trash } from "@styled-icons/boxicons-regular";
import { Eye } from "@styled-icons/ionicons-outline/Eye";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import ActionModal from "../../components/Modals/ActionModal";
import ViewNewsModal from "../../components/Modals/ViewNewsModal";
import TableCmp from "../../components/TableCmp/TableCmp";
import { deleteANews, getAllNews } from "../../redux/actions/newsAction";
import { convertToLocalDateTime } from "../../utils/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import appTheme from "../../constants/theme";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const WrapperImageText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BlogImgWrapper = styled(LazyLoadImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllNewsCmp = ({ setGetAllNews, setDeleteANews, userRole }) => {
  const [newsData, setNewsData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAllNews = async () => {
      setLoadingState(true);
      const response = await setGetAllNews(currentPage);

      setNewsData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllNews();
    } else {
      getAllNews();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const [showActionModal, setShowActionModal] = useState(false);
  const [viewNews, setViewNews] = useState(false);
  const [item, setItem] = useState();

  const navigate = useNavigate();

  const handleDeleteAction = () => {
    setDeleteANews(item._id);
    setRefresh(true);
  };

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : newsData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...newsData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "Title",
                accessor: "title",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <WrapperImageText>
                      <BlogImgWrapper
                        src={props.row.original.newsPicUrl}
                        alt={props.row.original.title}
                        effect="blur"
                      />
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#3E4954",
                        }}
                      >
                        {props.value}
                      </p>
                    </WrapperImageText>
                  );
                },
              },
              {
                Header: "Snippet",
                accessor: "snippet",
                id: "desc",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Tags",
                accessor: "tags",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value &&
                        props.value.length > 0 &&
                        props.value.map((v, i) => {
                          return (
                            <>
                              {`${v}${i === props.value.length - 1 ? "" : ","}`}
                            </>
                          );
                        })}
                    </p>
                  );
                },
              },
              {
                Header: "Date Created",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {convertToLocalDateTime(props.value)}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        {["Super Admin", "Admin", "Support"].includes(
                          userRole
                        ) && (
                          <>
                            {" "}
                            <SolidButton
                              text={
                                <>
                                  <Eye color="#58c283" size={15} />
                                </>
                              }
                              type="submit"
                              weighty="500"
                              textFontSize="14px"
                              borderColor="#58c283"
                              borderHoverColor="#58c283"
                              onClick={() => {
                                setViewNews(true);
                                setItem(row.original);
                              }}
                              specifyPadding="0.5rem 0.3rem"
                              color="#58c283"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              maxWidth="35px"
                              borderRadius="6px"
                              backColor="transparent"
                              backgroundHoverColor="transparent"
                              textHoverColor="#58c283"
                            />
                            <SolidButton
                              text={
                                <>
                                  <Pencil color="#2a9bf2" size={15} />
                                </>
                              }
                              type="submit"
                              weighty="500"
                              textFontSize="14px"
                              borderColor="#2a9bf2"
                              borderHoverColor="#2a9bf2"
                              onClick={() => {
                                navigate(
                                  `/help-center/blogs/edit/${row.values._id}`
                                );
                              }}
                              specifyPadding="0.5rem 0.3rem"
                              color="#2a9bf2"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              maxWidth="35px"
                              borderRadius="6px"
                              backColor="transparent"
                              backgroundHoverColor="transparent"
                              textHoverColor="#2a9bf2"
                            />
                            <SolidButton
                              text={
                                <>
                                  <Trash color="#d60000" size={15} />
                                </>
                              }
                              type="submit"
                              weighty="500"
                              textFontSize="14px"
                              borderColor="#D60000"
                              borderHoverColor="#D60000"
                              onClick={() => {
                                setShowActionModal(true);
                                setItem(row.values);
                              }}
                              specifyPadding="0.5rem 0.3rem"
                              color="#D60000"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              maxWidth="35px"
                              borderRadius="6px"
                              backColor="transparent"
                              backgroundHoverColor="transparent"
                              textHoverColor="#D60000"
                            />
                          </>
                        )}
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No blog added yet"
            desc="Please add blog post to populate this area"
            btnText={"Create New Blog"}
            link="/help-center/blogs/create"
          />
        )}
      </Wrapper>
      <ActionModal
        maxWidth="450px"
        wrapperPadding={"1rem 1rem 2rem"}
        showCloseIcon={true}
        isOpen={showActionModal}
        onRequestClose={() => {
          setShowActionModal(false);
        }}
        runAction={handleDeleteAction}
        actionOnReject={() => {
          setItem();
        }}
      />
      <ViewNewsModal
        maxWidth="600px"
        wrapperPadding={"1rem 1rem 2rem"}
        data={item}
        showCloseIcon={true}
        isOpen={viewNews}
        onRequestClose={() => {
          setViewNews(false);
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.news.allNewsLoading,
  error: state.news.allNewsError,
  news: state.news.allNews,
  userRole: state.auth.userRole,
});

const mapDispatch = (dispatch) => ({
  setGetAllNews: (page) => dispatch(getAllNews(page)),
  setDeleteANews: (id) => dispatch(deleteANews(id)),
});

export default connect(mapState, mapDispatch)(AllNewsCmp);
