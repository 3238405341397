import React from "react";
import SuccessModal from "./SuccessModal";
import ResetImg from "../../assets/reset-link.svg";

const ResetLinkSentModal = (props) => {
  return (
    <SuccessModal
      title={
        <>
          <span style={{ color: "#000", opacity: "80%", fontWeight: "400" }}>
            An email has been sent to the provided email address. Please check
            and follow the instructions.
            <br />
            <br />
            <p>
              If you don’t see it, you may need to check your <b>spam folder</b>
              .
            </p>
          </span>
        </>
      }
      titleMaxWidth="400px"
      maxWidth="450px"
      showLogo={true}
      wrapperPadding={"1rem 1rem 2rem"}
      showCloseIcon={true}
      isOpen={props.isOpen}
      heading={"Reset Link has been sent"}
      successImgUrl={ResetImg}
      onRequestClose={props.onRequestClose}
    />
  );
};

export default ResetLinkSentModal;
