import axios from "axios";
import {
  ALL_OTC_LOADING,
  ALL_OTC_SUCCESS,
  A_OTC_LOADING,
  A_OTC_SUCCESS,
  DELETE_OTC_LOADING,
  UPDATE_OTC_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllOtc =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_OTC_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/otc/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: ALL_OTC_LOADING, payload: null });
      dispatch({ type: ALL_OTC_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: ALL_OTC_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const deleteAOtc = (id) => async (dispatch) => {
  dispatch({ type: DELETE_OTC_LOADING, payload: true });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/otc/delete-otc/${id}`,
      headers()
    );
    dispatch({ type: DELETE_OTC_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllOtc());
    toast.success("Otc deleted successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_OTC_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const editOtc = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_OTC_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/otc/edit-otc/${id}`,
      data,
      headers()
    );
    dispatch({ type: UPDATE_OTC_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllOtc());
    toast.success("Otc updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: UPDATE_OTC_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getAOtc = (id) => async (dispatch) => {
  dispatch({ type: A_OTC_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/otc/get-otc/${id}`,
      headers()
    );
    dispatch({ type: A_OTC_LOADING, payload: null });
    dispatch({ type: A_OTC_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_OTC_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
