import axios from "axios";
import {
  ALL_AD_LOADING,
  ALL_AD_SUCCESS,
  A_AD_LOADING,
  A_AD_SUCCESS,
  CREATE_AD_LOADING,
  DELETE_AD_LOADING,
  UPDATE_AD_LOADING,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllAd =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_AD_LOADING, payload: true });
    try {
      const response = await axios.get(
        `${apiBaseUrl}/ads/all?page=${page}&limit=50`,
        headers()
      );
      dispatch({ type: ALL_AD_LOADING, payload: null });
      dispatch({ type: ALL_AD_SUCCESS, payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: ALL_AD_LOADING, payload: null });
      return toast.error(err.response?.data?.message, {
        toastId: "Error",
      });
    }
  };

export const deleteAAd = (id) => async (dispatch) => {
  dispatch({ type: DELETE_AD_LOADING, payload: true });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/ads/delete-ad/${id}`,
      headers()
    );
    dispatch({ type: DELETE_AD_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllAd());
    toast.success("Ad deleted successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_AD_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const createAd = (data) => async (dispatch) => {
  dispatch({ type: CREATE_AD_LOADING, payload: true });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/ads/create-ad`,
      data,
      headers()
    );
    dispatch({ type: CREATE_AD_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllAd());
    toast.success("Ad created successfully", { toastId: "Ad success" });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_AD_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${
          err.response?.data?.errors[0]?.title ||
          err.response?.data?.errors[0]?.adImage ||
          err.response?.data?.errors[0]?.content
        }`
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const editAd = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_AD_LOADING, payload: true });
  try {
    const response = await axios.put(
      `${apiBaseUrl}/ads/edit-ad/${id}`,
      data,
      headers()
    );
    dispatch({ type: UPDATE_AD_LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    dispatch(getAllAd());
    toast.success("Ad updated successfully", { toastId: "Success" });
    return response.data;
  } catch (err) {
    dispatch({ type: UPDATE_AD_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.id}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getAAd = (id) => async (dispatch) => {
  dispatch({ type: A_AD_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/ads/get-ad/${id}`,
      headers()
    );
    dispatch({ type: A_AD_LOADING, payload: null });
    dispatch({ type: A_AD_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_AD_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
