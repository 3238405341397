/* eslint-disable no-useless-escape */
import * as yup from "yup";

const editBuyGiftcardSchema = yup.object().shape({
  name: yup.string().required("Please add name"),
  cardType: yup.string().required("Please add card type"),
  rate: yup
    .array()
    .of(
      yup.object().shape({
        nairaDollarRate: yup.string().required("Add rate"),
        productId: yup.string().required("Add ID"),
      })
    )
    .required("Must have rate"),
});

export default editBuyGiftcardSchema;
