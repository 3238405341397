import React, { useEffect } from "react";
import { Add } from "@styled-icons/material-outlined";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import rolesOption from "../../constants/roles";
import AllRoles from "./AllRoles";
import SolidButton from "../../components/ButtonCmp/Button";

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const Roles = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params?.userRole && !rolesOption[params?.userRole]) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <DashboardLayout pageTitle={rolesOption[params?.userRole]}>
        <Wrapper>
          <ButtonWrapper>
            <SolidButton
              text={
                <>
                  <Add color="#fff" size={16} /> Add New{" "}
                  {rolesOption[params?.userRole]}
                </>
              }
              type="submit"
              weighty="500"
              textFontSize="14px"
              onClick={() => {
                navigate(`/roles-create/${params?.userRole}`);
              }}
              specifyPadding="0.8rem 0.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              maxWidth="200px"
              borderRadius="2px"
              backColor="#016CBA"
              backgroundHoverColor="#016CBA"
              textHoverColor="#fff"
              margin="1rem auto 0"
            />
          </ButtonWrapper>
          <AllRoles />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Roles;
