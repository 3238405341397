import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 65px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #adb5bd;
  padding: 10px;
  cursor: pointer;
`;

const GiftcardImage = styled(LazyLoadImage)`
  width: 30px;
  aspect-ratio: 3/2;
`;

const Text = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: #323232;
  margin: 0;
`;

const DisplayCountryCard = ({ flagIcon, country, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <GiftcardImage src={flagIcon} alt={country} effect="blur" />
      <Text>{country}</Text>
    </Wrapper>
  );
};

export default DisplayCountryCard;
