import {
  ALL_DASHBOARD_STAT_ERROR,
  ALL_DASHBOARD_STAT_LOADING,
  ALL_DASHBOARD_STAT_SUCCESS,
} from "./types";

const initialState = {
  allDashboardStatLoading: null,
  allDashboardStat: null,
  allDashboardStatError: null,
};

export default function dashboardStatReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_DASHBOARD_STAT_LOADING:
      return { ...state, allDashboardStatLoading: action.payload };
    case ALL_DASHBOARD_STAT_SUCCESS:
      return { ...state, allDashboardStat: action.payload };
    case ALL_DASHBOARD_STAT_ERROR:
      return { ...state, allDashboardStatError: action.payload };
    default:
      return state;
  }
}
