import React, { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FieldArray, Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import { useEffect } from "react";
import {
  editAGiftcardSell,
  getAGiftcardSell,
} from "../../redux/actions/giftcardActions";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import getSymbolFromCurrency from "currency-symbol-map";
import editSellGiftcardSchema from "../../validators/editSellGiftcardValidator";
import EUImage from "../../assets/eu.svg";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  /* max-width: 768px; */
  width: 100%;
  padding: 0rem 2rem;

  @media only screen and (max-width: 400px) {
    padding: 0 1rem;
  }
`;

const NewsFormWrapper = styled(Form)``;

const ImagePreviewWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
`;

const Image = styled(LazyLoadImage)`
  background-size: cover;
  width: 100%;
  height: 200px;
`;

const NewsWrapper = styled.div`
  /* max-width: 500px; */
  /* margin: 0 auto; */
  width: 100%;
  padding-bottom: 2rem;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
  width: 45%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

// const InputWrapper2 = styled.div`
//   padding-bottom: 1.5rem;
//   text-align: left;
//   width: 100%;
// `;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: 700;
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const EditSellGiftcard = ({
  setEditAGiftcardSell,
  loading,
  setGetAGiftcardSell,
  aGiftcardSell,
  aGiftcardSellLoading,
}) => {
  const { productId } = useParams();
  const [imagePreview, setImagePreview] = useState();

  const [, setAGiftcardData] = useState({});

  useEffect(() => {
    const getAGiftcard = async () => {
      const response = await setGetAGiftcardSell(productId);

      setAGiftcardData(response.data);
      setImagePreview(
        response.data?.supportedCountries[0]?.country?.isoName === "EU"
          ? EUImage
          : response.data?.supportedCountries[0]?.country?.flagUrl
      );
    };
    getAGiftcard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout pageTitle="Edit Giftcard Details">
      {aGiftcardSell && (
        <Wrapper>
          <NewsTitle>
            Edit Giftcard Rate -{" "}
            {aGiftcardSell?.data?.supportedCountries[0]?.country?.name}
          </NewsTitle>
          {imagePreview && (
            <ImagePreviewWrapper>
              <Image src={imagePreview} alt="Preview" effect="blur" />
            </ImagePreviewWrapper>
          )}
          {aGiftcardSell?.data?.supportedCountries[0]?.giftcardType?.map(
            (singleGiftcardType, i) => {
              return (
                <Formik
                  key={i}
                  enableReinitialize={true}
                  validationSchema={editSellGiftcardSchema}
                  initialValues={{
                    giftcardType: singleGiftcardType?.type,
                    rateData:
                      singleGiftcardType?.fixedRecipientDenominationsDetails?.map(
                        (v) => {
                          return {
                            nairaDollarRate: v?.nairaDollarRate,
                            denomination: v?.denomination,
                            _id: v._id,
                          };
                        }
                      ) || [],
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    // console.log(values, content);
                    // resetForm();

                    const response = await setEditAGiftcardSell(
                      {
                        rateData: values?.rateData,
                        giftcardType: values?.giftcardType,
                      },
                      productId
                    );

                    if (
                      response &&
                      response.status === 201 &&
                      response.message === "Giftcard rate updated"
                    ) {
                      // return navigate("/giftcards/sell");
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    setFieldValue,
                    setFieldTouched,
                    isValid,
                    touched,
                  }) => (
                    <>
                      <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                        <NewsWrapper>
                          <NewsTitle style={{ padding: "1rem 0" }}>
                            {values?.giftcardType} Rate
                          </NewsTitle>
                          <InputGroupWrapper>
                            <FieldArray
                              name="rate"
                              render={(arrayHelpers) => (
                                <>
                                  {values?.rateData?.map((v, i) => (
                                    <InputWrapper key={i}>
                                      <InputLabel>
                                        {getSymbolFromCurrency(
                                          aGiftcardSell?.data
                                            ?.supportedCountries[0]
                                            ?.recipientCurrencyCode
                                        )}
                                        {v?.denomination}
                                      </InputLabel>
                                      <CustomPriceInputCmp
                                        background="rgba(225, 235, 245, 0.1)"
                                        required={true}
                                        type="text"
                                        name={`rateData[${i}].nairaDollarRate`}
                                        placeholder="Enter rate"
                                        value={
                                          values?.rateData[i]?.nairaDollarRate
                                        }
                                        setFieldValue={setFieldValue}
                                        showMessage
                                        message={`${
                                          errors[
                                            `rateData[${i}].nairaDollarRate`
                                          ]
                                            ? errors[
                                                `rateData[${i}].nairaDollarRate`
                                              ]
                                            : ""
                                        }`}
                                      />
                                    </InputWrapper>
                                  ))}
                                </>
                              )}
                            ></FieldArray>
                          </InputGroupWrapper>
                          <ButtonWrapper>
                            <SolidButton
                              text={
                                loading ? (
                                  <>
                                    <SpinnerCmp
                                      enabled={true}
                                      color={appTheme.COLORS.bluePrimary}
                                      size={20}
                                      secondaryColor={appTheme.COLORS.white}
                                    />
                                  </>
                                ) : (
                                  "Update"
                                )
                              }
                              margin="2rem 0"
                              type="submit"
                              weighty="500"
                              textFontSize="16px"
                              widthWebkit="100%"
                              widthMoz="100%"
                              widthNormal="100%"
                              maxWidth="200px"
                              specifyPadding="1rem 1.5rem"
                              color="#fff"
                              borderRadius="7px"
                              backColor="#016CBA"
                              backgroundHoverColor="#016CBA"
                              textHoverColor="#fff"
                              disabled={loading ? true : false}
                            />
                          </ButtonWrapper>
                        </NewsWrapper>
                      </NewsFormWrapper>
                    </>
                  )}
                </Formik>
              );
            }
          )}
        </Wrapper>
      )}
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  loading: state.giftcard.editGiftcardSellLoading,
  aGiftcardSellLoading: state.giftcard.aGiftcardSellLoading,
  aGiftcardSell: state.giftcard.aGiftcardSell,
});

const mapDispatch = (dispatch) => ({
  setEditAGiftcardSell: (data, id) => dispatch(editAGiftcardSell(data, id)),
  setGetAGiftcardSell: (id) => dispatch(getAGiftcardSell(id)),
});

export default connect(mapState, mapDispatch)(EditSellGiftcard);
