import { Pencil } from "@styled-icons/boxicons-regular";
import { Eye } from "@styled-icons/ionicons-outline/Eye";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import TableCmp from "../../components/TableCmp/TableCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import {
  getAllGiftcardBuy,
  hideAGiftcardBuy,
} from "../../redux/actions/giftcardActions";
import { EyeOff } from "@styled-icons/ionicons-outline";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const WrapperImageText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BlogImgWrapper = styled(LazyLoadImage)`
  width: 50px;
  aspect-ratio: 3/2;
  object-fit: cover;
  border-radius: 5px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllBuyGiftcard = ({ setGetAllGiftcardBuy, setHideAGiftcardBuy }) => {
  const [giftcardData, setGiftcardData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAllGiftcards = async () => {
      setLoadingState(true);
      const response = await setGetAllGiftcardBuy(currentPage);

      setGiftcardData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    if (refresh) {
      getAllGiftcards();
    } else {
      getAllGiftcards();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const navigate = useNavigate();

  const handleHideAction = (data, id) => {
    setHideAGiftcardBuy(data, id);
    setRefresh(true);
  };

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : giftcardData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...giftcardData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "Name",
                accessor: "brand.brandName",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <WrapperImageText>
                      <BlogImgWrapper
                        src={props.row.original.logoUrls[0]}
                        alt={props.row.brand?.brandName}
                        effect="blur"
                      />
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#3E4954",
                        }}
                      >
                        {props.value}
                      </p>
                    </WrapperImageText>
                  );
                },
              },
              {
                Header: "Type",
                accessor: "cardType",
                id: "cardType",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "ID",
                accessor: "_id",
                id: "identifier",
                maxWidth: 300,
                minWidth: 180,
                width: 280,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              // {
              //   Header: "Date Created",
              //   accessor: "updatedAt",
              //   maxWidth: 200,
              //   minWidth: 140,
              //   width: 200,
              //   Cell: (props) => {
              //     return (
              //       <p
              //         style={{
              //           margin: "0",
              //           fontWeight: "400",
              //           fontSize: "13px",
              //           color: "#3E4954",
              //         }}
              //       >
              //         {convertToLocalDateTime(props.value)}
              //       </p>
              //     );
              //   },
              // },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        <SolidButton
                          text={
                            <>
                              <Pencil color="#2a9bf2" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#2a9bf2"
                          borderHoverColor="#2a9bf2"
                          onClick={() => {
                            navigate(`/giftcards/buy/edit/${row.values._id}`);
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#2a9bf2"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#2a9bf2"
                        />
                        <SolidButton
                          text={
                            <>
                              {row?.original?.hide ? (
                                <EyeOff color="#58c283" size={15} />
                              ) : (
                                <Eye color="#58c283" size={15} />
                              )}
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#58c283"
                          borderHoverColor="#58c283"
                          onClick={() => {
                            handleHideAction(
                              { hide: !row?.original?.hide },
                              row?.values?._id
                            );
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#58c283"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#58c283"
                        />
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No giftcard added yet"
            desc="Please add giftcard to populate this area"
          />
        )}
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  loading: state.giftcard.allGiftcardBuyLoading,
  error: state.giftcard.allGiftcardBuyError,
  giftcards: state.giftcard.allGiftcardBuy,
});

const mapDispatch = (dispatch) => ({
  setGetAllGiftcardBuy: () => dispatch(getAllGiftcardBuy()),
  setHideAGiftcardBuy: (data, id) => dispatch(hideAGiftcardBuy(data, id)),
});

export default connect(mapState, mapDispatch)(AllBuyGiftcard);
