import React, { useEffect } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import { updateWalletTxnStatus } from "../../redux/actions/walletActions";
import editWalletSettingsSchema from "../../validators/editWalletSettingsValidator";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 550px;
  width: 100%;
  padding: 0rem 1rem;

  @media only screen and (max-width: 700px) {
    max-width: unset;
  }
`;

const ParentWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const OtcFormWrapper = styled(Form)``;

const OtcWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const OtcTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 1.5rem 0 1rem;
`;

const OtcTitle2 = styled(OtcTitle)`
  padding: 1rem 0;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ViewDepositWallet = ({
  setUpdateWalletDeposit,
  updateNairaWalletLoading,
  updateNairaWalletSuccess,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      navigate("/naira-wallet/transactions");
      toast.warning("Select a transaction", {
        toastId: "Select a transaction",
      });
    }
  }, [location.state, navigate]);
  const btnClick = async (text, agentName) => {
    const response = await setUpdateWalletDeposit(
      { status: text, agentName },
      location.state.txn._id
    );

    if (
      response &&
      response.status === 201 &&
      response.message === "Transaction status updated"
    ) {
      return navigate("/naira-wallet/transactions");
    }
  };
  return (
    <DashboardLayout pageTitle="View Naira Wallet">
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: location.state?.userId.username,
          transactionId: location.state?.txn.txnId,
          amount: location.state?.txn.amount,
          otherAmount: location.state?.txn.charge,
          date: new Date(location.state?.createdAt).toDateString(),
          agentName:
            location.state?.txn.agentName || localStorage.getItem("name"),
          senderBankName: location.state?.txn.fromBank,
          senderAccountNumber: location.state?.txn.fromAccount,
          senderAccountName: location.state?.txn.fromName,
          toBankName: location.state?.txn.receiverBank,
          toAccountNumber: location.state?.txn.receiverAccount,
          toAccountName: location.state?.txn.receiverName,
        }}
        validationSchema={editWalletSettingsSchema}
        validateOnMount={false}
        onSubmit={async (values, { resetForm }) => {
          // resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue,
          setFieldTouched,
          isValid,
          touched,
        }) => (
          <>
            <form noValidate onSubmit={handleSubmit}>
              <ParentWrapper>
                <Wrapper>
                  <OtcFormWrapper>
                    <OtcWrapper>
                      <OtcTitle>Deposit</OtcTitle>
                      <InputWrapper>
                        <InputLabel>Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          disabled={true}
                          name="name"
                          placeholder="Enter name"
                          showMessage
                          message={`${errors["name"] ? errors["name"] : ""}`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Transaction ID</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          disabled={true}
                          type="text"
                          name="transactionId"
                          placeholder="Enter Transaction ID"
                          showMessage
                          message={`${
                            errors["transactionId"]
                              ? errors["transactionId"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Amount</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          disabled={true}
                          name="amount"
                          placeholder="Enter amount"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["amount"] ? errors["amount"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Other Amount</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="otherAmount"
                          disabled={true}
                          placeholder="Enter other amount"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["otherAmount"] ? errors["otherAmount"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Date</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          disabled={true}
                          name="date"
                          placeholder="Enter date"
                          showMessage
                          message={`${errors["date"] ? errors["date"] : ""}`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Agent</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          disabled={true}
                          type="text"
                          name="agentName"
                          placeholder="Enter Agent Name"
                          showMessage
                          message={`${
                            errors["agentName"] ? errors["agentName"] : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                </Wrapper>
                <Wrapper>
                  <OtcFormWrapper>
                    <OtcTitle>Bank details</OtcTitle>
                    <OtcWrapper>
                      <InputWrapper>
                        <InputLabel>Bank Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="senderBankName"
                          disabled={true}
                          placeholder="Primary bank name"
                          showMessage
                          message={`${
                            errors["senderBankName"]
                              ? errors["senderBankName"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Number</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          disabled={true}
                          name="senderAccountNumber"
                          placeholder="Enter sender account number"
                          showMessage
                          message={`${
                            errors["senderAccountNumber"]
                              ? errors["senderAccountNumber"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          disabled={true}
                          onChange={handleChange}
                          type="text"
                          name="senderAccountName"
                          placeholder="Enter sender account name"
                          showMessage
                          message={`${
                            errors["senderAccountName"]
                              ? errors["senderAccountName"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                  <OtcFormWrapper>
                    <OtcTitle2>To</OtcTitle2>
                    <OtcWrapper>
                      <InputWrapper>
                        <InputLabel>Bank Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          disabled={true}
                          onChange={handleChange}
                          type="text"
                          name="toBankName"
                          placeholder="Enter bank name"
                          showMessage
                          message={`${
                            errors["toBankName"] ? errors["toBankName"] : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Number</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          disabled={true}
                          onChange={handleChange}
                          type="text"
                          name="toAccountNumber"
                          placeholder="Enter Account Number"
                          showMessage
                          message={`${
                            errors["toAccountNumber"]
                              ? errors["toAccountNumber"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          disabled={true}
                          onChange={handleChange}
                          type="text"
                          name="toAccountName"
                          placeholder="Enter Account Name"
                          showMessage
                          message={`${
                            errors["toAccountName"]
                              ? errors["toAccountName"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                </Wrapper>
              </ParentWrapper>
              <ButtonWrapper>
                <SolidButton
                  onClick={() => {
                    btnClick("success", values.agentName);
                  }}
                  text={
                    updateNairaWalletLoading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Successful"
                    )
                  }
                  margin="2rem 0"
                  type="submit"
                  weighty="500"
                  textFontSize="16px"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  maxWidth="150px"
                  specifyPadding="1rem 0rem"
                  color="#fff"
                  borderRadius="7px"
                  backColor="#2BC155"
                  backgroundHoverColor="#2BC155"
                  textHoverColor="#fff"
                  disabled={updateNairaWalletLoading ? true : false}
                />
                <SolidButton
                  onClick={() => {
                    btnClick("declined", values.agentName);
                  }}
                  text={
                    updateNairaWalletLoading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Declined"
                    )
                  }
                  margin="2rem 0"
                  type="submit"
                  weighty="500"
                  textFontSize="16px"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  maxWidth="150px"
                  specifyPadding="1rem 0rem"
                  color="#fff"
                  borderRadius="7px"
                  backColor="#C12B2B"
                  backgroundHoverColor="#C12B2B"
                  textHoverColor="#fff"
                  disabled={updateNairaWalletLoading ? true : false}
                />
              </ButtonWrapper>
            </form>
          </>
        )}
      </Formik>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  updateNairaWalletLoading: state.wallet.updateNairaWalletLoading,
  updateNairaWalletData: state.wallet.updateNairaWalletSuccess,
});

const mapDispatch = (dispatch) => ({
  setUpdateWalletDeposit: (data, id) =>
    dispatch(updateWalletTxnStatus(data, id)),
});

export default connect(mapState, mapDispatch)(ViewDepositWallet);
