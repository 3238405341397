import {
  LOADING,
  AUTHENTICATED,
  LOGOUT,
  ERROR,
  USER_ROLE_SUCCESS,
  LOGIN_DATA,
} from "./types";

const initialState = {
  loading: null,
  authenticated: null,
  error: null,
  userRole: null,
  data: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case AUTHENTICATED:
      return { ...state, authenticated: action.payload || true };
    case LOGIN_DATA:
      return { ...state, data: action.payload };
    case USER_ROLE_SUCCESS:
      return { ...state, userRole: action.payload };
    case ERROR:
      return { ...state, error: action.payload };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
