import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AuthTemplateCmp from "../../components/AuthTemplateCmp/AuthTemplateCmp";
import ResetPasswordForm from "../../components/FormsCmp/ResetPasswordForm";
import { verifyResetPassword } from "../../redux/actions/authActions";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ResetPassword = ({ loading, error, setVerifyResetPassword }) => {
  const { token } = useParams();

  const navigate = useNavigate();
  const [verifyResetToken, setVerifyResetToken] = useState();

  useEffect(() => {
    async function callVerifyReset() {
      const response = await setVerifyResetPassword(token);
      // console.log(response, error);
      if (response && response.status === 200) {
        setVerifyResetToken(response.data.token);
        // return toast.success("Email verification successful", {
        //   toastId: "Email Success",
        // });
      } else {
        navigate("/login");
      }
    }

    callVerifyReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Content = () => {
    return (
      <>
        <Wrapper>
          <ResetPasswordForm
            verifyResetToken={verifyResetToken && verifyResetToken}
          />
        </Wrapper>
      </>
    );
  };
  return (
    <>
      <AuthTemplateCmp
        pageTitle="Reset Password"
        pageDesc="Enter your new password for your account and confirm the password"
        content={<Content />}
        mobilePadding={"0rem 1rem 2rem"}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatch = (dispatch) => ({
  setVerifyResetPassword: (token) => dispatch(verifyResetPassword(token)),
});

export default connect(mapState, mapDispatch)(ResetPassword);
