import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AllBuyGiftcardCmp from "./AllBuyGiftcard";

const Wrapper = styled.div``;

const GiftcardBuy = () => {
  return (
    <>
      <DashboardLayout pageTitle="Buy Giftcard">
        <Wrapper>
          <AllBuyGiftcardCmp />
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default GiftcardBuy;
