import { Eye } from "@styled-icons/ionicons-outline/Eye";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import EmptyTableDataCmp from "../../components/EmptyTableDataCmp/EmptyTableDataCmp";
import TableCmp from "../../components/TableCmp/TableCmp";
import { convertToLocalDateTime } from "../../utils/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../../redux/actions/userActions";
import UserPlaceholder from "../../assets/user-placeholder.jpg";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const WrapperImageText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BlogImgWrapper = styled(LazyLoadImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const AllUserCmp = ({ setGetAllUser }) => {
  const [userData, setUserData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getAllUser = async () => {
      setLoadingState(true);
      const response = await setGetAllUser(currentPage);

      setUserData(response.data.results);
      setDataCount(response.data.count);
      setRefresh(false);
      setLoadingState(false);
    };
    getAllUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, currentPage]);

  const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        {loadingState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={24}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : userData.length > 0 ? (
          <TableCmp
            showActionBtns={true}
            enablePagination={true}
            showSearch={true}
            showCustomFilter={true}
            searchPlaceholderText={"Search here"}
            containerHeight="350px"
            defaultPageSize={50}
            data={[...userData]}
            dataCount={dataCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            columns={[
              {
                Header: "",
                accessor: "profilePicUrl",
                id: "picture",
                maxWidth: 300,
                minWidth: 100,
                width: 100,
                Cell: (props) => {
                  return (
                    <WrapperImageText>
                      <BlogImgWrapper
                        src={
                          props.row.original.profilePicUrl || UserPlaceholder
                        }
                        alt={props.row.original.username}
                        effect="blur"
                      />
                    </WrapperImageText>
                  );
                },
              },
              {
                Header: "Account Type",
                accessor: "userType",
                maxWidth: 300,
                minWidth: 180,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Email",
                accessor: "email",
                maxWidth: 300,
                minWidth: 100,
                width: 250,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Username",
                maxWidth: 300,
                minWidth: 100,
                width: 250,
                Cell: ({ row }) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {row.original?.username
                        ? row.original?.username
                        : row.original.businessName}
                    </p>
                  );
                },
              },
              {
                Header: "Phone Number",
                accessor: "phone",
                maxWidth: 300,
                minWidth: 100,
                width: 230,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#3E4954",
                      }}
                    >
                      {props.value}
                    </p>
                  );
                },
              },
              {
                Header: "Date Joined",
                accessor: "createdAt",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        margin: "0",
                        fontWeight: "400",
                        fontSize: "13px",
                        color: "#3E4954",
                      }}
                    >
                      {convertToLocalDateTime(props.value)}
                    </p>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "_id",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: ({ row }) => {
                  return (
                    <>
                      <ActionsWrapper>
                        <SolidButton
                          text={
                            <>
                              <Eye color="#58c283" size={15} />
                            </>
                          }
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          borderColor="#58c283"
                          borderHoverColor="#58c283"
                          onClick={() => {
                            navigate(`/user/${row.values._id}`);
                          }}
                          specifyPadding="0.5rem 0.3rem"
                          color="#58c283"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="35px"
                          borderRadius="6px"
                          backColor="transparent"
                          backgroundHoverColor="transparent"
                          textHoverColor="#58c283"
                        />
                      </ActionsWrapper>
                    </>
                  );
                },
              },
            ]}
          />
        ) : (
          <EmptyTableDataCmp
            title="No user signed up yet"
            desc="No user info"
          />
        )}
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  loading: state.user.allUserLoading,
  error: state.user.allUserError,
  users: state.user.allUser,
});

const mapDispatch = (dispatch) => ({
  setGetAllUser: (page) => dispatch(getAllUsers(page)),
});

export default connect(mapState, mapDispatch)(AllUserCmp);
