import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import CustomTextAreaCmp from "../../components/InputsCmp/CustomTextAreaCmp";
import {
  getATicket,
  updateTicketStatus,
} from "../../redux/actions/ticketActions";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import updateStatusTicketSchema from "../../validators/updateStatusTicketValidator";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 550px;
  width: 100%;
  padding: 0rem 1rem;
`;

const NewsFormWrapper = styled(Form)``;

const NewsWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;

  padding-bottom: 2rem;
`;

const ImagePreviewWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
`;

const Image = styled(LazyLoadImage)`
  background-size: cover;
  width: 100%;
  height: 200px;
`;

const NewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const ViewTicket = ({
  aTicketLoading,
  setGetATicket,
  setUpdateTicketStatus,
  updateTicketStatusLoading,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [aTicketData, setATicketData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getATicket = async () => {
      const response = await setGetATicket(id);

      setATicketData(response.data);
      setRefresh(false);
    };
    if (refresh) {
      getATicket();
    } else {
      getATicket();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <DashboardLayout pageTitle="View Ticket">
      <Wrapper>
        <Formik
          enableReinitialize={true}
          initialValues={{
            txnId: aTicketData?.txnId,
            email: aTicketData?.email,
            description: aTicketData?.description,
            status: aTicketData?.status,
          }}
          validationSchema={updateStatusTicketSchema}
          onSubmit={async (values, { resetForm }) => {
            // resetForm();
            const response = await setUpdateTicketStatus(values.status, id);
            if (
              response &&
              response.status === 200 &&
              response.message === "Ticket updated successfully"
            ) {
              return navigate("/help-center/tickets");
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <NewsFormWrapper noValidate onSubmit={handleSubmit}>
                <NewsTitle>Ticket</NewsTitle>
                <NewsWrapper>
                  {aTicketData?.ticketPicUrl && (
                    <ImagePreviewWrapper>
                      <Image
                        src={aTicketData?.ticketPicUrl}
                        alt="Preview"
                        effect="blur"
                      />
                    </ImagePreviewWrapper>
                  )}
                  <InputWrapper>
                    <InputLabel>Transaction ID</InputLabel>
                    <CustomInputCmp
                      required={true}
                      values={values}
                      onChange={handleChange}
                      type="text"
                      name="txnId"
                      placeholder="Enter transaction ID"
                      showMessage
                      message={`${errors["txnId"] ? errors["txnId"] : ""}`}
                      disabled
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Email Address</InputLabel>
                    <CustomInputCmp
                      background="#fff"
                      required={true}
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      values={values}
                      onChange={handleChange}
                      message={`${errors["email"] ? errors["email"] : ""}`}
                      showMessage={true}
                      disabled
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Description</InputLabel>
                    <CustomTextAreaCmp
                      background="#fff"
                      as="textarea"
                      rows={4}
                      required={true}
                      type="text"
                      name="description"
                      placeholder="Describe the problem"
                      values={values}
                      onChange={handleChange}
                      message={`${
                        errors["description"] ? errors["description"] : ""
                      }`}
                      showMessage={true}
                      disabled
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Status</InputLabel>
                    <CustomInputSelectCmp
                      background="rgba(225, 235, 245, 0.1)"
                      required={true}
                      name="status"
                      placeholder="Select Status"
                      values={values}
                      onChange={handleChange}
                      data={["Unresolved", "Pending", "Resolved"]}
                      message={`${errors["status"] ? errors["status"] : ""}`}
                      showMessage={true}
                    />
                  </InputWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      text={
                        updateTicketStatusLoading ? (
                          <>
                            <SpinnerCmp
                              enabled={true}
                              color={appTheme.COLORS.bluePrimary}
                              size={20}
                              secondaryColor={appTheme.COLORS.white}
                            />
                          </>
                        ) : (
                          "Update"
                        )
                      }
                      margin="2rem 0"
                      type="submit"
                      weighty="500"
                      textFontSize="16px"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      maxWidth="200px"
                      specifyPadding="1rem 1.5rem"
                      color="#fff"
                      borderRadius="7px"
                      backColor="#016CBA"
                      backgroundHoverColor="#016CBA"
                      textHoverColor="#fff"
                      disabled={updateTicketStatusLoading ? true : false}
                    />
                  </ButtonWrapper>
                </NewsWrapper>
              </NewsFormWrapper>
            </>
          )}
        </Formik>
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  aTicketLoading: state.ticket.aTicketLoading,
  updateTicketStatusLoading: state.ticket.updateTicketStatusLoading,
});

const mapDispatch = (dispatch) => ({
  setGetATicket: (data) => dispatch(getATicket(data)),
  setUpdateTicketStatus: (status, id) =>
    dispatch(updateTicketStatus(status, id)),
});

export default connect(mapState, mapDispatch)(ViewTicket);
