import {
  ALL_PM_SETTINGS_ERROR,
  ALL_PM_SETTINGS_LOADING,
  ALL_PM_SETTINGS_SUCCESS,
  A_PM_SETTING_ERROR,
  A_PM_SETTING_LOADING,
  A_PM_SETTING_SUCCESS,
  EDIT_A_PM_SETTING_ERROR,
  EDIT_A_PM_SETTING_LOADING,
  EDIT_A_PM_SETTING_SUCCESS,
} from "./types";

const initialState = {
  allPmLoading: null,
  allPm: null,
  allPmError: null,
  aPmLoading: null,
  aPm: null,
  aPmError: null,
  editAPmLoading: null,
  editAPmSuccess: null,
  editAPmError: null,
};

export default function pmReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_PM_SETTINGS_LOADING:
      return { ...state, allPmLoading: action.payload };
    case ALL_PM_SETTINGS_SUCCESS:
      return { ...state, allPm: action.payload };
    case ALL_PM_SETTINGS_ERROR:
      return { ...state, allPmError: action.payload };
    case EDIT_A_PM_SETTING_LOADING:
      return { ...state, editAPmLoading: action.payload };
    case EDIT_A_PM_SETTING_SUCCESS:
      return { ...state, editAPmSuccess: action.payload };
    case EDIT_A_PM_SETTING_ERROR:
      return { ...state, editAPmError: action.payload };
    case A_PM_SETTING_LOADING:
      return { ...state, aPmLoading: action.payload };
    case A_PM_SETTING_SUCCESS:
      return { ...state, aPm: action.payload };
    case A_PM_SETTING_ERROR:
      return { ...state, aPmError: action.payload };
    default:
      return state;
  }
}
