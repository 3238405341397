import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HeaderCmp from "../HeaderCmp/HeaderCmp";
import SidebarCmp from "../SidebarCmp/SidebarCmp";

const DashboardContainer = styled(Container)`
  padding: 0;
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
`;

const MainContentWrapper = styled.div`
  flex: 0 0 83%;
  background-color: #f5f6f8;
  min-height: 100vh;
  max-width: 83%;
  width: 100%;
  margin-left: 17%;

  @media only screen and (max-width: 991px) {
    flex: 0 0 75%;
    max-width: 75%;
    margin-left: 25%;
  }

  @media only screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
  }
`;

const MainContentDiv = styled.div`
  padding: 1.5rem 1.5rem;
  max-width: 1500px;
  margin: 0 auto;

  /* @media only screen and (min-width: 1500px) {
    padding: 1.5rem 0;
  } */

  @media only screen and (max-width: 374px) {
    padding: 1rem;
  }
`;

const DashboardLayout = (props) => {
  return (
    <>
      <DashboardContainer fluid>
        <Wrapper>
          <SidebarCmp />
          <MainContentWrapper>
            <HeaderCmp
              backButton={props.backButton}
              backText={props.backText}
              pageTitle={props.pageTitle}
              desc={props.desc}
            />
            <MainContentDiv>{props.children}</MainContentDiv>
          </MainContentWrapper>
        </Wrapper>
      </DashboardContainer>
    </>
  );
};

export default DashboardLayout;
