import React from "react";
import styled from "styled-components";
import ModalCmp from "../ModalCmp/ModalCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import JessieLogo from "../../assets/logo-color.svg";
import Success from "../../assets/success.svg";
import appTheme from "../../constants/theme";
import SolidButton from "../ButtonCmp/Button";

const Wrapper = styled.div`
  text-align: center;
  padding: ${(props) =>
    props.wrapperPadding ? props.wrapperPadding : "2rem 1rem"};
`;

const ImgWrapper = styled.div`
  padding-bottom: 1.5rem;
`;

const LogoImg = styled(LazyLoadImage)`
  width: 137px;
  height: 46px;
`;

const SuccessImgWrapper = styled.div`
  padding-bottom: 1.5rem;
`;

const SuccessImg = styled(LazyLoadImage)``;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  padding-bottom: 0.8px;
`;

const Text = styled.p`
  font-weight: ${appTheme.WEIGHT.small2};
  color: #c4c4c4;
  font-size: 14px;
  max-width: ${(props) =>
    props.titleMaxWidth ? props.titleMaxWidth : "300px"};
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
`;

const SuccessModal = (props) => {
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: props.maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Success"
        id="success"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={props.showCloseIcon}
      >
        <Wrapper wrapperPadding={props.wrapperPadding}>
          {props.showLogo && (
            <ImgWrapper>
              <LogoImg src={JessieLogo} alt="Jessie" effect="blur" />
            </ImgWrapper>
          )}
          <SuccessImgWrapper>
            <SuccessImg
              src={props.successImgUrl || Success}
              alt="Jessie"
              effect="blur"
            />
          </SuccessImgWrapper>
          {props.heading && <Title>{props.heading}</Title>}
          <Text titleMaxWidth={props.titleMaxWidth}>{props.title}</Text>
          {props.buttonCloseText && (
            <ButtonWrapper>
              <SolidButton
                onClick={props.onRequestClose}
                text={props.buttonCloseText}
                type="submit"
                weighty="500"
                textFontSize="14px"
                specifyPadding="0.8rem 1.5rem"
                color="#fff"
                widthWebkit={props.actionButtonWidth || "150px"}
                widthMoz={props.actionButtonWidth || "150px"}
                widthNormal={props.actionButtonWidth || "150px"}
                borderRadius="8px"
                backColor="#1a8fdd"
                backgroundHoverColor="#1a8fdd"
                textHoverColor="#fff"
                margin="0rem auto 0"
              />
            </ButtonWrapper>
          )}
        </Wrapper>
      </ModalCmp>
    </>
  );
};

export default SuccessModal;
