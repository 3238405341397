import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SolidButton from "../../components/ButtonCmp/Button";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../../components/InputsCmp/CustomInputCmp";
import CustomInputSelectCmp from "../../components/InputsCmp/CustomInputSelectCmp";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import { Formik } from "formik";
import { connect } from "react-redux";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import {
  getListOfBanks,
  getWalletSettings,
  updateWalletSettings,
} from "../../redux/actions/walletActions";
import editWalletSettingsSchema from "../../validators/editWalletSettingsValidator";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1.5px 2.6px 10px rgba(119, 119, 119, 0.1);
  max-width: 550px;
  width: 100%;
  padding: 0rem 1rem;

  @media only screen and (max-width: 700px) {
    max-width: unset;
  }
`;

const ParentWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const OtcFormWrapper = styled(Form)``;

const OtcWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const OtcTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1.4rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 1.5rem 0 1rem;
`;

const OtcTitle2 = styled(OtcTitle)`
  padding: 1rem 0;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const EditWalletSettings = ({
  getSettingsData,
  updateSettingsLoading,
  updateSettingsData,
  setUpdateWalletSettings,
  setGetWalletSettings,
  setGetBankList,
  bankListSuccess,
}) => {
  const [settingsData, setSettingsData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getASettingsData = async () => {
      const response = await setGetWalletSettings();
      await setGetBankList();

      setSettingsData(response.data);
      setRefresh(false);
    };
    if (refresh) {
      getASettingsData();
    } else {
      getASettingsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, setUpdateWalletSettings]);
  return (
    <DashboardLayout pageTitle="Naira Wallet Charges">
      <Formik
        enableReinitialize={true}
        initialValues={{
          nairaWalletDepositFee: settingsData.nairaWalletDepositFee,
          peerWithdrawalFee: settingsData.peerWithdrawalFee,
          jessieUserFee: settingsData.jessieUserFee,
          primaryAccountNumber: settingsData.primaryAccountNumber,
          primaryAccountName: settingsData.primaryAccountName,
          primaryBankName: settingsData.primaryBankName,
          primaryBankCode: settingsData.primaryBankCode,
          secondaryAccountNumber: settingsData.secondaryAccountNumber,
          secondaryAccountName: settingsData.secondaryAccountName,
          secondaryBankName: settingsData.secondaryBankName,
          secondaryBankCode: settingsData.secondaryBankCode,
          activateBuyCrypto: settingsData.activateBuyCrypto,
          activateSendCrypto: settingsData.activateSendCrypto,
        }}
        validationSchema={editWalletSettingsSchema}
        validateOnMount={false}
        onSubmit={async (values, { resetForm }) => {
          console.log(values);
          // resetForm();
          const response = await setUpdateWalletSettings({ ...values });

          if (
            response &&
            response.status === 200 &&
            response.message === "Setting updated successfully"
          ) {
            return;
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue,
          setFieldTouched,
          isValid,
          touched,
        }) => (
          <>
            <form noValidate onSubmit={handleSubmit}>
              <ParentWrapper>
                <Wrapper>
                  <OtcFormWrapper>
                    <OtcWrapper>
                      <OtcTitle>Deposit Fee</OtcTitle>
                      <InputWrapper>
                        <InputLabel>Amount</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="nairaWalletDepositFee"
                          placeholder="Enter deposit fee"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["nairaWalletDepositFee"]
                              ? errors["nairaWalletDepositFee"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <OtcTitle>P2P Withdrawal</OtcTitle>
                      <InputWrapper>
                        <InputLabel>Amount</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="peerWithdrawalFee"
                          placeholder="Enter peer withdrawal fee"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["peerWithdrawalFee"]
                              ? errors["peerWithdrawalFee"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <OtcTitle>Jessie User</OtcTitle>
                      <InputWrapper>
                        <InputLabel>Amount</InputLabel>
                        <CustomPriceInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="jessieUserFee"
                          placeholder="Enter Jessie user fee"
                          values={values}
                          setFieldValue={setFieldValue}
                          showMessage
                          message={`${
                            errors["jessieUserFee"]
                              ? errors["jessieUserFee"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <OtcTitle>Crypto Settings</OtcTitle>
                      <Form.Check
                        type="switch"
                        id="activateBuyCrypto"
                        name="activateBuyCrypto"
                        label="Activate Buy Crypto"
                        checked={values.activateBuyCrypto}
                        onChange={(e) => {
                          setFieldValue("activateBuyCrypto", e.target.checked);
                        }}
                      />
                      <Form.Check
                        type="switch"
                        id="activateSendCrypto"
                        name="activateSendCrypto"
                        label="Activate Send Crypto"
                        checked={values.activateSendCrypto}
                        onChange={(e) => {
                          setFieldValue("activateSendCrypto", e.target.checked);
                        }}
                      />
                    </OtcWrapper>
                  </OtcFormWrapper>
                </Wrapper>
                <Wrapper>
                  <OtcFormWrapper>
                    <OtcTitle>Bank details</OtcTitle>
                    <OtcWrapper>
                      <InputWrapper>
                        <InputLabel>Bank Name</InputLabel>
                        <CustomInputSelectCmp
                          required={true}
                          name="primaryBankName"
                          placeholder="Select Bank"
                          values={values}
                          onChange={handleChange}
                          data={
                            bankListSuccess &&
                            bankListSuccess.length > 0 &&
                            bankListSuccess.map((v, i) => {
                              return v.name;
                            })
                          }
                          showMessage
                          message={`${
                            errors["primaryBankName"]
                              ? errors["primaryBankName"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper style={{ display: "none" }}>
                        <CustomInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="primaryBankCode"
                          placeholder="Bank Code"
                          disabled={true}
                          value={
                            (values.primaryBankCode =
                              bankListSuccess &&
                              bankListSuccess.length > 0 &&
                              bankListSuccess.filter((obj) => {
                                return obj.name === values.primaryBankName;
                              })[0]?.code)
                          }
                          onChange={handleChange}
                          message={`${
                            errors["primaryBankCode"]
                              ? errors["primaryBankCode"]
                              : ""
                          }`}
                          showMessage={false}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Number</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="primaryAccountNumber"
                          placeholder="Enter primary account number"
                          showMessage
                          message={`${
                            errors["primaryAccountNumber"]
                              ? errors["primaryAccountNumber"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="primaryAccountName"
                          placeholder="Enter primary account name"
                          showMessage
                          message={`${
                            errors["primaryAccountName"]
                              ? errors["primaryAccountName"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                  <OtcFormWrapper>
                    <OtcTitle2>Secondary account</OtcTitle2>
                    <OtcWrapper>
                      <InputWrapper>
                        <InputLabel>Bank Name</InputLabel>
                        <CustomInputSelectCmp
                          required={true}
                          name="secondaryBankName"
                          placeholder="Select Bank"
                          values={values}
                          onChange={handleChange}
                          data={
                            bankListSuccess &&
                            bankListSuccess.length > 0 &&
                            bankListSuccess.map((v, i) => {
                              return v.name;
                            })
                          }
                          showMessage
                          message={`${
                            errors["secondaryBankName"]
                              ? errors["secondaryBankName"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper style={{ display: "none" }}>
                        <CustomInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="secondaryBankCode"
                          placeholder="Bank Code"
                          disabled={true}
                          value={
                            (values.secondaryBankCode =
                              bankListSuccess &&
                              bankListSuccess.length > 0 &&
                              bankListSuccess.filter((obj) => {
                                return obj.name === values.secondaryBankName;
                              })[0]?.code)
                          }
                          onChange={handleChange}
                          message={`${
                            errors["secondaryBankCode"]
                              ? errors["secondaryBankCode"]
                              : ""
                          }`}
                          showMessage={false}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Number</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="secondaryAccountNumber"
                          placeholder="Enter Account Number"
                          showMessage
                          message={`${
                            errors["secondaryAccountNumber"]
                              ? errors["secondaryAccountNumber"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputLabel>Account Name</InputLabel>
                        <CustomInputCmp
                          required={true}
                          values={values}
                          onChange={handleChange}
                          type="text"
                          name="secondaryAccountName"
                          placeholder="Enter Account Name"
                          showMessage
                          message={`${
                            errors["secondaryAccountName"]
                              ? errors["secondaryAccountName"]
                              : ""
                          }`}
                        />
                      </InputWrapper>
                    </OtcWrapper>
                  </OtcFormWrapper>
                </Wrapper>
              </ParentWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    updateSettingsLoading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Update"
                    )
                  }
                  margin="2rem 0"
                  type="submit"
                  weighty="500"
                  textFontSize="16px"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  maxWidth="150px"
                  specifyPadding="1rem 0rem"
                  color="#016CBA"
                  borderRadius="7px"
                  backColor="transparent"
                  backgroundHoverColor="transparent"
                  textHoverColor="#016CBA"
                  borderColor="#016CBA"
                  borderHoverColor="#016CBA"
                  disabled={updateSettingsLoading ? true : false}
                />
              </ButtonWrapper>
            </form>
          </>
        )}
      </Formik>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  getSettingsData: state.wallet.getNairaWalletSettingsSuccess,
  updateSettingsLoading: state.wallet.updateNairaWalletSettingsLoading,
  updateSettingsData: state.wallet.updateNairaWalletSettingsSuccess,
  bankListLoading: state.wallet.bankListLoading,
  bankListSuccess: state.wallet.bankListSuccess,
  bankListError: state.wallet.bankListError,
});

const mapDispatch = (dispatch) => ({
  setGetWalletSettings: () => dispatch(getWalletSettings()),
  setUpdateWalletSettings: (data) => dispatch(updateWalletSettings(data)),
  setGetBankList: () => dispatch(getListOfBanks()),
});

export default connect(mapState, mapDispatch)(EditWalletSettings);
