/* eslint-disable no-useless-escape */
import * as yup from "yup";

const createCareerSchema = yup.object().shape({
  editorState: yup
    .object()
    .test("has text", "Cannot save an empty note", (value) => {
      return value.getCurrentContent().hasText();
    })
    .required("This field is required."),
  jobTitle: yup.string().required("Please add job title"),
  jobType: yup.string().required("Please add job type"),
  category: yup.string().required("Please add job category"),
  location: yup.string().required("Please add job location"),
});

export default createCareerSchema;
