/* eslint-disable no-useless-escape */
import * as yup from "yup";

const createNewsSchema = yup.object().shape({
  editorState: yup
    .object()
    .test("has text", "Cannot save an empty note", (value) => {
      return value.getCurrentContent().hasText();
    })
    .required("This field is required."),
  file: yup
    .mixed()
    .required("Please add an image")
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value[0]?.size) {
        return value[0].size <= 1000000;
      }
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value[0]?.type) {
        if (
          [
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "image/png",
            "image/svg+xml",
          ].includes(value[0].type)
        ) {
          return true;
        }
      }
    }),
  title: yup.string().required("Please add news title"),
  tags: yup
    .array()
    .required("Select/Add a tag")
    .min(1, "Select/Add a tag")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .test(
      "len",
      "Select/Add minimum of 2 tags",
      (val) => val && val.length >= 2
    ),
  snippet: yup
    .string()
    .required("Please add a snippet")
    .test(
      "len",
      "Number of characters is more than 160",
      (val) => val && val.length < 160
    ),
  showOnHome: yup.string().required("Select an option"),
});

export default createNewsSchema;
