import React, { memo, useState } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProfilePicSvg from "../../assets/pic.svg";
import { ChevronUp } from "@styled-icons/boxicons-regular";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import SettingsIconImg from "../../assets/settings-icon.svg";
import LogoutIconImg from "../../assets/logout-icon.svg";
import { useNavigate } from "react-router-dom";
import { Menu } from "@styled-icons/boxicons-regular";
import SidebarCmp from "../SidebarCmp/SidebarCmp";
import { useWindowSize } from "react-use";
import { ChevronBackCircle } from "@styled-icons/ionicons-outline";
import { connect } from "react-redux";

const HeaderWrapper = styled.div`
  padding: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 1020;
  background-color: rgb(245, 246, 248);

  @media only screen and (max-width: 767px) {
    background-color: rgba(245, 246, 248, 0.5);
    backdrop-filter: blur(3px);
  }

  @media only screen and (max-width: 374px) {
    padding: 1.5rem 1rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  margin: 0 auto;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GreetingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Hamburger = styled(Menu)`
  display: none;

  @media only screen and (max-width: 767px) {
    display: unset;
    cursor: pointer;
  }
`;

const BackIcon = styled(ChevronBackCircle)`
  cursor: pointer;
`;

const HelloText = styled.h2`
  margin: 0;
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 1.5rem;

  @media only screen and (max-width: 767px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const WelcomeText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: #c4c4c4;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const OtherSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const ProfilePicWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const ProfilePic = styled(LazyLoadImage)`
  background-size: cover;
`;

const ProfileDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    gap: 10px;
  }
`;

const ProfileNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ProfileName = styled.h2`
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  color: #000;
  opacity: 80%;
  margin: 0;
`;

const RoleName = styled.p`
  color: #8f8f8f;
  font-size: 12px;
  font-weight: 400;
`;

const DropdownIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 3px;
  width: 21px;
  height: 17px;
  transform: ${(props) => props.visible === true && "rotate(180deg)"};
`;

const DropdownIconImg = styled(ChevronUp)``;

const DropdownMenuWrapper = styled.div`
  width: 156px;
  height: 114px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 1rem;
`;

const DropdownMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.6rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(243, 243, 243, 0.7);
    border-radius: 8px;
  }
`;

const DropdownItemIcon = styled.img``;

const DropdownItemName = styled.h2`
  font-weight: ${appTheme.WEIGHT.small};
  font-size: 16px;
  margin: 0;
  color: #000;
  color: rgba(196, 196, 196, 1);
  opacity: 80%;
`;

const HeaderCmp = ({
  backButton,
  backText,
  desc,
  pageTitle,
  userRole,
  loginData,
}) => {
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const [menuToggle, setMenuToggle] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState();

  const DropdownArr = [
    { name: "Settings", path: "/dashboard", icon: `${SettingsIconImg}` },
    { name: "Log out", path: "/logout", icon: `${LogoutIconImg}` },
  ];

  const handleToggleOnMobile = (val) => {
    setMenuToggle(val);
  };
  const DropdownMenu = () => {
    return (
      <>
        <DropdownMenuWrapper>
          {DropdownArr.map((v, i) => {
            return (
              <>
                <DropdownMenuItem
                  key={i}
                  onClick={() => {
                    navigate(v.path);
                  }}
                >
                  <DropdownItemIcon src={v.icon} alt={v.name} />
                  <DropdownItemName>{v.name}</DropdownItemName>
                </DropdownMenuItem>
              </>
            );
          })}
        </DropdownMenuWrapper>
      </>
    );
  };
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <TitleSection>
            {backButton === false ||
              (!backButton && (
                <>
                  <Hamburger
                    size={22}
                    color="#323232"
                    onClick={() => {
                      setMenuToggle(!menuToggle);
                    }}
                  />
                  <GreetingWrapper>
                    <HelloText>{pageTitle}</HelloText>
                    {desc && <WelcomeText>{desc}</WelcomeText>}
                  </GreetingWrapper>
                </>
              ))}

            {backButton && backText && (
              <>
                <BackIcon
                  size={24}
                  color="#ADB5BD"
                  onClick={() => navigate(-1)}
                />
                <HelloText>Back to Wallet</HelloText>
              </>
            )}
          </TitleSection>
          <OtherSectionWrapper>
            <ProfileWrapper>
              <ProfilePicWrapper>
                <ProfilePic src={ProfilePicSvg} alt="Profile" effect="blur" />
              </ProfilePicWrapper>
              <Dropdown
                trigger={["click"]}
                overlay={DropdownMenu}
                animation="slide-up"
                overlayStyle={{ fontFamily: '"Nunito", sans-serif' }}
                onVisibleChange={(visible) => {
                  setDropdownVisible(visible);
                }}
              >
                <ProfileDropdown>
                  <ProfileNameWrapper>
                    <ProfileName>{loginData?.username || "User"}</ProfileName>
                    <RoleName>{userRole}</RoleName>
                  </ProfileNameWrapper>
                  <DropdownIconWrapper visible={dropdownVisible}>
                    <DropdownIconImg src={ChevronUp} color="#000" size={18} />
                  </DropdownIconWrapper>
                </ProfileDropdown>
              </Dropdown>
            </ProfileWrapper>
          </OtherSectionWrapper>
        </HeaderContainer>
      </HeaderWrapper>

      {width < 768 && (
        <SidebarCmp
          menuToggle={menuToggle}
          handleToggleOnMobile={handleToggleOnMobile}
        />
      )}
    </>
  );
};

const mapState = (state) => ({
  userRole: state.auth.userRole,
  loginData: state.auth.data,
});

export default memo(connect(mapState)(HeaderCmp), (prevProps, nextProps) => {
  return prevProps === nextProps && prevProps === nextProps;
});
