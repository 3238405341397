const rolesOption = {
  "super-admin": "Super Admin",
  admin: "Admin",
  agent: "Agent",
  sale: "Sale",
  account: "Account",
  support: "Support",
};

export default rolesOption;
